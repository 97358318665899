import {
  Badge,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import React, { useState } from "react";

import Conversations from "./Conversations";

import BlueStar from "@/assets/svgs/blue-star.svg";
import CompaniesIcon from "@/assets/svgs/companies.svg";
import PeopleIcon from "@/assets/svgs/people.svg";
// import Star from "@/assets/svgs/persana-magic-star.svg";

import leadService from "@/services/leads.service";

import { useModalStore } from "@/stores/modal.store";
import useTypedText from "./useTypedText";
import Star from "@/assets/svgs/star.svg";
const AIAssistant = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inputValue, setInputValue] = useState("");
  const placeholders = [
    "Ask me anything...",
    "Does Salesforce have an API?",
    "Where is the headquarters of Meta?",
    "Find healthcare companies in United States",
    "Find people who work at Google as a Software Engineer and lives in San Francisco",
  ];

  const dynamicPlaceholder = useTypedText(placeholders);
  const updateState = useModalStore((state) => state.updateState);

  const {
    mutateAsync: searchPeopleUsingGPTMutateAsync,
    data: answerData,
    isPending,
  } = useMutation({
    mutationFn: (input: string) =>
      leadService.searchPeopleUsingGPT({ prompt: input }),
    onSuccess: (response) => {
      // setInputValue("");
      if (response.type === "AGENT") {
        onOpen();
        return;
      }

      if (response.type === "PEOPLE_SEARCH") {
        if (response.data?.peopleSearchPayload) {
          window["peopleSearchPayload"] = response.data.peopleSearchPayload;
          navigate({
            to: "/search",
          });
        }
        return;
      }

      if (!response?.data?.tableId) return;
      navigate({
        to: `/table/$tableId`,
        params: {
          tableId: response?.data?.tableId,
        },
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      inputValue && searchPeopleUsingGPTMutateAsync(inputValue);
    }
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    setInputValue(value);
  };

  return (
    <>
      <div className="relative mx-[24px] mb-[12px] overflow-hidden rounded-[8px] border border-[#7846cc] bg-white">
        {/* <div
          className="flex items-center gap-2 rounded-t-[8px] bg-primaryPink p-[16px]"
          style={{
            background: "linear-gradient(90deg, #8E32A4 0%, #5024DC 100%)",
          }}
        >
          <img src={Star} alt="" />
          <p className="text-[14px] font-medium text-white">Persana Magic</p>
        </div> */}
        <div className="p-[16px]">
          <p className="mb-1 text-[20px] font-[600] text-[#0F0D1C]">
            Find your perfect target audience
          </p>

          <Badge
            size="sm"
            variant="solid"
            className="shine-effect absolute right-[0.5em] top-[0.5em] !flex items-center !rounded-[3em] !bg-[#b86ddf] !px-3 !py-1 capitalize !text-white opacity-70"
          >
            <img
              className="mr-2 "
              src={Star}
              alt="star"
              width={20}
              height={20}
            />
            New! try sales gpt
          </Badge>
          <p className="text-[12px] text-[#0F0D1C]">
            Search for your ideal customer and build your first list.
          </p>
        </div>
        <div className="flex flex-col gap-[16px] p-[16px]">
          <textarea
            onKeyDown={handleKeyDown}
            value={inputValue}
            onChange={handleChange}
            placeholder={dynamicPlaceholder}
            rows={2}
            className="!h-[62px] !w-full !resize-none !rounded-md !p-2 shadow-none"
            style={{
              border: "1px solid #d8d2ec",
            }}
          />

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-[12px]">
              <ButtonGroup>
                <Button
                  leftIcon={
                    <img src={PeopleIcon} alt="people" width={20} height={20} />
                  }
                  className="rounded-[6px] !border-[#5024DC] !bg-[#F5F3FF] !text-[#5024DC]"
                  onClick={() => {
                    navigate({ to: "/search" });
                  }}
                  variant="secondary"
                  size="sm"
                >
                  People
                </Button>
                <Button
                  leftIcon={
                    <img
                      src={CompaniesIcon}
                      alt="companies"
                      width={20}
                      height={20}
                    />
                  }
                  className="rounded-[6px] !border-[#5024DC] !bg-[#F5F3FF] !text-[#5024DC]"
                  onClick={() => {
                    updateState({
                      isOpenModal: true,
                      modalData: {
                        modalType: "linkedin-company-search",
                        metaData: { openPreviousModal: false },
                      },
                    });
                  }}
                  variant="secondary"
                  size="sm"
                >
                  Companies
                </Button>
              </ButtonGroup>
            </div>
            <Button
              isLoading={isPending}
              onClick={() => searchPeopleUsingGPTMutateAsync(inputValue)}
              variant="outline"
              leftIcon={
                <img src={BlueStar} alt="star" width={20} height={20} />
              }
              className="flex w-fit cursor-pointer rounded-[6px] !border !border-[#693DC7] !bg-white !text-[#693dc7]"
              isDisabled={!inputValue}
            >
              Generate
            </Button>
          </div>
        </div>
      </div>

      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        scrollBehavior="inside"
        closeOnOverlayClick={false}
      >
        <ModalOverlay
          bg="rgba(0, 0, 0, 0.1)"
          backdropFilter="revert"
          backdropInvert="80%"
          backdropBlur="2px"
        />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Conversations answerData={answerData?.data} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AIAssistant;
