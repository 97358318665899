import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import Swal from "sweetalert2";

import ExportDataModal from "./ExportDataModal";

import leadService from "@/services/leads.service";
import { useSearchLeadsStore } from "@/stores/searchleads.store";
import { userStore } from "@/stores/user.store";
import Icons from "../Icons";

type Props = {
  currentSearchPayload: any;
  isForFindEmail?: boolean;
  buttonText: string;
  variant: "primary" | "secondary";
};

const PeopleSearchResultTableActions = ({
  currentSearchPayload,
  buttonText,
  isForFindEmail = false,
  variant,
}: Props) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [tableId, setTableId] = useState<string>("");
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const currentUser = userStore((state) => state.currentUser);
  const selectedLeads = useSearchLeadsStore((state) => state.selectedLeads);
  const selectionCount = useSearchLeadsStore((state) => state.selectionCount);
  const selectionAction = useSearchLeadsStore((state) => state.selectionAction);
  const searchLeadsResult = useSearchLeadsStore(
    (state) => state.searchLeadsResult,
  );
  const updatedLeadState = useSearchLeadsStore((state) => state.updateState);

  const { mutateAsync, isPending: isAddingToTable } = useMutation({
    mutationFn: () => {
      const isFreeUser = currentUser.planType?.toLowerCase() === "free";

      const customSelectionNumber = isFreeUser
        ? Math.min(25, selectionCount)
        : selectionCount;

      const payload = {
        ...(currentSearchPayload || {}),
        cacheKey: searchLeadsResult?.cacheKey,
        isForFindEmail,
        selectedLeads: ["select-all", "range-selection"].includes(
          selectionAction,
        )
          ? []
          : selectedLeads?.map((item) => item._id),
        customSelectionNumber:
          selectionAction === "range-selection" ? customSelectionNumber : 0,
        isSelectAll: selectionAction === "select-all",
        totalEntries: searchLeadsResult?.pagination?.total_entries || 0,
      } as any;
      console.log("selectionAction", selectionAction);

      return leadService.addLeadsToTable(payload);
    },
  });

  const handleView = () => {
    window.open(`${window.location.origin}/table/${tableId}`, "_blank");
  };

  const handleImportLeads = async () => {
    if (!selectedLeads.length) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select the leads.",
        timer: 2000,
        timerProgressBar: true,
      });
      return;
    }
    await mutateAsync(undefined, {
      onSuccess: (response: any) => {
        if (!response?.data?.tableData) return;
        setTableId(response?.data?.tableData._id!);
        queryClient.refetchQueries({
          queryKey: ["user-credit-details"],
        });
        if (isForFindEmail) {
          updatedLeadState({
            selectionAction: "",
          });
          onOpen();
          setIsExportModalOpen(false);
        } else {
          toast({
            title: "Success",
            description: (
              <p>
                Leads have been successfully imported to the table. <br />
                <a
                  href={`/table/${response?.data?.tableData._id}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-lg font-bold text-white underline"
                >
                  View Table
                </a>
              </p>
            ),
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
          window.open(
            `${window.location.origin}/table/${response?.data?.tableData._id}`,
            "_blank",
          );
        }
      },
      onError: (error) => {
        console.log(error);
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        // setIsAddingToTable(false);
      },
    });
  };

  const handleExportData = () => {
    if (!selectedLeads.length) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select the leads.",
        timer: 2000,
        timerProgressBar: true,
      });
      return;
    }
    setIsExportModalOpen(true);
  };

  return (
    <>
      <div className="flex shrink-0 justify-end">
        <Button
          isLoading={isAddingToTable}
          loadingText={isForFindEmail ? "Exporting..." : "Importing..."}
          variant="outline"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          onClick={() => {
            isForFindEmail ? handleExportData() : handleImportLeads();
          }}
          leftIcon={
            buttonText.includes("Import") ? (
              <Icons.PlusIcon />
            ) : (
              <Icons.SearchBarButton />
            )
          }
          className={`!hover:text-inherit !w-fit !rounded-[8px] !px-4 !py-1.5 !font-title !text-sm !font-semibold !tracking-wide ${variant === "primary" ? "!border !border-[#C03EDC] !bg-[#F5F3FF] !text-[#373F51]" : "!bg-[#FFFFFF] !text-[#373F51]"}`}
        >
          {buttonText}
        </Button>
      </div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Email Export</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>
              Your email export is processing. You will receive an email with a
              link to the list.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button variant={"outline"} colorScheme="blue" onClick={handleView}>
              View
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ExportDataModal
        isShowExportModal={isExportModalOpen}
        handleClose={() => setIsExportModalOpen(false)}
        handleExport={handleImportLeads}
        selectedLeadsCount={selectedLeads.length}
        isLoading={isAddingToTable}
      />
    </>
  );
};

export default PeopleSearchResultTableActions;
