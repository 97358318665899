import { Divider, Input, Textarea } from "@chakra-ui/react";

type EmailDetails = {
  sendTo: string;
  subject: string;
  body: string;
};

type Props = {
  localEmailDetails: EmailDetails;
  setLocalEmailDetails: (val: any) => void;
};

const DraftEmailDetails = ({
  localEmailDetails,
  setLocalEmailDetails,
}: Props) => {
  return (
    <div className="px-4 py-2">
      <div className="mb-4">
        <p className="text-sm font-semibold">
          Send To:{" "}
          <Input
            placeholder=""
            size={"sm"}
            variant={"unstyled"}
            className="mt-2"
            value={localEmailDetails.sendTo}
            onChange={(e) =>
              setLocalEmailDetails((prevDetails: EmailDetails) => ({
                ...prevDetails,
                sendTo: e.target.value,
              }))
            }
          />
        </p>
      </div>
      <Divider className="my-2" />
      <div className="mb-4">
        <p className="text-sm font-semibold">
          Subject:{" "}
          <Input
            placeholder=""
            size={"sm"}
            variant={"unstyled"}
            className="mt-2"
            value={localEmailDetails.subject}
            onChange={(e) =>
              setLocalEmailDetails((prevDetails: EmailDetails) => ({
                ...prevDetails,
                subject: e.target.value,
              }))
            }
          />
        </p>
      </div>
      <Divider className="my-2" />
      <div className="mb-4">
        <p className="text-sm font-semibold mb-2">Body:</p>
        <Textarea
          style={{
            height: "200px",
            border: "1px solid #cbd5e0",
            maxHeight: "15rem",
            fontSize: "14px",
          }}
          resize={"none"}
          placeholder=""
          value={localEmailDetails.body}
          onChange={(e) =>
            setLocalEmailDetails((prevDetails: EmailDetails) => ({
              ...prevDetails,
              body: e.target.value,
            }))
          }
        />
      </div>
    </div>
  );
};

export default DraftEmailDetails;
