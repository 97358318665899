import GithubIntegration from "./GithubIntegration";
import HubspotIntegration from "./HubspotIntegration";
import OpenAIIntegration from "./OpenAIIntegration";
import OutreachIntegration from "./OutreachIntegration";
import SalesforceIntegration from "./SalesforceIntegration";
import SalesoftIntegration from "./SalesoftIntegration";
import SmartleadIntegration from "./SmartleadIntegration";
// import HubspotKeyIntegration from "./HubspotKeyIntegration";
import GmailIntegration from "./GmailIntegration";
import HeyReachIntegration from "./HeyReachIntegration";
import InstantlyIntegration from "./InstantlyIntegration";
import LeadMagicIntegration from "./LeadMagicIntegration";
import LemListIntegration from "./LemListIntegration";
import SlackIntegration from "./SlackIntegration";
import ZenrowsIntegration from "./ZenrowsIntegration";

const Integrations = () => {
  const isNotProd = import.meta.env.VITE_MODE !== "production";

  return (
    <section className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3">
      <HubspotIntegration />
      <SalesforceIntegration />
      <OutreachIntegration />
      <SalesoftIntegration />
      <GithubIntegration />
      <GmailIntegration />
      <SmartleadIntegration />
      <InstantlyIntegration />
      <OpenAIIntegration />
      <LeadMagicIntegration />
      <ZenrowsIntegration />
      <HeyReachIntegration />
      <LemListIntegration />
      {isNotProd && <SlackIntegration />}
      {/* <HubspotKeyIntegration /> */}
    </section>
  );
};

export default Integrations;
