import { Input, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import PremiumIcon from "@/components/Common/PremiumIcon";

import tableService from "@/services/table.service";
import { useTableStore } from "@/stores/table.store";
import { TableData } from "@/types/table.types";

const TableName = () => {
  const queryClient = useQueryClient();
  const isTablePreviewPage =
    window.location.pathname.startsWith("/table-preview");
  const isUserLoggedIn = queryClient.getQueryData(["user-details"]);

  const tableData = useTableStore((state) => state.tableData);
  const isPremium = useTableStore(
    (state) => state.tableData.metaData?.isPremium,
  );
  const tableId = useTableStore((state) => state.tableData._id);
  const filters = useTableStore((state) => state.tableFilters.filters);
  const updateState = useTableStore((state) => state.updateState);

  const tableName = tableData.name;

  const [isEditingName, setIsEditingName] = useState(false);
  const [newName, setNewName] = useState(() => tableName);

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (newTableName: string) =>
      tableService.updateTableData(tableId, {
        name: newTableName,
      }),
    onSuccess: (response) => {
      const tableResponse = response as unknown as TableData;
      if (response.success === false) return;

      updateState({
        tableData: {
          ...tableData,
          name: tableResponse.name,
        },
      });

      queryClient.refetchQueries({
        queryKey: ["tables"],
        type: "all",
      });
    },
  });

  const toggleEditing = () => {
    setIsEditingName((prevState) => !prevState);
  };
  const updateName = async () => {
    if (newName === tableName || !newName.trim()) {
      toggleEditing();
      return;
    }
    await mutateAsync(newName);
    toggleEditing();
  };

  useEffect(() => {
    setNewName(tableName);
  }, [tableName]);

  if (!tableName) return null;

  const inputWidth = tableName.length * 10 + 100 + "px";

  const totalFilterApplied = filters?.length || 0;

  return (
    <div>
      {!isTablePreviewPage && isEditingName ? (
        <div style={{ width: inputWidth }} className="flex items-center gap-2">
          <Input
            value={newName}
            onBlur={() => {
              updateName();
            }}
            onChange={(e) => setNewName(e.target.value)}
            autoFocus
            disabled={isPending}
          />
          {isPending && <Spinner color="purple" />}
        </div>
      ) : (
        <div className="flex items-center gap-1">
          <div>
            <h1
              className="cursor-pointer rounded-md px-2 text-xl font-bold hover:bg-slate-200"
              onClick={toggleEditing}
            >
              {tableName}{" "}
              {totalFilterApplied > 0 && (
                <span className="text-gray-500 text-xs font-semibold">
                  ( {filters?.length} Filter
                  {`${totalFilterApplied > 1 ? "s" : ""}`} Applied )
                </span>
              )}
            </h1>
            {!isUserLoggedIn && isTablePreviewPage && (
              <span className="ml-2 text-sm px-2 py-1 bg-[#F8F0FF] text-textPurple rounded">
                Create an account for free to view the entire list.
              </span>
            )}
          </div>
          {isPremium && <PremiumIcon />}
        </div>
      )}
    </div>
  );
};

export default TableName;
