import { Box } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { useGridOptions } from "./hooks/useGridOptions";
import { LoadingView } from "./components/LoadingView";
import { NoTablesView } from "./components/NoTablesView";
import { CreateNew } from "./components/CreateNew";
import StyleProvider from "@/providers/StyleProvider";
import "./index.css";

const TableList = () => {
  const gridOptions = useGridOptions();

  return (
    <StyleProvider>
      <Box overflowX="auto" className="folder-table mt-4">
        <Box overflowY="auto" maxHeight="540px">
          <CreateNew mr="2em" />
          <AgGridReact
            className="custom-scrollbar ag-theme-quartz !h-[540px]"
            gridOptions={gridOptions}
            noRowsOverlayComponent={NoTablesView}
            loadingOverlayComponent={LoadingView}
          />
        </Box>
      </Box>
    </StyleProvider>
  );
};
export default TableList;
