import Icons from "../Icons";

import ApiFy from "@/assets/images/apify.png";
import GPT from "@/assets/svgs/enrichment-gpt.svg";
import Verify from "@/assets/svgs/enrichment-verify.svg";
import AI from "@/assets/svgs/sidebar-ai-enrichment.svg";
import Company from "@/assets/svgs/sidebar-company-enrichment.svg";
import Email from "@/assets/svgs/sidebar-email-info.svg";
import Prospect from "@/assets/svgs/sidebar-prospect-enrichment.svg";
import Web from "@/assets/svgs/sidebar-web-research.svg";
import CleanData from "@/assets/svgs/sort_icon.svg";
import { Column } from "@/types/table.types";

export type TopEnrichmentTypes = {
  id?: string;
  name?: string;
  enrichmentName: string;
  icon?: any | (() => Element);
  iconBg?: string;
  label?: string;
  subtitle?: string;
  categories?: string[];
  hasPrecaution?: boolean;
  showResponse?: boolean;
  columnData?: Column;
  rowData?: any;
  onClick?: () => void;
  credits?: string;
  isHidden?: boolean;
  isWaterfall?: boolean;
  isForStaging?: boolean;
  isUseOwnKey?: boolean;
  isForAdmin?: boolean;
  allowEmails?: string[];
};
export const _showSubtitlesInEnrichmentList = [
  "askAQuestion",
  "persanaAgent2",
  "persanaAgent3",
];
export const topEnrichments: TopEnrichmentTypes[] = [
  {
    id: "persanaAgent3",
    name: "Persana Agent",
    icon: () => <Icons.RobotIcon color="#1972f5" size={26} />,
    enrichmentName: "persanaAgent3",
    iconBg: "#fff",
    label: "Ask and answer any question about a company with AI",
    subtitle:
      "Our most advanced agent capable of strong reasoning, browsing the internet and searching the web.",
    categories: ["Web Research", "AI Enrichment"],
    credits: "1/FREE",
  },
  {
    id: "useAi",
    name: "Use AI (ChatGPT + Claude)",
    enrichmentName: "useAi",
    icon: GPT,
    iconBg: "#fff",
    label: "Enrich data using AI",
    subtitle:
      "Use the combined intelligence of GPT and Claude. Generate hyper-personalized messaging, ask and answer complex queries, and elevate your business interactions.",
    categories: ["Web Research", "AI Enrichment"],
    hasPrecaution: false,
    onClick: () => {},
    credits: "1",
  },
  {
    id: "googleSearch",
    name: "Google Search",
    enrichmentName: "googleSearch",
    subtitle:
      "Perform large-scale Google searches for prospect information, and pair it with our AI enrichment to enhance your prospecting strategies.",
    categories: ["Web Research"],
    icon: () => <Icons.GoogleIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    credits: "1",
  },
  {
    id: "findWorkEmailWaterfall",
    name: "Find Work Email",
    enrichmentName: "findWorkEmail",
    icon: () => <Icons.MailIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Bridge connections effortlessly: Find work emails with a name.",
    subtitle:
      "Discover work emails of prospects quickly and accurately, using either LinkedIn URLs or a combination of Company Name & Prospect Name. Benefit from our high match and validation rate in the industry.",
    categories: ["Email Information", "Prospect Enichment"],
    hasPrecaution: false,
    onClick: () => {},
    isWaterfall: true,
    isForStaging: false,
    credits: "1",
  },
  {
    id: "httpApi",
    name: "HTTP API",
    enrichmentName: "httpApi",
    icon: () => <Icons.HttpIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "HTTP API",
    subtitle: "HTTP API",
    categories: [],
    credits: "Pro",
  },
  {
    id: "scrapeWebsite",
    name: "Scrape Website",
    enrichmentName: "scrapeWebsite",
    subtitle:
      "Automate the process of extracting valuable data from prospect websites. Use this feature in tandem with AI enrichment to gather comprehensive information effortlessly.",
    categories: ["Web Research"],
    icon: () => <Icons.WebsiteIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    credits: "1",
  },
] as const;

export const allEnrichments: TopEnrichmentTypes[] = [
  {
    id: "findWorkEmail",
    name: "Find Work Email",
    enrichmentName: "findWorkEmail",
    icon: () => <Icons.MailIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Bridge connections effortlessly: Find work emails with a name.",
    subtitle:
      "Discover work emails of prospects quickly and accurately, using either LinkedIn URLs or a combination of Company Name & Prospect Name. Benefit from our high match and validation rate in the industry.",
    categories: ["Email Information", "Prospect Enichment"],
    hasPrecaution: false,
    isWaterfall: false,
    isHidden: true,
    onClick: () => {},
    credits: "1",
  },
  {
    id: "linkedInDetails",
    name: "Enrich Full Person Profile",
    enrichmentName: "linkedInDetails",
    icon: () => <Icons.PersonIcon size={26} style={{ color: "#1972f5" }} />,
    iconBg: "#fff",
    label:
      "Glimpse the professional essence with LinkedIn insights. Get all the details of a profile",
    subtitle:
      "Retrieve detailed information from LinkedIn profiles using just the LinkedIn URL. This tool is perfect for in-depth research on prospects and can be combined with our LinkedIn URL finding tool if you don't have the LinkedIn URL.",
    categories: ["Linkedin Enrichment", "Prospect Enrichment"],
    credits: "1",
  },
  {
    id: "companyDetails",
    name: "Enrich Full Company Details",
    enrichmentName: "companyDetails",
    icon: () => <Icons.CompanyDetailsIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Enrich Full Company Details",
    subtitle:
      "Extract a wealth of information from a simple company URL. Discover LinkedIn pages, business descriptions, industries, employee counts, and much more, streamlining your research process.",
    categories: ["Company Enrichment"],
    credits: "1",
  },
  {
    id: "jobOpenings",
    name: "Job Openings",
    enrichmentName: "jobOpenings",
    icon: () => <Icons.UserListIcon size={26} color={"#1972f5"} />,
    iconBg: "#fff",
    label: "Unearth the latest career opportunities within top companies.",
    subtitle:
      "Just input a company URL to get a comprehensive list of current job openings, a key indicator of potential business needs and opportunities.",
    categories: ["Company Enrichment"],
    credits: "1",
  },
  {
    id: "findLinkedInUrl",
    name: "Find Linkedin Url",
    enrichmentName: "findLinkedInUrl",
    icon: () => <Icons.LinkedInIcon size={26} style={{ color: "#1972f5" }} />,
    iconBg: "#fff",
    label: "Seamlessly match emails to their LinkedIn profile links.",
    subtitle:
      "Locate LinkedIn profiles at scale using just a work email or the prospect's name and company. This tool is essential for expanding your professional network and identifying key contacts.",
    categories: ["Linkedin Enrichment", "Prospect Enrichment"],
    credits: "1",
  },
  {
    id: "companyNews",
    name: "Company News",
    enrichmentName: "companyNews",
    icon: "https://persana-assets.s3.us-west-1.amazonaws.com/predictleads-logo.jpeg",
    iconBg: "#fff",
    label: "Stay a step ahead with real-time company updates.",
    subtitle: "Stay a step ahead with real-time company updates.",
    categories: ["Company Enrichment"],
    hasPrecaution: false,
    onClick: () => {},
    credits: "1",
  },
  {
    id: "findPeopleAtCompany",
    name: "Find People At Company",
    enrichmentName: "findPeopleAtCompany",
    icon: () => <Icons.PeopleIcon size={26} color={"#1972f5"} />,
    iconBg: "#fff",
    label: "Find people at a company",
    subtitle:
      "Identify and connect with key individuals at your target companies. Input the company name and define your ideal customer profile to get a curated list of contacts, use this with additonal enrichments such as Find Work Email to supercharge your outreach.",
    categories: ["Linkedin Enrichment", "Prospect Enrichment"],
    credits: "1",
  },
  {
    id: "findPeopleAtCompanyProxyCurl",
    name: "Find People At Company ( Proxy Curl )",
    enrichmentName: "findPeopleAtCompanyProxyCurl",
    icon: () => <Icons.PeopleIcon size={26} color={"#1972f5"} />,
    iconBg: "#fff",
    label: "Find people at a company",
    subtitle:
      "Identify and connect with key individuals at your target companies. Input the company name and define your ideal customer profile to get a curated list of contacts, use this with additonal enrichments such as Find Work Email to supercharge your outreach.",
    categories: ["Linkedin Enrichment", "Prospect Enrichment"],
    credits: "1",
    isForStaging: true,
  },
  {
    id: "companyTechnologies",
    name: "Company Technologies",
    enrichmentName: "companyTechnologies",
    icon: () => <Icons.QRCodeIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Uncover the technological backbone of a company given its URL.",
    subtitle:
      "Identify the technologies a prospect company uses. This vital insight helps in assessing whether they are a suitable prospect for your products or services.",
    categories: ["Company Enrichment"],
    credits: "1",
  },
  {
    id: "generateSalesEmails",
    name: "Generate Sales Emails",
    enrichmentName: "generateSalesEmails",
    icon: () => <Icons.CampaignIcon size={26} color="#1972f5" />,
    hasPrecaution: true,
    label: "Hyperpersonalized AI messages at scale.",
    subtitle:
      "Transform a LinkedIn URL into a powerful highly personalized sales emails, increasing your engagement rates and potential for successful conversions.",
    iconBg: "#fff",
    credits: "1",
    categories: [],
  },
  {
    id: "verifyEmail",
    name: "Email Verification and Bounce Rates",
    enrichmentName: "verifyEmail",
    icon: Verify,
    iconBg: "#fff",
    label: "Validate email to prevent bounce rates",
    subtitle:
      "Ensure your emails reach their intended recipients with our email verification and bounce testing tool. Avoid spam filters and maintain a high deliverability score for effective email marketing campaigns.",
    categories: ["Email Information"],
    hasPrecaution: false,
    onClick: () => {},
    credits: "1",
  },
  {
    id: "addLeadToSmartleadsCampaign",
    name: "Add Leads to Smartlead",
    enrichmentName: "addLeadToSmartleadsCampaign",
    icon: "https://persana-assets.s3.us-west-1.amazonaws.com/smartlead-logo.jpeg",
    iconBg: "#fff",
    label: "Add a lead to a campaign in Smartlead.ai.",
    subtitle:
      "Integrate new leads and personalizations into Smartlead, ensuring each lead is personalized and tailored to your business needs.",
    categories: [],
    credits: "Pro",
  },
  {
    id: "personLinkedInName",
    name: "Enrich Full Person Profile with Name",
    enrichmentName: "linkedInDetails",
    icon: () => <Icons.PersonIcon size={26} style={{ color: "#1972f5" }} />,
    iconBg: "#fff",
    label:
      "Glimpse the professional essence with LinkedIn insights. Get all the details of a profile",
    subtitle:
      "Retrieve detailed information from LinkedIn profiles using just the LinkedIn URL. This tool is perfect for in-depth research on prospects and can be combined with our LinkedIn URL finding tool if you don't have the LinkedIn URL.",
    categories: ["Linkedin Enrichment", "Prospect Enrichment"],
    credits: "1",
  },
  {
    id: "personLinkedInHeadline",
    name: "Enrich Full Person Profile with Headline",
    enrichmentName: "linkedInDetails",
    icon: () => <Icons.PersonIcon size={26} style={{ color: "#1972f5" }} />,
    iconBg: "#fff",
    label:
      "Glimpse the professional essence with LinkedIn insights. Get all the details of a profile",
    subtitle:
      "Retrieve detailed information from LinkedIn profiles using just the LinkedIn URL. This tool is perfect for in-depth research on prospects and can be combined with our LinkedIn URL finding tool if you don't have the LinkedIn URL.",
    categories: ["Linkedin Enrichment", "Prospect Enrichment"],
    credits: "1",
  },
  {
    id: "personLinkedInProfilePhoto",
    name: "Enrich Full Person Profile with Profile Photo",
    enrichmentName: "linkedInDetails",
    icon: () => <Icons.PersonIcon size={26} style={{ color: "#1972f5" }} />,
    iconBg: "#fff",
    label:
      "Glimpse the professional essence with LinkedIn insights. Get all the details of a profile",
    subtitle:
      "Retrieve detailed information from LinkedIn profiles using just the LinkedIn URL. This tool is perfect for in-depth research on prospects and can be combined with our LinkedIn URL finding tool if you don't have the LinkedIn URL.",
    categories: ["Linkedin Enrichment", "Prospect Enrichment"],
    credits: "1",
  },
  {
    id: "personLinkedInLocation",
    name: "Enrich Full Person Profile with Location",
    enrichmentName: "linkedInDetails",
    icon: () => <Icons.PersonIcon size={26} style={{ color: "#1972f5" }} />,
    iconBg: "#fff",
    label:
      "Glimpse the professional essence with LinkedIn insights. Get all the details of a profile",
    subtitle:
      "Retrieve detailed information from LinkedIn profiles using just the LinkedIn URL. This tool is perfect for in-depth research on prospects and can be combined with our LinkedIn URL finding tool if you don't have the LinkedIn URL.",
    categories: ["Linkedin Enrichment", "Prospect Enrichment"],
    credits: "1",
  },
  {
    id: "personLinkedInSummary",
    name: "Enrich Full Person Profile with Summary",
    enrichmentName: "linkedInDetails",
    icon: () => <Icons.PersonIcon size={26} style={{ color: "#1972f5" }} />,
    iconBg: "#fff",
    label:
      "Glimpse the professional essence with LinkedIn insights. Get all the details of a profile",
    subtitle:
      "Retrieve detailed information from LinkedIn profiles using just the LinkedIn URL. This tool is perfect for in-depth research on prospects and can be combined with our LinkedIn URL finding tool if you don't have the LinkedIn URL.",
    categories: ["Linkedin Enrichment", "Prospect Enrichment"],
    credits: "1",
  },
  {
    id: "personLinkedInCurrentCompany",
    name: "Enrich Full Person Profile with Current Company",
    enrichmentName: "linkedInDetails",
    icon: () => <Icons.PersonIcon size={26} style={{ color: "#1972f5" }} />,
    iconBg: "#fff",
    label:
      "Glimpse the professional essence with LinkedIn insights. Get all the details of a profile",
    subtitle:
      "Retrieve detailed information from LinkedIn profiles using just the LinkedIn URL. This tool is perfect for in-depth research on prospects and can be combined with our LinkedIn URL finding tool if you don't have the LinkedIn URL.",
    categories: ["Linkedin Enrichment", "Prospect Enrichment"],
    credits: "1",
  },
  {
    id: "personLinkedInCurrentCompanyUrl",
    name: "Enrich Full Person Profile with Current Company Website",
    enrichmentName: "linkedInDetails",
    icon: () => <Icons.PersonIcon size={26} style={{ color: "#1972f5" }} />,
    iconBg: "#fff",
    label:
      "Glimpse the professional essence with LinkedIn insights. Get all the details of a profile",
    subtitle:
      "Retrieve detailed information from LinkedIn profiles using just the LinkedIn URL. This tool is perfect for in-depth research on prospects and can be combined with our LinkedIn URL finding tool if you don't have the LinkedIn URL.",
    categories: ["Linkedin Enrichment", "Prospect Enrichment"],
    credits: "1",
  },
  {
    id: "personLinkedInCurrentTitle",
    name: "Enrich Full Person Profile with Current Title",
    enrichmentName: "linkedInDetails",
    icon: () => <Icons.PersonIcon size={26} style={{ color: "#1972f5" }} />,
    iconBg: "#fff",
    label:
      "Glimpse the professional essence with LinkedIn insights. Get all the details of a profile",
    subtitle:
      "Retrieve detailed information from LinkedIn profiles using just the LinkedIn URL. This tool is perfect for in-depth research on prospects and can be combined with our LinkedIn URL finding tool if you don't have the LinkedIn URL.",
    categories: ["Linkedin Enrichment", "Prospect Enrichment"],
    credits: "1",
  },
  {
    id: "personLinkedInSchool",
    name: "Enrich Full Person Profile with School",
    enrichmentName: "linkedInDetails",
    icon: () => <Icons.PersonIcon size={26} style={{ color: "#1972f5" }} />,
    iconBg: "#fff",
    label:
      "Glimpse the professional essence with LinkedIn insights. Get all the details of a profile",
    subtitle:
      "Retrieve detailed information from LinkedIn profiles using just the LinkedIn URL. This tool is perfect for in-depth research on prospects and can be combined with our LinkedIn URL finding tool if you don't have the LinkedIn URL.",
    categories: ["Linkedin Enrichment", "Prospect Enrichment"],
    credits: "1",
  },
  {
    id: "headquarters",
    name: "Enrich Company Details with Headquarter",
    enrichmentName: "companyDetails",
    icon: () => <Icons.CompanyDetailsIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Enrich Company Details with Headquarter",
    subtitle:
      "Extract a wealth of information from a simple company URL. Discover LinkedIn pages, business descriptions, industries, employee counts, and much more, streamlining your research process.",
    categories: ["Company Enrichment"],
    credits: "1",
  },
  {
    id: "companyHeadcount",
    name: "Enrich Company Details with Headcount",
    enrichmentName: "companyDetails",
    icon: () => <Icons.CompanyDetailsIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Enrich Company Details with Headcount",
    subtitle:
      "Extract a wealth of information from a simple company URL. Discover LinkedIn pages, business descriptions, industries, employee counts, and much more, streamlining your research process.",
    categories: ["Company Enrichment"],
    credits: "1",
  },
  {
    id: "companyFollowers",
    name: "Enrich Company Details with Followers",
    enrichmentName: "companyDetails",
    icon: () => <Icons.CompanyDetailsIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Enrich Company Details with Followers",
    subtitle:
      "Extract a wealth of information from a simple company URL. Discover LinkedIn pages, business descriptions, industries, employee counts, and much more, streamlining your research process.",
    categories: ["Company Enrichment"],
    credits: "1",
  },
  {
    id: "companyWebsite",
    name: "Enrich Company Details with Website",
    enrichmentName: "companyDetails",
    icon: () => <Icons.CompanyDetailsIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Enrich Company Details with Website",
    subtitle:
      "Extract a wealth of information from a simple company URL. Discover LinkedIn pages, business descriptions, industries, employee counts, and much more, streamlining your research process.",
    categories: ["Company Enrichment"],
    credits: "1",
  },
  {
    id: "companyLinkedinUrl",
    name: "Enrich Company Details with LinkedIn URL",
    enrichmentName: "companyDetails",
    icon: () => <Icons.CompanyDetailsIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Enrich Company Details with LinkedIn URL",
    subtitle:
      "Extract a wealth of information from a simple company URL. Discover LinkedIn pages, business descriptions, industries, employee counts, and much more, streamlining your research process.",
    categories: ["Company Enrichment"],
    credits: "1",
  },
  {
    id: "companyFoundedYear",
    name: "Enrich Company Details with Founded Year",
    enrichmentName: "companyDetails",
    icon: () => <Icons.CompanyDetailsIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Enrich Company Details with Founded Year",
    subtitle:
      "Extract a wealth of information from a simple company URL. Discover LinkedIn pages, business descriptions, industries, employee counts, and much more, streamlining your research process.",
    categories: ["Company Enrichment"],
    credits: "1",
  },
  {
    id: "companyAddress",
    name: "Enrich Company Details with Address",
    enrichmentName: "companyDetails",
    icon: () => <Icons.CompanyDetailsIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Enrich Company Details with Address",
    subtitle:
      "Extract a wealth of information from a simple company URL. Discover LinkedIn pages, business descriptions, industries, employee counts, and much more, streamlining your research process.",
    categories: ["Company Enrichment"],
    credits: "1",
  },
  {
    id: "apifyActor",
    name: "Apify Actor",
    enrichmentName: "apifyActor",
    icon: ApiFy,
    iconBg: "#fff",
    label: "Run Apify actors to scrape data from websites.",
    subtitle: "",
    categories: [],
    credits: "FREE",
  },
  {
    id: "addLeadToApollo",
    name: "Add Leads to Apollo Sequences",
    enrichmentName: "addLeadToApollo",
    icon: () => <Icons.GoogleAdsIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Add leads to Apollo Sequences",
    subtitle: "Add leads to Apollo Sequences",
    categories: [],
    credits: "1/FREE",
  },
  {
    id: "webhook",
    name: "Webhook",
    enrichmentName: "webhook",
    icon: () => <Icons.WebhookIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Webhook",
    subtitle: "Webhook",
    categories: [],
    credits: "Pro",
    isHidden: true,
  },

  {
    id: "companyFormalizer",
    name: "Company Formalizer",
    enrichmentName: "formalizer",
    icon: () => <Icons.CleanIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Company Formalizer",
    subtitle: "Company Formalizer",
    categories: ["Clean Data"],
    credits: "FREE",
  },
  {
    id: "domainFormalizer",
    name: "Domain Formalizer",
    enrichmentName: "formalizer",
    icon: () => <Icons.CleanIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Domain Formalizer",
    subtitle: "Domain Formalizer",
    categories: ["Clean Data"],
    credits: "FREE",
  },
  {
    id: "whiteSpaceFormalizer",
    name: "Remove Extra Whitespace",
    enrichmentName: "formalizer",
    icon: () => <Icons.CleanIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Remove Extra Whitespace",
    subtitle: "Remove Extra Whitespace",
    categories: ["Clean Data"],
    credits: "FREE",
  },
  {
    id: "firstNameFormalizer",
    name: "Get a First Name",
    enrichmentName: "formalizer",
    icon: () => <Icons.CleanIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Get a First Name",
    subtitle: "Get a First Name",
    categories: ["Clean Data"],
    credits: "FREE",
  },
  {
    id: "lastNameFormalizer",
    name: "Get a Last Name",
    enrichmentName: "formalizer",
    icon: () => <Icons.CleanIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Get a Last Name",
    subtitle: "Get a Last Name",
    categories: ["Clean Data"],
    credits: "FREE",
  },
  {
    id: "aiFormula",
    name: "AI Formula",
    enrichmentName: "aiFormula",
    icon: () => <Icons.FunctionIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "AI Formula",
    subtitle: "AI Formula",
    categories: ["Clean Data"],
    credits: "FREE",
  },
  {
    id: "searchPhoneNumber",
    name: "Search Phone Number (Datagma)",
    enrichmentName: "searchPhoneNumber",
    icon: "https://persana-assets.s3.us-west-1.amazonaws.com/datagma-logo.png",
    iconBg: "#fff",
    label: "Search Phone Number (Datagma)",
    subtitle: "Search Phone Number (Datagma)",
    categories: ["Phone Number"],
    credits: "1",
    isUseOwnKey: true,
  },
  {
    id: "phoneWaterfall",
    name: "Find Phone Number (Waterfall)",
    enrichmentName: "phoneWaterfall",
    icon: () => <Icons.CallIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Find Phone Number (Waterfall)",
    subtitle: "Find Phone Number (Waterfall)",
    categories: ["Phone Number"],
    credits: "3/18",
    isUseOwnKey: true,
  },
  {
    id: "twitterDetails",
    name: "Get Twitter Details",
    enrichmentName: "twitterDetails",
    icon: () => <Icons.TwitterIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Get Twitter Details",
    subtitle: "Get Twitter Details",
    categories: [""],
    credits: "1",
    isUseOwnKey: true,
    isHidden: false,
  },
  {
    id: "findMobileNumber",
    name: "Search Phone Number (LeadMagic)",
    enrichmentName: "findMobileNumber",
    icon: "https://persana-assets.s3.us-west-1.amazonaws.com/leadmagic-logo.png",
    iconBg: "#fff",
    label: "Search Phone Number (LeadMagic)",
    subtitle: "Search Phone Number (LeadMagic)",
    categories: ["Phone Number"],
    credits: "1",
    isForStaging: false,
    isUseOwnKey: true,
  },
  {
    id: "hubspotCompany",
    name: "Create or Update Company (HubSpot)",
    enrichmentName: "hubspotCompany",
    icon: "https://persana-assets.s3.us-west-1.amazonaws.com/hubspot-logo.png",
    iconBg: "#fff",
    label: "Create or Update Company (HubSpot)",
    subtitle: "Create or Update Company (HubSpot)",
    categories: ["Company Enrichment"],
    credits: "FREE",
    isUseOwnKey: true,
  },
  {
    id: "hubspotContact",
    name: "Create or Update Contact (HubSpot)",
    enrichmentName: "hubspotContact",
    icon: "https://persana-assets.s3.us-west-1.amazonaws.com/hubspot-logo.png",
    iconBg: "#fff",
    label: "Create or Update Contact (HubSpot)",
    subtitle: "Create or Update Contact (HubSpot)",
    categories: ["Company Enrichment"],
    credits: "FREE",
    isUseOwnKey: true,
  },
  {
    id: "hubspotContactUpdate",
    name: "Update Contact (HubSpot)",
    enrichmentName: "hubspotContactUpdate",
    icon: "https://persana-assets.s3.us-west-1.amazonaws.com/hubspot-logo.png",
    iconBg: "#fff",
    label: "Update Contact (HubSpot)",
    subtitle: "Update Contact (HubSpot)",
    categories: ["Company Enrichment"],
    credits: "FREE",
    isUseOwnKey: true,
  },
  {
    id: "recentLinkedinPost",
    name: "Find Recent LinkedIn Post",
    enrichmentName: "recentLinkedinPost",
    icon: "https://persana-assets.s3.us-west-1.amazonaws.com/recent-post-logo.png",
    iconBg: "#fff",
    label: "Find Recent LinkedIn Post",
    subtitle: "Find Recent LinkedIn Post",
    categories: ["Company Enrichment"],
    credits: "1",
  },
  ...topEnrichments,
  {
    id: "persanaAgent2",
    name: "Company Web Search Agent",
    icon: () => <Icons.RobotIcon color="#1972f5" size={26} />,
    enrichmentName: "persanaAgent2",
    iconBg: "#fff",
    label: "Ask and answer any question about a company with AI",
    subtitle:
      "Rely on search as a primarily source for finding company related information.",
    categories: ["Web Research", "AI Enrichment"],
    credits: "1",
    isHidden: false,
  },
  {
    id: "findWorkEmailWaterfall",
    name: "Find Work Email (Waterfall)",
    enrichmentName: "findWorkEmail",
    icon: () => <Icons.MailIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Bridge connections effortlessly: Find work emails with a name.",
    subtitle:
      "Discover work emails of prospects quickly and accurately, using either LinkedIn URLs or a combination of Company Name & Prospect Name. Benefit from our high match and validation rate in the industry.",
    categories: ["Email Information", "Prospect Enichment"],
    hasPrecaution: false,
    onClick: () => {},
    isWaterfall: true,
    isForStaging: false,
    credits: "1",
  },
  {
    id: "findWorkEmailIcypeas",
    name: "Find Work Email (Icypeas)",
    enrichmentName: "findWorkEmail",
    icon: () => <Icons.MailIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Bridge connections effortlessly: Find work emails with a name.",
    subtitle:
      "Discover work emails of prospects quickly and accurately, using combination of Company Name & Prospect Name. Benefit from our high match and validation rate in the industry.",
    categories: ["Email Information", "Prospect Enichment"],
    hasPrecaution: false,
    isWaterfall: true,
    isForStaging: false,
    onClick: () => {},
    credits: "1",
  },
  {
    id: "findWorkEmailProspeoLinkedin",
    name: "Find Work Email with LinkedIn URL (Prospeo)",
    enrichmentName: "findWorkEmail",
    icon: () => <Icons.MailIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Bridge connections effortlessly: Find work emails with a name.",
    subtitle:
      "Discover work emails of prospects quickly and accurately, using combination of Company Name & Prospect Name. Benefit from our high match and validation rate in the industry.",
    categories: ["Email Information", "Prospect Enichment"],
    hasPrecaution: false,
    isWaterfall: false,
    isForStaging: false,
    onClick: () => {},
    credits: "1",
  },
  {
    id: "findWorkEmailFindyMail",
    name: "Find Work Email (FindyMail)",
    enrichmentName: "findWorkEmail",
    icon: () => <Icons.MailIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Bridge connections effortlessly: Find work emails with a name.",
    subtitle:
      "Discover work emails of prospects quickly and accurately, using combination of Company Name & Prospect Name. Benefit from our high match and validation rate in the industry.",
    categories: ["Email Information", "Prospect Enichment"],
    hasPrecaution: false,
    isWaterfall: true,
    isForStaging: false,
    onClick: () => {},
    credits: "1",
  },
  {
    id: "findWorkEmailDatagma",
    name: "Find Work Email (Datagma)",
    enrichmentName: "findWorkEmail",
    icon: () => <Icons.MailIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Bridge connections effortlessly: Find work emails with a name.",
    subtitle:
      "Discover work emails of prospects quickly and accurately, using combination of Company Name & Prospect Name. Benefit from our high match and validation rate in the industry.",
    categories: ["Email Information", "Prospect Enichment"],
    hasPrecaution: false,
    isWaterfall: true,
    isForStaging: false,
    onClick: () => {},
    credits: "1",
  },
  {
    id: "findWorkEmailHunterIo",
    name: "Find Work Email (HunterIo)",
    enrichmentName: "findWorkEmail",
    icon: () => <Icons.MailIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Bridge connections effortlessly: Find work emails with a name.",
    subtitle:
      "Discover work emails of prospects quickly and accurately, using combination of Company Name & Prospect Name. Benefit from our high match and validation rate in the industry.",
    categories: ["Email Information", "Prospect Enichment"],
    hasPrecaution: false,
    isWaterfall: true,
    isForStaging: false,
    onClick: () => {},
    credits: "1",
  },
  {
    id: "findWorkEmailLeadMagic",
    name: "Find Work Email (LeadMagic)",
    enrichmentName: "findWorkEmail",
    icon: () => <Icons.MailIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Bridge connections effortlessly: Find work emails with a name.",
    subtitle:
      "Discover work emails of prospects quickly and accurately, using combination of Company Name & Prospect Name. Benefit from our high match and validation rate in the industry.",
    categories: ["Email Information", "Prospect Enichment"],
    hasPrecaution: false,
    isWaterfall: true,
    isForStaging: false,
    onClick: () => {},
    credits: "1",
  },
  {
    id: "addLeadToLgm",
    name: "La Growth Machine - Create or Update Lead",
    enrichmentName: "addLeadToLgm",
    icon: () => (
      <img
        src="https://persana-assets.s3.us-west-1.amazonaws.com/lgm.svg"
        alt="LGM"
      />
    ),
    iconBg: "#fff",
    label: "La Growth Machine - Create or Update Lead",
    subtitle: "La Growth Machine - Create or Update Lead",
    categories: [],
    isForStaging: false,
    credits: "Pro",
    isUseOwnKey: true,
  },
  {
    id: "addLeadToInstantlyCampaign",
    name: "Add Leads to Instantly",
    enrichmentName: "addLeadToInstantlyCampaign",
    icon: "https://persana-assets.s3.us-west-1.amazonaws.com/instantly-logo.jpg",
    iconBg: "#fff",
    label: "Add a lead to a campaign in Instantly.ai.",
    subtitle:
      "Integrate new leads and personalizations into Instantly, ensuring each lead is personalized and tailored to your business needs.",
    categories: [],
    credits: "FREE",
    isUseOwnKey: true,
  },
  {
    id: "googleNews",
    name: "Find News Results",
    enrichmentName: "googleNews",
    icon: "https://persana-assets.s3.us-west-1.amazonaws.com/google-news-logo.png",
    iconBg: "#fff",
    label: "Find News Results",
    subtitle: "Find News Results",
    categories: [""],
    credits: "1",
  },
  {
    id: "googleShopping",
    name: "Find Shopping Results",
    enrichmentName: "googleShopping",
    icon: "https://persana-assets.s3.us-west-1.amazonaws.com/google-shopping-logo.jpg",
    iconBg: "#fff",
    label: "Find Shopping Results",
    subtitle: "Find Shopping Results",
    categories: [""],
    credits: "1",
  },
  {
    id: "zenrowsScrape",
    name: "Run Zenrows Scrape",
    enrichmentName: "zenrowsScrape",
    icon: "https://persana-assets.s3.us-west-1.amazonaws.com/zenrows.jpeg",
    iconBg: "#fff",
    label: "Run Zenrows Scrape",
    subtitle: "Run Zenrows Scrape",
    categories: [""],
    credits: "FREE",
    isUseOwnKey: true,
  },
  {
    id: "addProspectToSendspark",
    name: "Add Prospect to Video Campaign (Sendspark)",
    enrichmentName: "addProspectToSendspark",
    icon: "https://persana-assets.s3.us-west-1.amazonaws.com/sendspark.png",
    iconBg: "#fff",
    label: "Add Prospect to Video Campaign (Sendspark)",
    subtitle: "Add Prospect to Video Campaign (Sendspark)",
    categories: [""],
    credits: "FREE",
    isUseOwnKey: true,
  },
  {
    id: "capterraScrape",
    name: "Capterra on Zenrows",
    enrichmentName: "capterraScrape",
    icon: "https://persana-assets.s3.us-west-1.amazonaws.com/capterra-logo.png",
    iconBg: "#fff",
    label: "Capterra on Zenrows",
    subtitle: "Capterra on Zenrows",
    categories: [""],
    credits: "FREE",
    isUseOwnKey: true,
    isForStaging: true,
  },
  {
    id: "enrichProfileByMixrank",
    name: "Profile Enrichment",
    enrichmentName: "enrichProfileByMixrank",
    icon: "https://persana-assets.s3.us-west-1.amazonaws.com/mixrank.png",
    iconBg: "#fff",
    label: "Profile Enrichment",
    subtitle: "Profile Enrichment",
    categories: ["Prospect Enrichment"],
    credits: "1",
    isForStaging: false,
  },
  {
    id: "addLeadToHeyReachCampaign",
    name: "Add Lead to Campaign (HeyReach)",
    enrichmentName: "addLeadToHeyReachCampaign",
    icon: "https://persana-assets.s3.us-west-1.amazonaws.com/HeyReach-logo.png",
    iconBg: "#fff",
    label: "Add Lead to Campaign (HeyReach)",
    subtitle: "Add Lead to Campaign (HeyReach)",
    categories: [""],
    credits: "FREE",
    isUseOwnKey: true,
    isForAdmin: true,
  },
  {
    id: "templates",
    name: "Playbooks",
    enrichmentName: "templates",
    icon: () => <Icons.TemplateIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Playbooks",
    subtitle: "Playbooks",
    categories: ["Playbooks"],
    credits: "FREE",
  },
  {
    id: "mergeColumns",
    name: "Merge Columns",
    enrichmentName: "mergeColumns",
    icon: () => <Icons.MergeIcon size={26} color="#1972f5" />,
    iconBg: "#fff",
    label: "Merge Columns",
    subtitle: "Merge Columns",
    categories: [""],
    credits: "FREE",
  },
  {
    id: "addLeadToLemListCampaign",
    name: "Add Lead to Campaign (lemlist)",
    enrichmentName: "addLeadToLemListCampaign",
    icon: "https://persana-assets.s3.us-west-1.amazonaws.com/Lemlist-logo.png",
    iconBg: "#fff",
    label: "Add Lead to Campaign (lemlist)",
    subtitle: "Add Lead to Campaign (lemlist)",
    categories: [""],
    credits: "FREE",
    isUseOwnKey: true,
  },
  {
    id: "findPeopleAtCompanyApollo",
    name: "Find People With Apollo",
    enrichmentName: "findPeopleAtCompanyApollo",
    icon: () => <Icons.PeopleIcon size={26} color={"#1972f5"} />,
    iconBg: "#fff",
    label: "Find People With Apollo",
    subtitle:
      "Identify and connect with key individuals at your target companies. Input the company name and define your ideal customer profile to get a curated list of contacts, use this with additonal enrichments such as Find Work Email to supercharge your outreach.",
    categories: ["Linkedin Enrichment", "Prospect Enrichment"],
    credits: "FREE",
    isUseOwnKey: true,
  },
  {
    id: "findPeopleAtCompanyApollo",
    name: "Find People at Company 2.0",
    enrichmentName: "findPeopleAtCompanyApollo",
    icon: () => <Icons.PeopleIcon size={26} color={"#1972f5"} />,
    iconBg: "#fff",
    label: "Find People at Company 2.0",
    subtitle:
      "Identify and connect with key individuals at your target companies. Input the company name and define your ideal customer profile to get a curated list of contacts, use this with additonal enrichments such as Find Work Email to supercharge your outreach.",
    categories: ["Linkedin Enrichment", "Prospect Enrichment"],
    credits: "1",
  },
  {
    id: "updateLeadInLemListCampaign",
    name: "Update Lead in Campaign (lemlist)",
    enrichmentName: "addLeadToLemListCampaign",
    icon: "https://persana-assets.s3.us-west-1.amazonaws.com/Lemlist-logo.png",
    iconBg: "#fff",
    label: "Update Lead in Campaign (lemlist)",
    subtitle: "Update Lead in Campaign (lemlist)",
    categories: [""],
    credits: "FREE",
    isUseOwnKey: true,
  },
  {
    id: "companyGrowthRate",
    name: "Company Growth",
    icon: () => <Icons.GrowthIcon color="#1972f5" size={26} />,
    enrichmentName: "companyGrowthRate",
    iconBg: "#fff",
    label: "",
    subtitle: "",
    categories: ["Growth"],
    credits: "3",
    isForAdmin: true,
    allowEmails: ["brent@saassalesadvisors.com"],
  },
  {
    id: "perplexity",
    name: "Perplexity AI Agent",
    icon: "https://persana-assets.s3.us-west-1.amazonaws.com/perplexity.ai.png",
    enrichmentName: "perplexity",
    iconBg: "#fff",
    label: "",
    subtitle: "",
    categories: [""],
    credits: "1",
  },
  {
    id: "leadScore",
    name: "Lead Score",
    icon: "https://persana-assets.s3.us-west-1.amazonaws.com/compare.png",
    enrichmentName: "leadScore",
    iconBg: "#fff",
    label: "",
    subtitle: "",
    categories: [],
    credits: "FREE",
    isForAdmin: false,
  },
];

export const editAbleColumns: Partial<TopEnrichmentTypes>[] = [
  {
    name: "Formula",
    enrichmentName: "formula",
  },
];

export const categories = [
  {
    name: "All Enrichments",
    icon: () => <Icons.SelectAllIcon className={`text-xl text-textPurple`} />,
  },
  { name: "Prospect Enrichment", icon: Prospect },
  // { name: "Linkedin Enrichment", icon: LinkedIn },
  { name: "Company Enrichment", icon: Company },
  { name: "Email Information", icon: Email },
  {
    name: "Phone Number",
    icon: () => <Icons.CallIcon className={`text-xl text-textPurple`} />,
  },
  { name: "Web Research", icon: Web },
  { name: "AI Enrichment", icon: AI },
  { name: "Clean Data", icon: CleanData },
  {
    name: "Playbooks",
    icon: () => <Icons.TemplateIcon className={`text-xl text-textPurple`} />,
  },
] as const;

export const REGEX = {
  LINKEDINPROFILE:
    /https?:\/\/(?:www\.)?linkedin\.com\/(?:in|profile)\/[\w-]+\/?/g,
  LINKEDINCOMPANY: /https?:\/\/(www\.)?linkedin\.com\/company\/[\w-]+\/?/gi,
  EMAIL: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gi,
  WEBSITE:
    /^(?!.*linkedin\.com)(https?:\/\/(?:[a-zA-Z0-9]{2,}\.)+[^\s/$.?#].[^\s]*)$/g,
} as const;

export const jobOpeningsTutorialLink =
  "https://www.youtube.com/embed/MPCA-5Fu3Ig?si=voR5C47hj-W_e4Fw";
export const companyTechnologiesTutorialLink =
  "https://www.youtube.com/embed/UkHqXos4iBY?si=xD9hL5x0WXwK3YOJ";
export const companyNewsTutorialLink =
  "https://www.youtube.com/embed/ViKrWs6xrRE?si=gvOhvIIiLjd9BbAG";
export const workEmailsTutorialLink =
  "https://www.youtube.com/embed/L39i0MyO2Io?si=tnoZo9mAmyNtzv_Q";
export const companyQuestionTutorialLink =
  "https://www.youtube.com/embed/G4NABAcIm_o?si=FWXMEHG-IJyofjnt";
export const googleSearchTutorialLink =
  "https://www.youtube.com/embed/KEBestxLkJQ?si=WvoqvoALBQPYv6er";
export const scrapeWebsitesTutorialLink =
  "https://www.youtube.com/embed/ucEOiJ4osIo?si=WQPkAAX6jRQ2TcBi";
export const useChatGPTTutorialLink =
  "https://www.youtube.com/embed/ucEOiJ4osIo?si=WQPkAAX6jRQ2TcBi";

export const companyNewsCategories = [
  {
    signal: "hires",
    group: "leadership",
    description: "Company hired new executive or senior personnel.",
  },
  {
    signal: "promotes",
    group: "leadership",
    description: "Company promoted existing executive or senior personnel.",
  },
  {
    signal: "leaves",
    group: "leadership",
    description: "Executive or senior personnel left the company.",
  },
  {
    signal: "retires",
    group: "leadership",
    description: "Executive or senior personnel retires from the company.",
  },
  {
    signal: "acquires",
    group: "acquisition",
    description: "Company acquired other company.",
  },
  {
    signal: "merges_with",
    group: "acquisition",
    description: "Company merges with other company.",
  },
  {
    signal: "sells_assets_to",
    group: "acquisition",
    description:
      "Company sells assets (like properties or warehouses) to other company.",
  },
  {
    signal: "expands_offices_to",
    group: "expansion",
    description:
      "Company opens new offices in another town, state, country or continent.",
  },
  {
    signal: "expands_offices_in",
    group: "expansion",
    description: "Company expands existing offices.",
  },
  {
    signal: "expands_facilities",
    group: "expansion",
    description:
      "Company opens new or expands existing facilities like warehouses, data centers, manufacturing plants etc.",
  },
  {
    signal: "opens_new_location",
    group: "expansion",
    description:
      "Company opens new service location like hotels, restaurants, bars, hospitals etc.",
  },
  {
    signal: "increases_headcount_by",
    group: "expansion",
    description: "Company offers new job vacancies.",
  },
  {
    signal: "launches",
    group: "new_offering",
    description: "Company launches new offering.",
  },
  {
    signal: "integrates_with",
    group: "new_offering",
    description: "Company integrates with other company.",
  },
  {
    signal: "is_developing",
    group: "new_offering",
    description: "Company begins development of a new offering.",
  },
  {
    signal: "receives_financing",
    group: "investment",
    description:
      "Company receives investment like venture funding, loan, grant etc.",
  },
  {
    signal: "invests_into",
    group: "investment",
    description: "Company invests into other company.",
  },
  {
    signal: "invests_into_assets",
    group: "investment",
    description:
      "Company invests into assets like property, trucks, facilities etc.",
  },
  {
    signal: "goes_public",
    group: "investment",
    description: "Company issues shares to the public for the first time.",
  },
  {
    signal: "closes_offices_in",
    group: "cost_cutting",
    description: "Company closes existing offices.",
  },
  {
    signal: "decreases_headcount_by",
    group: "cost_cutting",
    description: "Company lays off employees.",
  },
  {
    signal: "partners_with",
    group: "partnership",
    description: "Company partners with other company.",
  },
  {
    signal: "receives_award",
    group: "recognition",
    description: "Company or person at the company receives an award.",
  },
  {
    signal: "recognized_as",
    group: "recognition",
    description: "Company or person at the company receives recognition.",
  },
  {
    signal: "signs_new_client",
    group: "contract",
    description: "Company signs new client.",
  },
  {
    signal: "files_suit_against",
    group: "corporate_challenges",
    description: "Company files suit against other company.",
  },
  {
    signal: "has_issues_with",
    group: "corporate_challenges",
    description: "Company has vulnerability problems.",
  },
  {
    signal: "identified_as_competitor_of",
    group: "relational",
    description: "New or existing competitor was identified.",
  },
] as const;
