import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { hotjar } from "react-hotjar";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  Outlet,
  rootRouteWithContext,
  useNavigate,
  useRouterState,
} from "@tanstack/react-router";
import * as React from "react";
import { useEffect } from "react";

import FreeTrialBanner from "@/components/FreeTrialBanner";
import RootComponents from "@/components/RootComponents";
import Sidebar from "@/components/Sidebar";

import FallbackLoader from "@/components/Common/FallbackLoader";
import { useUserData } from "@/hooks/useUserData";
import { useEnrichStore } from "@/stores/enrich.store";
import { userStore } from "@/stores/user.store";
import { UserData } from "@/types/user.types";

export const Route = rootRouteWithContext<{
  queryClient: QueryClient;
}>()({
  component: RootComponent,
});

const TanStackRouterDevtools =
  process.env.NODE_ENV === "production"
    ? () => null
    : React.lazy(() =>
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
        })),
      );

declare global {
  interface Window {
    Intercom: any;
  }
}

function RootComponent() {
  const [isFetchingUser, setIsFetchingUser] = React.useState<boolean>(true);

  const routes = useRouterState();
  const noLayoutPaths = [
    "/login",
    "/closed-beta",
    "/complete-onboarding",
    "/oauth",
    "/admin/user_login/",
    "/table-preview/",
    "/table-preview",
  ];
  const publicPaths = [
    "/login",
    "/table-preview/",
    "/table-preview",
    "/admin/user_login/",
  ];

  const updateState = useEnrichStore((state) => state.updateState);
  const selectedEnrichments = useEnrichStore(
    (state) => state.selectedEnrichments,
  );
  const {
    currentUser,
    updateState: updateUserData,
    creditDetails,
  } = userStore();

  // Refactor this line
  const { isLoading, data } = useUserData();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const currentPath = routes.location.pathname;

  const userData = queryClient.getQueryData(["user-details"]) as UserData;

  useEffect(() => {
    if (currentUser) {
      const initialKeySelect = currentUser.useSelfOpenAIKey
        ? "ownApiKey"
        : "persanaApiKey";
      console.log("initialKeySelect", initialKeySelect);

      updateState({
        keySelect: initialKeySelect,
      });

      if (hotjar.initialized()) {
        hotjar.identify(currentUser?.Email, {
          name:
            currentUser?.profileInfo?.firstName ||
            "" + " " + currentUser?.profileInfo?.lastName ||
            "",
        });
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (isLoading) return;
    try {
      if (userData) {
        updateUserData({
          currentUser: userData,
          creditDetails: {
            creditLeft: userData?.creditLeft || 0,
            creditUsed: userData?.creditUsed || 0,
            planType: userData?.planType || "",
          },
        });

        // if (creditDetails) {
        //   updateUserData({
        //     creditDetails: creditDetails,
        //   });
        // }

        if (typeof window.Intercom === "function" && userData?.profileInfo) {
          window.Intercom("boot", {
            api_base: "https://api-iam.intercom.io",
            app_id: "v0ulpc9d",
            action_color: "#AA6BFA",
            background_color: "#AA6BFA",
            email: userData?.Email,
            user_id: userData?._id,
            name: userData?.profileInfo?.firstName || "",
          });
        }

        if (!userData?.profileInfo && userData?.token) {
          navigate({
            to: `/complete-onboarding/$token`,
            params: {
              token: userData?.token,
            },
          });
          return;
        }

        if (currentPath === "/login") {
          navigate({ to: "/" });
        }
      } else {
        if (!noLayoutPaths.includes(currentPath)) {
          navigate({ to: "/login" });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetchingUser(false);
    }
  }, [data, userData, isLoading]);

  // Update Enrichment Common State
  useEffect(() => {
    if (!selectedEnrichments?.enrichmentName) {
      updateState({
        runConditionFormula: null,
      });
    }
  }, [selectedEnrichments?.enrichmentName]);

  // Loader for initial user data
  if (isLoading || isFetchingUser) {
    return <FallbackLoader />;
  }

  if (userData?.token && !userData?.profileInfo) {
    return <Outlet />;
  }

  return (
    <div className="flex">
      {!noLayoutPaths.includes(routes.location.pathname) && <Sidebar />}

      {publicPaths.includes(routes.location.pathname) ? (
        <Outlet />
      ) : (
        <>
          {!noLayoutPaths.includes(routes.location.pathname) ? (
            <div className="main-container">
              {creditDetails?.planType === "Free" && <FreeTrialBanner />}
              <Outlet />
            </div>
          ) : (
            <Outlet />
          )}
        </>
      )}

      <React.Suspense fallback={<FallbackLoader />}>
        <RootComponents />
      </React.Suspense>
      <TanStackRouterDevtools position="top-left" />
      <ReactQueryDevtools buttonPosition="top-right" />
    </div>
  );
}
