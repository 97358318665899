import { Button, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { AgGridReact } from "ag-grid-react";

import Icons from "@/components/Icons";
import tableService from "@/services/table.service";
import { useTableStore } from "@/stores/table.store";
import { RowData } from "@/types/table.types";

interface Props {
  tableId: string;
  gridRef: React.RefObject<AgGridReact>;
  isAnyProcessRunning: boolean;
}

const AddRow = ({ tableId, gridRef, isAnyProcessRunning }: Props) => {
  const toast = useToast();

  const updateRows = useTableStore((state) => state.updateRows);

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () => tableService.insertRow(tableId),
  });

  const handleAddRow = async () => {
    await mutateAsync(undefined, {
      onSuccess: (response) => {
        if (!response.data?.success) {
          return toast({
            position: "top-right",
            title: response.data?.message || "Failed to create new row",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
        const newRow = response.data?.data as unknown as RowData[];

        if (newRow) {
          updateRows([...newRow], false);
          gridRef.current?.api?.refreshCells({ force: true });
        }

        toast({
          position: "top-right",
          title: "New row created",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          position: "top-right",
          title: "Failed to create new row",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      },
    });
  };

  return (
    <Button
      size="sm"
      variant="outline"
      leftIcon={<Icons.AddIcon size={20} />}
      onClick={handleAddRow}
      isLoading={isPending}
      className="m-auto"
      isDisabled={isAnyProcessRunning}
    >
      <span className="flex-1">Add Row</span>
    </Button>
  );
};

export default AddRow;
