import { Text } from "@chakra-ui/react";

const Header = () => {
  return (
    <div className="px-[12px] py-[8px] bg-white flex items-center justify-between bg-white">
      <Text className="text-[20px] font-[600] leading-[32px] text-[#14171F]">
        People Search
      </Text>
    </div>
  );
};

export default Header;
