import { useRef } from "react";

import DataTable from "@/components/Table";
import PageActions from "@/components/Table/PageActions";
import { AgGridReact } from "ag-grid-react";

interface Props {
  isRefetching: boolean;
}

const TablePage = ({ isRefetching }: Props) => {
  const gridRef = useRef<AgGridReact>(null);

  return (
    <div className="mx-2 mt-2  flex h-full flex-col">
      <PageActions isRefetching={isRefetching} gridRef={gridRef} />
      <DataTable isRefetching={isRefetching} gridRef={gridRef} />
    </div>
  );
};

export default TablePage;
