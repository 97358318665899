import { Button, useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import Icons from "@/components/Icons";
import tableService from "@/services/table.service";
import { useEnrichStore } from "@/stores/enrich.store";
import { useTableStore } from "@/stores/table.store";
import { convertSlateToText } from "@/utils";
import CustomTextEditor from "../../Common/CustomTextEditor";
import { defaultSlateValue } from "@/utils/constants";

function EditFormula() {
  const [isInvalidFormula, setIsInvalidFormula] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const toast = useToast();
  const viewMode = useEnrichStore((state) => state.viewMode);
  const editedFormula = useEnrichStore((state) => state.editedFormula);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const tableId = useTableStore((state) => state.tableData._id);
  const totalRows = useTableStore((state) => state.totalRows);
  const clearEnrichState = useEnrichStore((state) => state.clearState);

  const updateState = useEnrichStore((state) => state.updateState);

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (payload: {
      formula: string;
      name?: string;
      totalRows: number;
      reRunColumnId?: string;
      action?: "add" | "edit";
    }) => tableService.addFormulaColumn(tableId, payload),
  });

  const handleUseAi = () => {
    updateState({
      generateFormulaModal: {
        isOpen: true,
        type: "edit",
      },
      formulaPrompt:
        selectedColumnToEdit?.metaData?.enrichmentMetaData?.prompt ||
        defaultSlateValue,
    });
  };

  const clearAllState = () => {
    clearEnrichState();
  };

  const onSave = async () => {
    await mutateAsync(
      {
        formula: editedFormula,
        totalRows: totalRows,
        reRunColumnId: selectedColumnToEdit?._id,
        action: "edit",
      },
      {
        onSuccess: (response) => {
          if (!response.success || response.error) {
            return toast({
              position: "top-right",
              title: response.data?.message || "Failed to create new column",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
          if (response?.success) {
            clearAllState();

            toast({
              position: "top-right",
              title: "Success",
              description: "Formula added to the table",
              status: "success",
              duration: 2000,
              isClosable: true,
            });

            queryClient.refetchQueries({
              queryKey: ["table-running-processes", tableId],
            });
          }
        },
      },
    );
  };

  useEffect(() => {
    if (viewMode === "edit" && selectedColumnToEdit) {
      updateState({
        editedFormula:
          selectedColumnToEdit?.metaData?.enrichmentMetaData?.formula || [],
      });
    }
  }, [selectedColumnToEdit, viewMode]);

  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <CustomTextEditor
          editorHeight={"10rem"}
          isCodeView
          slateValue={editedFormula}
          placeholder={`"Hi " + {{ Name }}.`}
          setSlateValue={(value: any) => {
            const formula = convertSlateToText(value);

            if (formula?.text === "") {
              updateState({
                editedFormula: value,
              });
              setIsInvalidFormula(false);
              return;
            }

            updateState({
              editedFormula: value,
            });
          }}
          isInvalidFormula={isInvalidFormula}
          actionBtn={
            <button
              onClick={handleUseAi}
              className="flex items-center gap-x-1.5 rounded bg-primary px-2.5 py-1 text-sm font-medium text-white"
            >
              <Icons.Processor className="inline-block rotate-180" />
              <span>Use AI</span>
            </button>
          }
        />
      </div>
      <div
        className={`flex flex-wrap items-center gap-y-2.5 border-t border-[#e7e8ec] p-4`}
      >
        <Button
          isDisabled={!editedFormula || isInvalidFormula}
          isLoading={isPending}
          size={"sm"}
          onClick={onSave}
          className="flex-1"
        >
          Save and run for all
        </Button>
      </div>

      {/* <SaveAndRunEnrichment
                isDisabled={!editedFormula || isInvalidFormula}
                apiPayload={{}}
            /> */}
    </>
  );
}

export default EditFormula;
