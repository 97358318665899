import SearchImg from "@/assets/svgs/search.svg";

const BeginSearch = () => {
  return (
    <div className="flex size-full items-center justify-center flex-col gap-[56px] w-[400px] m-auto">
      <img src={SearchImg} alt="" />
      <div>
        <p className="text-[16px] leading-[24px] font-medium text-center mb-[10px] w-full">
          Begin your search
        </p>
        <p className="text-[14px] leading-[20px] font-[400] text-center text-[#4A5264] w-full">
          Apply filters on the left to start choosing between all the companies
          in our database.
        </p>
      </div>
    </div>
  );
};

export default BeginSearch;
