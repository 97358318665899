/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@tanstack/react-query";

import tableService from "@/services/table.service";

export const useTableData = (tableId: string, enabled: boolean = true) => {
  return useQuery({
    queryKey: ["table", tableId],
    queryFn: ({ signal }) => {
      return tableService.fetchTableData({
        tableId,
        signal,
      });
    },
    refetchOnMount: true,
    staleTime: 1,
    gcTime: 1,
    retry: 2,
    enabled,
  });
};
