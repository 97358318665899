import { useState } from "react";
import { Button, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";

import Icons from "../Icons";
import { allTemplates } from "../Leads/UseTemplateModal/constants";
import PersanaLogo from "@/assets/images/logo.png";

const Templates = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [templates, setTemplates] = useState(allTemplates);
  const handleFilterChange = (query: string) => {
    setSearchValue(query);
    if (query) {
      const filteredTemplates = allTemplates.filter(
        (item) =>
          item.heading
            .toLocaleLowerCase()
            .includes(query.toLocaleLowerCase()) ||
          item.title.toLocaleLowerCase().includes(query.toLocaleLowerCase()),
      );
      setTemplates(filteredTemplates);
    } else {
      setTemplates(allTemplates);
    }
  };
  return (
    <div>
      <div className="py-[32px] px-[32px] flex flex-col items-center justify-center gap-4 bg-white">
        <p className="text-[32px] font-bold leading-[32px]">Playbooks</p>
        <InputGroup width="80%">
          <InputLeftElement>
            <Icons.SearchIcon size={20} color="gray" className="mt-2" />
          </InputLeftElement>
          <Input
            placeholder="Search playbooks..."
            size="lg"
            value={searchValue}
            onChange={(event) => {
              handleFilterChange(event?.target.value);
            }}
          />
        </InputGroup>
      </div>
      <div className="px-[32px] py-[16px] grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-4">
        {templates.map((item) => (
          <div
            key={item.templateId}
            className="flex flex-col justify-between w-full rounded-[12px] bg-white py-[32px] px-[24px] shadow dark:!bg-navy-700 overflow-hidden  hover:shadow-xl"
          >
            <div className="mb-4 flex flex-col gap-[20px]">
              <img src={PersanaLogo} height={30} width={30} />
              <div className="font-medium text-[18px] leading-[24px]">
                {item.heading}
              </div>
              <p className="text-[#4A5264] text-[16px]">{item.title}</p>
            </div>
            <Button
              onClick={() => {
                navigate({
                  to: `/playbooks/$templateId`,
                  params: {
                    templateId: item.heading,
                  },
                });
              }}
              variant="outline"
              className="h-[40px] w-[140px] rounded-md !bg-primary-gradient !text-white hover:shadow"
            >
              Try Now
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Templates;
