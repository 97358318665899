import { AgGridReact } from "ag-grid-react";
import TableViews from "../TableViews";
import AddRow from "./AddRow";
import ColumnList from "./ColumnList";
import RunningProcesses from "./RunningProcesses";
import { useProcessingStore } from "@/stores/processing.store";

interface Props {
  gridRef: React.RefObject<AgGridReact>;
  tableId: string;
}

const BottomPageActions = ({ tableId, gridRef }: Props) => {
  const isTablePreviewPage =
    window.location.pathname.startsWith("/table-preview");
  const processingData = useProcessingStore((state) => state.processingData);
  const isAnyProcessRunning = !!processingData?.length;

  return (
    <div className="mt-1 flex h-[50px] items-center gap-5 pb-[0.25rem] pt-[0.50rem]">
      {!isTablePreviewPage && (
        <div className="flex items-center gap-3">
          <AddRow
            tableId={tableId}
            gridRef={gridRef}
            isAnyProcessRunning={isAnyProcessRunning}
          />
          <ColumnList />
          <TableViews />
        </div>
      )}
      <RunningProcesses />
    </div>
  );
};

export default BottomPageActions;
