import { HStack } from "@chakra-ui/react";
import { IRadioCardGroup } from "./types";
import { RadioCard } from "./radioCard";

export function RadioCardGroup<T extends string>(props: IRadioCardGroup<T>) {
  return (
    <HStack className="inline-grid grid-cols-2 items-center gap-1 overflow-hidden rounded-lg border-none p-0.5 text-sm font-medium sm:grid-cols-4">
      {props.options.map(({ value, label, icon: Icon }) => {
        return (
          <RadioCard
            key={value}
            isChecked={value === props.value}
            onChange={(e) => (e.target.checked ? props.setValue(value) : null)}
          >
            <Icon className="mb-1 hidden size-5 shrink-0 stroke-2 sm:inline" />
            <span>{label}</span>
          </RadioCard>
        );
      })}
    </HStack>
  );
}
