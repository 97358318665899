import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import type { ICellRendererParams } from "ag-grid-community";
import { useCallback } from "react";

import CustomErrorBoundary from "../Common/ErrorComponent/CustomErrorBoundary";
import Icons from "../Icons";

import { queryClient } from "@/lib/queryClient";
import enrichment from "@/services/enrichment.service";
import tableService from "@/services/table.service";
import { useModalStore } from "@/stores/modal.store";
import { useProcessingStore } from "@/stores/processing.store";
import { useTableStore } from "@/stores/table.store";
import {
  EnrichmentApiCallPayload,
  TableQueueReRunPayload,
} from "@/types/enrichment.types";
import { isUrl, showItalicText } from "@/utils";

const CustomCell = (props: ICellRendererParams) => {
  const toast = useToast();
  const isTablePreviewPage =
    window.location.pathname.startsWith("/table-preview");
  const isUserLoggedIn = queryClient.getQueryData(["user-details"]);
  const updateState = useTableStore((state) => state.updateState);
  const handleCellClick = useTableStore((state) => state.handleCellClick);
  const updateTableMetaDta = useTableStore((state) => state.updateTableMetaDta);
  const tableRows = useTableStore((state) => state.rowsData);
  const tableData = useTableStore((state) => state.tableData);
  const processingData = useProcessingStore((state) => state.processingData);
  const { updateState: updateModalState } = useModalStore((state) => state);

  const columnDef = props.column?.getColDef();
  const columnId = props.column?.getColId() || "";

  const isTableQueueColumn =
    columnDef?.headerComponentParams?.columnData?.metaData?.queueType ===
    "tableQueue";
  const columnEvent =
    columnDef?.headerComponentParams?.columnData?.metaData?.event;
  let cellValue = props.value ?? "";
  cellValue =
    typeof cellValue === "object" ? JSON.stringify(cellValue) : `${cellValue}`;

  const isLink = isUrl(cellValue);
  const isItalic = showItalicText(cellValue);
  const rowData = props?.data?.rowData;

  const isAnyRunningProcess =
    (
      processingData?.filter((data) => data.processingColumnId === columnId) ||
      []
    ).length > 0;

  const { mutateAsync: enrichMutationAsync, isPending: isReRunning } =
    useMutation({
      mutationFn: (payload: Partial<EnrichmentApiCallPayload>) =>
        enrichment.callEnrichmentApi(payload),
    });

  const { mutateAsync: callToReRunTableQueue } = useMutation({
    mutationFn: (payload: TableQueueReRunPayload) =>
      tableService.callToReRunColumn(payload),
  });

  const getImgUrl = (rowId: string) => {
    const row = tableRows.filter((item) => item._id === rowId);
    let imageUrl = "";
    const cells = row?.[0]?.cells;
    if (columnId && cells) {
      imageUrl = cells[columnId] ? cells[columnId]?.imgURL || "" : "";
    }

    return (
      imageUrl ||
      columnDef?.headerComponentParams?.columnData?.metaData?.iconImg
    );
  };

  const handleFullView = useCallback(() => {
    updateState({
      expendedCellValue: {
        isOpen: true,
        columnName: columnDef?.headerName || "",
        columnId: props.column?.getColId() || "",
        rowId: props?.data?.rowData?._id || "",
        cellValue: props.value || "",
        rowIndex: props.rowIndex,
        showSendOption:
          columnDef?.headerComponentParams?.columnData?.metaData
            ?.enrichmentMetaData?.enrichmentName === "draftEmail" &&
          columnDef?.headerComponentParams?.columnData?.metaData
            ?.enrichmentMetaData?.showSendOption,
      },
    });
  }, [props, isTablePreviewPage]);

  const handleInfoClick = useCallback(() => {
    updateModalState({
      isOpenModal: true,
      modalData: {
        modalType: "table-details",
        metaData: {
          rowIndex: props.rowIndex,
        },
      },
    });
  }, [props, isTablePreviewPage]);

  const rerunCell = async (event: React.MouseEvent<SVGElement, MouseEvent>) => {
    event.stopPropagation();

    if (isTableQueueColumn) {
      await callToReRunTableQueue({
        tableId: tableData._id,
        columnId,
        payload: {
          action: "selected",
          selectedRowIds: [rowData?._id],
          ...columnDef?.headerComponentParams?.columnData?.metaData,
        },
      });

      invalidateQueriesAndUpdateState();
      return;
    }

    const payLoad: Partial<EnrichmentApiCallPayload> = {
      ...columnDef?.headerComponentParams?.columnData?.metaData
        ?.enrichmentMetaData,
      reRunColumnId: columnId,
      selectedRowsId: [rowData?._id],
      isSelectedAll: false,
      totalDataToProcess: 1,
      action: "selected",
      tableId: tableData._id,
    };
    updateTableMetaDta({
      isProcessing: true,
      totalDataToProcess: 1,
      processingMessage: "",
    });
    await enrichMutationAsync(payLoad, {
      onSuccess: (response) => {
        if (!response.success) {
          toast({
            title:
              response?.error?.message ||
              response?.data?.message ||
              "Something went wrong",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
          updateTableMetaDta({
            isProcessing: false,
            totalDataToProcess: 0,
            processedData: 0,
          });
          return;
        }
        toast({
          title: response.data?.message || "Enrichment started",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });

        invalidateQueriesAndUpdateState();
      },
      onError: (err) => {
        toast({
          title: err.message || "Something went wrong",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        updateTableMetaDta({
          isProcessing: false,
          totalDataToProcess: 0,
          processedData: 0,
        });
      },
    });
  };

  const invalidateQueriesAndUpdateState = () => {
    queryClient.refetchQueries({
      queryKey: ["table-running-processes", tableData._id],
    });

    const updatedRows = tableRows.map((row) => {
      const newRow = { ...row };

      if (rowData?._id === row._id) {
        newRow.cells = {
          ...newRow.cells,
          [columnId]: {
            value: "queued...",
          },
        };
      }

      return newRow;
    });

    updateState({
      tableData: {
        ...tableData,
        metaData: {
          isProcessing: true,
          processedData: 0,
          totalDataToProcess: 1,
        },
      },
      rowsData: updatedRows,
      selectedRowsId: [],
    });
  };

  const imgURL = getImgUrl(rowData?._id);

  return (
    <>
      <div
        className="group flex size-full cursor-pointer items-center justify-between px-[15px]"
        onContextMenu={(event) => {
          if (!isTablePreviewPage) {
            event.preventDefault();
            const { clientX, clientY } = event;
            updateState({
              contextMenuState: {
                x: clientX,
                y: clientY,
                metaData: {
                  rowId: props?.data?.rowData?._id,
                },
              },
            });
          }
        }}
        onClick={() => {
          if (columnEvent === "modal" && !isTablePreviewPage) {
            handleCellClick(
              columnDef?.headerComponentParams?.columnData,
              rowData,
            );
          }
        }}
        style={
          columnDef?.headerComponentParams?.columnData?.metaData?.highlightColor
            ? {
                backgroundColor:
                  columnDef?.headerComponentParams?.columnData?.metaData
                    ?.highlightColor || "#fff",
              }
            : {}
        }
      >
        {isLink && (
          <Icons.LinkIcon
            className="mr-2  shrink-0 text-primary"
            onClick={() => {
              let url = cellValue;
              if (
                !cellValue.startsWith("http://") &&
                !cellValue.startsWith("https://")
              ) {
                url = "http://" + cellValue;
              }

              window.open(url, "_blank");
            }}
            size={20}
          />
        )}
        {!!imgURL && (
          <img src={imgURL} className="mr-2 size-[20px] rounded-full" />
        )}
        <span
          className={`w-[90%] truncate ${
            isLink ? "text-primary" : "text-darkTextGray"
          } ${isTablePreviewPage && props.rowIndex > 9 && !isUserLoggedIn && "blur-sm"} ${isItalic && "italic"}`}
        >
          {cellValue}
        </span>
        {columnDef?.headerComponentParams?.columnData?.metaData
          ?.isIndexColumn &&
          !isTablePreviewPage &&
          !isAnyRunningProcess && (
            <Icons.OpenEyeIcon
              className="shrink-0 opacity-[0.3]"
              onClick={handleInfoClick}
              size={20}
            />
          )}
        {!columnDef?.headerComponentParams?.columnData?.metaData
          ?.isIndexColumn &&
          columnEvent !== "modal" &&
          !isTablePreviewPage &&
          !isAnyRunningProcess && (
            <Icons.FullscreenIcon
              className="hidden shrink-0 group-hover:inline-block"
              onClick={(e: any) => {
                e.stopPropagation();
                handleFullView();
              }}
              size={20}
            />
          )}
        {(columnDef?.headerComponentParams?.columnData?.metaData?.isShowReRun ||
          isTableQueueColumn) &&
          !isTablePreviewPage &&
          !isAnyRunningProcess && (
            <Icons.PlayIcon
              className={`ml-2 hidden shrink-0 text-[#693DC7] group-hover:inline-block ${isReRunning ? "animate-pulse cursor-not-allowed" : ""}`}
              size={18}
              onClick={(e: React.MouseEvent<SVGElement, MouseEvent>) =>
                isReRunning ? null : rerunCell(e)
              }
            />
          )}
        {columnEvent === "modal" && !isTablePreviewPage && (
          <Icons.ArrowDownIcon
            className="shrink-0 text-darkTextGray"
            size={20}
          />
        )}
      </div>
    </>
  );
};

const WrappedCustomCell = (props: ICellRendererParams) => (
  <CustomErrorBoundary>
    <CustomCell {...props} />
  </CustomErrorBoundary>
);

export default WrappedCustomCell;
