import { useEffect } from "react";
import { queryClient } from "@/lib/queryClient";
import { useTable } from "@/components/DataTable/Table/hooks/useTable";
import { useRunningProcessesByTypes } from "@/hooks/useRunningProcesses";
import useSocket from "@/lib/socket";
import { useProcessingStore } from "@/stores/processing.store";
import { IsocketJobChangesData } from "@/types/socket.types";

export const useJobSync = () => {
  const { updateTableRow, finished } = useTable();
  const { subscribe, connect, disconnect } = useSocket();
  const updateProcessingData = useProcessingStore(
    (state) => state.updateProcessingData,
  );
  const updateProcessingState = useProcessingStore(
    (state) => state.updateState,
  );
  const { data: processingData } =
    useRunningProcessesByTypes("track_job_changes");

  useEffect(() => {
    if (processingData?.data) {
      updateProcessingState({
        processingData: processingData.data,
      });
    }
  }, [processingData?.data]);
  useEffect(() => {
    connect();
    subscribe("jobChanges", async (data: IsocketJobChangesData) => {
      if (data.leadData) {
        // @ts-expect-error backend inconsistency
        updateTableRow(data.leadData.lead ? data.leadData.lead : data.leadData);
      }
      if (data.processingData) {
        updateProcessingData(data.processingData);
        if (!data.processingData.isProcessing) {
          queryClient.invalidateQueries({
            predicate: (query) => {
              return query.queryKey[0] === "table-running-processes";
            },
          });
          finished();
          queryClient.invalidateQueries({
            queryKey: ["filterValues", "jobChanges"],
          });
        }
      }
    });
    return () => {
      disconnect();
    };
  }, []);
  return processingData;
};
