import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";

export type ProviderNameType =
  | "openAiApiKey"
  | "github"
  | "hubspotApiKey"
  | "smartLeadsApiKey"
  | "apifyApiKey"
  | "claude"
  | "datagma"
  | "leadMagicKey"
  | "laGrowthMachineKey"
  | "instantlyApiKey"
  | "zenrowsApiKey"
  | "sendsparkCredentials"
  | "heyReachApiKey"
  | "lemListApiKey"
  | "icyPeasApiKey"
  | "icyPeasUserId"
  | "apolloio"
  | "nimbler"
  | "forager"
  | "Datagma"
  | "perplexityApiKey"
  | "";

type State = {
  isProviderApiKeyModalOpen: boolean;
  providerApiKeyModalData: {
    title: string;
    providerName: ProviderNameType;
    secretKeyInput?: boolean;
    extraKeyField?: {
      label: string;
      keyName: ProviderNameType;
    };
  };
};

type Actions = {
  updateState: (payload: Partial<State>) => void;
};

export const useProviderStore = create<State & Actions>((set) => ({
  isProviderApiKeyModalOpen: false,
  providerApiKeyModalData: {
    // Fix: Changed property name from 'providerKeyModalData' to 'providerApiKeyModalData'
    title: "",
    providerName: "",
    secretKeyInput: false,
  },

  updateState: (payload) => set(() => payload),
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("Providers", useProviderStore);
}
