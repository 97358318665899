import {
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  useDisclosure,
  useOutsideClick,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IHeaderParams } from "ag-grid-community";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Swal from "sweetalert2";

import CustomErrorBoundary from "../Common/ErrorComponent/CustomErrorBoundary";

import enrichment from "@/services/enrichment.service";
import tableService from "@/services/table.service";
import { useEnrichStore } from "@/stores/enrich.store";
import { usePresetsStore } from "@/stores/presets.store";
import { useTableStore } from "@/stores/table.store";
import { tableActionIcons } from "../Icons/tableActionIcons";

import { generateUniqueId } from "@/lib/utils";
import { useProcessingStore } from "@/stores/processing.store";
import {
  EnrichmentApiCallPayload,
  TableQueueReRunPayload,
} from "@/types/enrichment.types";
import type { Column } from "@/types/table.types";
import { allEnrichments, editAbleColumns } from "../Enrichment/constants";
import Icons from "../Icons";
import { colConditions, conditions } from "./PageActions/Filters/constants";
import { addColumnOptions, headOptions } from "./constants";
import { getEnrichmentId } from "./utils";

export interface ICustomHeaderParams extends IHeaderParams {
  columnData: Column;
  isRefetching?: boolean;
}

const CustomHeader = (props: ICustomHeaderParams) => {
  const popoverRef = useRef(null);
  const isTablePreviewPage =
    window.location.pathname.startsWith("/table-preview");
  const displayName = props.displayName;
  const columnId = props.columnData?._id;
  const isRefetching = props.isRefetching;
  const isFormulaColumn =
    props.columnData?.metaData?.enrichmentMetaData?.enrichmentName ===
    "formula";

  const toast = useToast({
    position: "top-right",
    id: "header-changes",
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isPopoverOpen,
    onToggle,
    onClose: popoverOnClose,
  } = useDisclosure();
  useOutsideClick({
    enabled: isPopoverOpen,
    ref: popoverRef,
    handler: popoverOnClose,
  });
  const queryClient = useQueryClient();

  const headerRef = useRef(null);
  const [displayHeaderName, setDisplayHeaderName] = useState(displayName);

  const selectedViewId = useTableStore(
    (state) => state.tableData.selectedViewId,
  );
  const tableViews = useTableStore((state) => state.tableData.views || []);
  const tableId = useTableStore((state) => state.tableData._id);
  const totalRows = useTableStore((state) => state.totalRows);
  const allColumns = useTableStore((state) => state.tableData.columns);
  const tableRows = useTableStore((state) => state.rowsData);
  const deleteColumn = useTableStore((state) => state.deleteColumn);
  const updateState = useTableStore((state) => state.updateState);
  const updateEnrichmentState = useEnrichStore((state) => state.updateState);
  const tableData = useTableStore((state) => state.tableData);
  const selectedRowsId = useTableStore((state) => state.selectedRowsId);
  const sortingProcessingColumnKey = useTableStore(
    (state) => state.sortingProcessingColumnKey,
  );
  const tableFilters = useTableStore((state) => state.tableFilters);
  const queryParams = useTableStore((state) => state.queryParams);
  const updateColumns = useTableStore((state) => state.updateColumns);
  const [existingColumnName] = useState(props.displayName);
  const [columnName, setColumnName] = useState(() => props.displayName);
  const processingData = useProcessingStore((state) => state.processingData);

  const clearTableState = useTableStore((state) => state.clearState);
  const clearEnrichState = useEnrichStore((state) => state.clearState);
  const clearPresetState = usePresetsStore((state) => state.clearState);

  const uniqueIdentifier =
    props.columnData?.metaData?.enrichmentMetaData?.uniqueIdentifier;

  const currentView = tableViews.find((view) => view._id === selectedViewId);

  const linkedColumnIds = allColumns
    .map((column) => {
      if (
        uniqueIdentifier &&
        column.metaData?.enrichmentMetaData?.uniqueIdentifier ===
          uniqueIdentifier
      ) {
        return column._id;
      }
      return null;
    })
    .filter(Boolean);

  const isAnyRunningProcess = processingData?.filter(
    (data) =>
      data.processingColumnId === columnId ||
      props.columnData?.metaData?.refColumnIds?.includes(
        data.processingColumnId,
      ) ||
      linkedColumnIds.includes(data.processingColumnId),
  );

  const { mutateAsync } = useMutation({
    mutationFn: ({
      tableId,
      columnId,
    }: {
      tableId: string;
      columnId: string;
    }) => tableService.deleteColumns(tableId, [columnId]),
  });

  const { mutateAsync: removeDuplicate, isPending: isRemoving } = useMutation({
    mutationFn: ({
      tableId,
      payload,
    }: {
      tableId: string;
      payload: {
        columnId: string;
        totalRows: number;
      };
    }) => tableService.removeDuplicateRows(tableId, payload),
  });

  const { mutateAsync: enrichMutationAsync, isPending: isRerunning } =
    useMutation({
      mutationFn: (payload: Partial<EnrichmentApiCallPayload>) =>
        enrichment.callEnrichmentApi(payload),
    });

  const { mutateAsync: callToReRunTableQueue } = useMutation({
    mutationFn: (payload: TableQueueReRunPayload) =>
      tableService.callToReRunColumn(payload),
  });

  const { mutateAsync: updateColumnNameAsync, isPending } = useMutation({
    mutationFn: ({
      tableId,
      name,
      columnId,
    }: {
      tableId: string;
      name: string;
      columnId: string;
    }) =>
      tableService.updateColumnData(tableId, columnId, {
        name,
      }),
  });

  const { mutateAsync: updateColumnPinnedState } = useMutation({
    mutationFn: (
      payload: Parameters<typeof tableService.createOrUpdateView>[0],
    ) => tableService.createOrUpdateView(payload),
  });

  const selectedColumnToEdit = useMemo(() => {
    if (!props?.columnData) return null;

    if (
      props.columnData.metaData?.isParentColumn ||
      props.columnData.metaData?.isShowReRun ||
      isFormulaColumn
    ) {
      return props.columnData;
    }

    const uniqueIdentifier =
      props.columnData?.metaData?.enrichmentMetaData?.uniqueIdentifier;
    const parentColumn = allColumns.find((column) => {
      if (
        uniqueIdentifier &&
        column.metaData?.enrichmentMetaData?.uniqueIdentifier ===
          uniqueIdentifier &&
        column.metaData?.isParentColumn
      ) {
        return true;
      }
      return false;
    });

    return parentColumn;
  }, [props?.columnData, allColumns]);

  const editableColumn = useMemo(() => {
    if (!selectedColumnToEdit) return null;

    return props.columnData
      ? allEnrichments.find((enrichment) => {
          if (enrichment.enrichmentName === "formalizer") {
            return (
              enrichment.enrichmentName ===
                props.columnData.metaData?.enrichmentMetaData?.enrichmentName &&
              getEnrichmentId(
                props.columnData.metaData?.enrichmentMetaData?.formalizerType ||
                  "Company",
              ) === enrichment.id
            );
          } else {
            return (
              enrichment.enrichmentName ===
              props.columnData.metaData?.enrichmentMetaData?.enrichmentName
            );
          }
        }) ??
          editAbleColumns.find(
            (enrichment) =>
              enrichment.enrichmentName ===
              props.columnData.metaData?.enrichmentMetaData?.enrichmentName,
          ) ??
          null
      : null;
  }, [props, selectedColumnToEdit]);

  const headerOptions = headOptions.filter((option) => {
    if (isFormulaColumn) {
      return true;
    }
    if (option.type === "onlyForEnrichment") {
      return !!editableColumn;
    }
    return true;
  });

  const handleDuplicateRows = async () => {
    await removeDuplicate(
      {
        tableId,
        payload: {
          columnId,
          totalRows,
        },
      },
      {
        onSuccess: (response) => {
          queryClient.refetchQueries({
            queryKey: ["table-running-processes", tableId],
          });
          toast({
            title: "Remove duplicate rows",
            description: response?.data?.message || "Started Processings",
            status: "success",
            position: "top-right",
          });
        },

        onError: (error) => {
          toast({
            title: "Remove duplicate rows",
            description: error?.message || "Failed to remove duplicate rows",
            status: "error",
          });
        },
      },
    );
  };

  const handleDeleteColumn = () => {
    toast.closeAll();
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#AA6BFA",
      confirmButtonText: "Yes, delete it!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        return await mutateAsync(
          {
            tableId,
            columnId,
          },
          {
            onSuccess: (response) => {
              if (response.success == false) {
                toast({
                  title: "Something went wrong",
                  description: response.error.message,
                  status: "error",
                  duration: 9000,
                  isClosable: true,
                });
                return;
              }

              // Update state
              deleteColumn(columnId);

              queryClient.invalidateQueries({
                queryKey: ["table", tableId],
              });

              toast({
                title: `"${props.columnData.name}" Column deleted successfully.`,
                status: "success",
                duration: 2000,
                isClosable: true,
              });
            },
            onError: (error) => {
              toast({
                title: "Something went wrong",
                description: error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            },
          },
        );
      },
    });
  };

  const handleFilter = () => {
    updateState({
      tableFilters: {
        ...tableFilters,
        open: true,
        filters: [
          ...(tableFilters.filters || []),
          {
            id: generateUniqueId(),
            column: props.columnData,
            type: "input",
            colCondition: colConditions[0],
            condition: conditions[0],
            value: "",
          },
        ],
      },
      isFiltering: true,
    });
  };

  const updateColumnName = useCallback(
    async (columnName: string) => {
      const isExists = allColumns.find((column) => column.name == columnName);
      if (isExists) {
        return;
      }

      // Call API using debounce
      await updateColumnNameAsync(
        { tableId, name: columnName, columnId },
        {
          onSuccess: (response) => {
            if (response.success == false) {
              toast({
                title: "Something went wrong",
                description: response.error.message,
                status: "error",
                duration: 9000,
                isClosable: true,
              });
              return;
            }

            const updatedColumns = response.data?.columns;

            if (!updatedColumns || !updatedColumns.length) return;

            queryClient.refetchQueries({
              queryKey: ["table", tableId],
            });

            toast({
              title: `"${props.displayName}" Column name updated successfully`,
              status: "success",
              duration: 1000,
              isClosable: true,
            });
          },
          onError: (error) => {
            toast({
              title: "Something went wrong",
              description: error.message,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          },
        },
      );
    },
    [columnId, allColumns, tableData],
  );

  const handleSort = useCallback(
    async (type: "ASC" | "DESC") => {
      try {
        if (
          queryParams?.sortingKey === columnId &&
          queryParams?.sortOrder === type
        ) {
          updateState({
            queryParams: {
              ...queryParams,
              sortingKey: "",
              sortOrder: "ASC",
              pageNumber: 1,
            },
            sortingProcessingColumnKey: columnId,
          });
          return;
        }

        updateState({
          queryParams: {
            ...queryParams,
            sortingKey: columnId,
            sortOrder: type === "ASC" ? "ASC" : "DESC",
            pageNumber: 1,
          },
          sortingProcessingColumnKey: columnId,
        });
      } catch (error: any) {
        toast({
          title: "Something went wrong",
          description: error?.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } finally {
        onClose();
      }
    },
    [queryParams, columnId],
  );

  const handleEdit = useCallback(() => {
    if (!editableColumn && !isFormulaColumn) return;

    // To handle reference update
    const selectedEnrichment = {
      ...editableColumn,
    };

    const isWaterfall =
      props.columnData.metaData?.enrichmentMetaData?.isWaterfall;

    if (isWaterfall && selectedEnrichment) {
      selectedEnrichment.id = (selectedEnrichment?.id || "") + "Waterfall";
      selectedEnrichment.isWaterfall = true;
    }

    console.log("myLog selectedColumnToEdit,", selectedColumnToEdit);

    updateEnrichmentState({
      selectedEnrichments: {
        ...selectedEnrichment,
        enrichmentName: selectedEnrichment?.enrichmentName || "", // Provide a default value for enrichmentName
      },
      isOpenEnrichModal: false,
      viewMode: "edit",
      selectedColumnToEdit,
    });

    onClose();
  }, [props, editableColumn]);

  const handlePinColumn = useCallback(() => {
    const pinned = props.columnData?.metaData?.pinned === "left" ? "" : "left";

    updateColumns({
      _id: columnId,
      metaData: {
        pinned,
      },
    });

    let _pinnedColumns = currentView?.pinnedColumnIds || [];

    if (pinned === "left") {
      _pinnedColumns = [columnId, ..._pinnedColumns];
    } else {
      _pinnedColumns = _pinnedColumns.filter((colId) => colId !== columnId);
    }

    console.log("myLog :>>", { tableId, pinned, columnId, _pinnedColumns });

    updateColumnPinnedState({
      tableId,
      viewId: selectedViewId,
      bodyData: {
        pinnedColumnIds: _pinnedColumns,
      },
    });

    onClose();
  }, [columnId, currentView]);

  const clearAllState = () => {
    clearTableState();
    clearEnrichState();
    clearPresetState();
  };

  const handleRunColumn = async (
    action: "all" | "failed" | "selected",
    isForTableQueue?: boolean,
  ) => {
    onClose();
    popoverOnClose();

    const enrichmentMetaDta = props?.columnData?.metaData?.enrichmentMetaData;

    console.log(props.columnData?.metaData, "column metaData");

    console.log("isForTableQueue", isForTableQueue);
    console.log("action", action);

    if (isForTableQueue) {
      console.log("table queue");

      const columnMetaData = props.columnData?.metaData;

      const rowIds = tableRows.map((row) => row._id) || [];

      await callToReRunTableQueue({
        tableId,
        columnId,
        payload: {
          action,
          selectedRowIds: action === "selected" ? selectedRowsId : [],
          ...columnMetaData,
          rowIds,
        },
      });

      refetchQueriesAndUpdateQueueState({
        action,
        columnId,
      });

      return;
    }

    if (!enrichmentMetaDta) return;

    enrichmentMetaDta.customRowsCount = 0;

    const payLoad: Partial<EnrichmentApiCallPayload> = {
      ...enrichmentMetaDta,
      reRunColumnId: props?.columnData?._id,
      selectedRowsId: action === "selected" ? selectedRowsId : [],
      isSelectedAll: true,
      customRowsCount: 0,
      totalDataToProcess: action === "all" ? totalRows : selectedRowsId.length,
      action,
      tableId,
      startRange: 0,
      endRange: 0,
    };

    await enrichMutationAsync(payLoad, {
      onSuccess: (response) => {
        clearAllState();

        if (!response.success || response.data.success === false) {
          toast({
            title:
              response?.error?.message ||
              response?.data?.message ||
              "Something went wrong",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
          return;
        }
        toast({
          title: response.data?.message || "Enrichment started",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });

        const newAddedColumn = response?.data?.newColumns || [];

        const newColumnId = newAddedColumn?.[0]?._id;

        if (newColumnId) {
          refetchQueriesAndUpdateQueueState({
            columnId: newColumnId,
            action,
          });
        }
      },
      onError: (err) => {
        toast({
          title: err.message || "Something went wrong",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      },
    });
  };

  const refetchQueriesAndUpdateQueueState = ({
    columnId,
    action,
  }: {
    columnId: string;
    action: "all" | "failed" | "selected";
  }) => {
    queryClient.refetchQueries({
      queryKey: ["table-running-processes", tableId],
    });
    queryClient.invalidateQueries({
      queryKey: ["user-credit-details"],
    });

    const newColumnId = columnId;

    const updatedRows = tableRows.map((row) => {
      const newRow = { ...row };

      // TODO: queued status for failed/empty rows
      if (action === "selected") {
        if (selectedRowsId.includes(row._id)) {
          newRow.cells = {
            ...newRow.cells,
            [newColumnId]: {
              value: "queued...",
            },
          };
        }
      } else if (action === "failed") {
        if (
          newRow.cells[newColumnId]?.status === "error" ||
          !newRow.cells[newColumnId]?.hasOwnProperty("status") ||
          !(newColumnId in newRow.cells)
        ) {
          newRow.cells = {
            ...newRow.cells,
            [newColumnId]: {
              value: "queued...",
            },
          };
        }
      } else {
        newRow.cells = {
          ...newRow.cells,
          [newColumnId]: {
            value: "queued...",
          },
        };
      }

      return newRow;
    });

    updateState({
      tableData: {
        ...tableData,
      },
      rowsData: updatedRows,
      selectedRowsId: [],
    });
  };

  const HeaderRightIcon = useMemo(() => {
    if (!queryParams.sortingKey || queryParams.sortingKey !== columnId)
      return null;
    if (queryParams.sortOrder === "ASC") {
      return Icons.AscSortIcon;
    }
    return Icons.DescSortIcon;
  }, [columnId, queryParams.sortingKey, queryParams.sortOrder]);

  const HeaderIcon =
    tableActionIcons[props.columnData?.metaData?.iconType || "text"];

  const isProcessing =
    sortingProcessingColumnKey === columnId ||
    !!isAnyRunningProcess?.length ||
    isRerunning;
  const isEnrichedColumn = !!props.columnData?.metaData?.isShowReRun;
  const isTableQueueColumn =
    props.columnData?.metaData?.queueType === "tableQueue";

  if (props.displayName === "#") {
    return (
      <span className="ml-3">
        <span>{props.displayName}</span>
      </span>
    );
  }

  const onMenuClose = () => {
    if (columnName !== existingColumnName) {
      updateColumnName(columnName);
    }

    onClose();
  };

  const updateDisplayHeaderName = useCallback(() => {
    const headerElement = headerRef.current;
    if (headerElement) {
      // @ts-ignore
      const columnWidth = headerElement.clientWidth - 81;
      const maxCharacters = Math.floor(columnWidth / 10) + 3;
      const newDisplayName =
        displayName.length > maxCharacters
          ? displayName.substring(0, maxCharacters) + "..."
          : displayName;
      setDisplayHeaderName(newDisplayName);
    }
  }, [displayName]);

  useEffect(() => {
    updateDisplayHeaderName();
    const column = props.column;
    const onWidthChanged = () => {
      updateDisplayHeaderName();
    };
    column.addEventListener("widthChanged", onWidthChanged);
    return () => {
      column.removeEventListener("widthChanged", onWidthChanged);
    };
  }, [updateDisplayHeaderName, props]);

  return (
    <Menu
      closeOnSelect={false}
      isOpen={!isTablePreviewPage && isOpen && !isProcessing}
      onOpen={onOpen}
      onClose={onMenuClose}
    >
      {props.columnData || props.displayName === "Add Column" ? (
        <MenuButton
          ref={headerRef}
          className={`group peer flex size-full cursor-pointer items-center px-2 text-left !text-black hover:!bg-light`}
          transition="all 0.2s"
          _expanded={{ bg: "#AA6BFA", color: "#FFFFFF" }}
          style={{
            backgroundColor:
              props.columnData?.metaData?.highlightColor || "#fff",
          }}
          disabled={isRefetching || isRemoving}
        >
          <div className="flex w-full items-center justify-between">
            <span className="flex items-center gap-2">
              {props.displayName === "Add Column" ? (
                <Icons.AddIcon2 className="text-[1.1rem]" />
              ) : props.columnData?.metaData?.iconImg ? (
                <img
                  src={props.columnData?.metaData?.iconImg}
                  alt={props.columnData?.metaData?.iconType}
                  className="h-[15px]"
                />
              ) : (
                <HeaderIcon className="h-[15px]" />
              )}
              <span className="!w-full">{displayHeaderName}</span>
            </span>
            <span className="absolute right-0 flex items-center gap-x-3 rounded-full bg-[#FFFFFFCC] pr-2  group-hover:!bg-[transparent]">
              {props.displayName === "Add Column" ? (
                <Icons.ArrowDownIcon className="text-[1.1rem]" />
              ) : null}
              {isProcessing && (
                <Spinner
                  thickness="2px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="purple.500"
                  size="sm"
                />
              )}
              {!isProcessing && HeaderRightIcon && (
                <HeaderRightIcon size={12} />
              )}
            </span>
          </div>
        </MenuButton>
      ) : null}

      {!isTablePreviewPage &&
        (isEnrichedColumn || isTableQueueColumn) &&
        !isProcessing && (
          <Popover isOpen={isPopoverOpen} onClose={popoverOnClose}>
            <PopoverTrigger>
              <div
                className="absolute right-0 h-full cursor-pointer bg-[#FFFFFFCC] px-2 py-3 hover:!bg-light hover:shadow peer-hover:!bg-[transparent]"
                onClick={() => onToggle()}
              >
                <Icons.PlayIcon className="text-[#693DC7]" />
              </div>
            </PopoverTrigger>
            <Portal>
              <PopoverContent ref={popoverRef}>
                <PopoverArrow />
                <PopoverBody className="m-0 flex flex-col justify-center gap-2 p-0">
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={() => handleRunColumn("all", isTableQueueColumn)}
                    isLoading={isPending}
                    className="m-auto w-[94%]"
                    isDisabled={isRefetching}
                  >
                    <span className="flex-1">
                      Rerun <span className="font-bold">all</span>
                    </span>
                  </Button>
                  {!!selectedRowsId.length && (
                    <Button
                      size="sm"
                      variant="outline"
                      onClick={() =>
                        handleRunColumn("selected", isTableQueueColumn)
                      }
                      isLoading={isPending}
                      className="m-auto w-[94%]"
                      isDisabled={isRefetching}
                    >
                      <span className="flex-1">
                        Rerun <span className="font-bold">selected</span> rows
                      </span>
                    </Button>
                  )}

                  {!isTableQueueColumn ? (
                    <Button
                      size="sm"
                      variant="outline"
                      onClick={() =>
                        handleRunColumn("failed", isTableQueueColumn)
                      }
                      isLoading={isPending}
                      className="m-auto w-[94%]"
                      isDisabled={isRefetching}
                    >
                      <span className="flex-1">
                        Rerun{" "}
                        <span className="font-bold text-red-400">
                          Failed/Empty
                        </span>{" "}
                        records
                      </span>
                    </Button>
                  ) : null}
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        )}

      <Portal>
        {props.displayName === "Add Column" ? (
          <MenuList
            width={"max-content"}
            zIndex={9}
            sx={{
              shadow:
                "rgba(76, 76, 76, 0.32) 0px 0px 1px, rgba(76, 76, 76, 0.06) 0px 4px 8px, rgb(238, 238, 238) 0px 8px 48px",
              py: "0",
              p: "0.25rem",
              fontSize: "13.2px",
              fontFamily: "Inter",
              fontWeight: "500",
            }}
          >
            {addColumnOptions.map((option, index) => (
              <MenuItem
                key={`add_column_${index}`}
                sx={{
                  borderRadius: "0rem",
                  px: "0.3rem",
                  _hover: {
                    borderRadius: "5px",
                  },
                }}
                onClick={() => {
                  option.onClick();
                  onClose();
                }}
              >
                <div className="flex items-center text-[0.95rem] font-medium">
                  {/* <addTabelTextIcon.icon className="h-auto w-4 text-[#000000]" />{" "} */}
                  <span className="w-[30px]">{option.icon}</span>
                  <span>{option.name}</span>
                </div>
              </MenuItem>
            ))}
          </MenuList>
        ) : (
          <MenuList
            sx={{
              shadow:
                "rgba(76, 76, 76, 0.32) 0px 0px 1px, rgba(76, 76, 76, 0.06) 0px 4px 8px, rgb(238, 238, 238) 0px 8px 48px",
              width: "max-content",
            }}
            zIndex={9}
          >
            <Box px="3" role="menuitem" py="3">
              <InputGroup>
                <Input
                  autoFocus
                  placeholder={"Enter value"}
                  size="sm"
                  value={columnName}
                  onChange={(e) => {
                    const value = e.target.value;

                    setColumnName(value);
                    if (value === props.displayName) return;

                    const isExists = allColumns.find(
                      (column) => column.name == value,
                    );
                    if (isExists) {
                      toast({
                        title: "Column name already exists",
                        description: "Please choose a different name",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                      });
                      return;
                    }
                  }}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (columnName === existingColumnName) return;

                    if (e.key === "Enter") {
                      updateColumnName(columnName);
                    }
                  }}
                  disabled={isPending}
                  className="size-full rounded-sm border-primary p-1.5 text-[#1D2026] ring-1 ring-primary focus:outline-none focus:ring-2 focus:ring-offset-2"
                />
                {isPending && (
                  <InputRightElement className="!top-[-4px]">
                    <Spinner color="purple" size="sm" />
                  </InputRightElement>
                )}
              </InputGroup>
            </Box>

            {headerOptions.map((option, index) => {
              const isSelected =
                queryParams?.sortingKey === props?.columnData?._id &&
                option.params?.includes(queryParams?.sortOrder);

              if (option.isHidden) return null;

              if (
                option.params?.includes("selectedRows") &&
                !selectedRowsId.length
              )
                return null;

              if (option.type === "divider") {
                return (
                  <MenuDivider
                    key={`column_action_${index}`}
                    className="!m-0"
                  />
                );
              }
              return (
                <MenuItem
                  key={`column_action_${index}`}
                  className={`gap-2 p-2 ${
                    option.isDanger ? "!text-red-500" : ""
                  } ${isSelected ? "!bg-slate-300" : ""}`}
                  onClick={() => {
                    option?.onClick?.((type) => {
                      if (type === "DELETE") {
                        handleDeleteColumn();
                      } else if (type === "DUPLICATE_ROWS") {
                        handleDuplicateRows();
                      } else if (type === "ASC" || type === "DESC") {
                        handleSort(type);
                      } else if (type === "EDIT") {
                        handleEdit();
                      } else if (type === "FILTER") {
                        handleFilter();
                      } else if (type === "PIN") {
                        handlePinColumn();
                      }
                    });
                  }}
                >
                  <>{option.icon}</>
                  {props.columnData?.metaData?.pinned === "left" &&
                  option.name === "Pin" ? (
                    <span>Unpin</span>
                  ) : (
                    <span>{option.name}</span>
                  )}
                </MenuItem>
              );
            })}
          </MenuList>
        )}
      </Portal>
    </Menu>
  );
};

const WrappedCustomHeader = (props: ICustomHeaderParams) => (
  <CustomErrorBoundary>
    <CustomHeader {...props} />
  </CustomErrorBoundary>
);

export default WrappedCustomHeader;
