import { useEffect, useState } from "react";
import {
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

import LeftSection from "./LeftSection";
import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";

import { useModalStore } from "@/stores/modal.store";
import { useProcessingStore } from "@/stores/processing.store";
import { TSelectedColumnOption } from "@/types/common.types";

const DraftEmail = () => {
  const [mailDetails, setMailDetails] = useState({
    subject: "",
    body: "",
  });
  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);
  const isOpenModal = useModalStore((state) => state.isOpenModal);
  const modalData = useModalStore((state) => state.modalData);
  const processesData = useProcessingStore((state) => state.processingData);
  const updateState = useModalStore((state) => state.updateState);

  const handleModalClose = () => {
    updateState({
      isOpenModal: false,
      modalData: { modalType: "", metaData: "" },
    });
  };

  const handleDetails = (key: string, value: string) => {
    setMailDetails((prevDetails) => ({
      ...prevDetails,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (processesData?.length) {
      handleModalClose();
    }
  }, [processesData]);

  return (
    <Modal
      isCentered
      onClose={handleModalClose}
      isOpen={isOpenModal && modalData.modalType === "draft-email"}
      motionPreset="scale"
      size={"6xl"}
    >
      <ModalOverlay />
      <ModalContent className="rounded-[20px]">
        <ModalCloseButton />
        <ModalHeader className="text-center">Draft Emails</ModalHeader>
        <Divider />
        <ModalBody className="!px-0 !pt-0">
          <div className="flex h-[500px]">
            <LeftSection
              mailDetails={mailDetails}
              handleDetails={handleDetails}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
            {/* <Divider orientation="vertical" />
            <RightSection mailDetails={mailDetails} /> */}
          </div>
          <Divider />
        </ModalBody>
        <ModalFooter className="!flex !items-center !justify-end">
          <SaveAndRunEnrichment
            showDivider={false}
            isDisabled={
              !mailDetails.body ||
              !mailDetails.subject ||
              !selectedOption?.keyId
            }
            apiPayload={{
              prompts: `${mailDetails.body}`,
              columnId: selectedOption?.keyId,
              emailData: {
                subject: mailDetails.subject,
                sendTo: selectedOption?.keyId,
              },
            }}
            onClear={handleModalClose}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DraftEmail;
