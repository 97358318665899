import {
  Button,
  Divider,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useEffect } from "react";

import Icons from "@/components/Icons";

import { useTableStore } from "@/stores/table.store";

const TableInfo = () => {
  const toast = useToast();
  const { onOpen, onClose, isOpen } = useDisclosure();

  const tableMetaData = useTableStore((state) => state.tableData.metaData);

  const webhookURL = tableMetaData?.webhookURLPath
    ? `${import.meta.env.VITE_BE_BASE_URL}/${tableMetaData.webhookURLPath}`
    : "";
  const shareableLink = !tableMetaData?.shareableLink
    ? ""
    : `${window.location.origin}/table-preview?token=${tableMetaData?.shareableLink}`;
  const shareableLinkExpiredAt = tableMetaData?.shareableLinkExpiredAt || "";
  const isExpired = !shareableLinkExpiredAt
    ? true
    : dayjs().isAfter(dayjs(shareableLinkExpiredAt));

  const handleCopyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
    toast({
      position: "top-right",
      title: "Copied to clipboard",
      status: "success",
      duration: 1000,
      isClosable: true,
    });
  };

  useEffect(() => {
    if (tableMetaData?.isWebhook) {
      onOpen();
    }
  }, [tableMetaData?.isWebhook]);

  if (!tableMetaData || (!webhookURL && !shareableLink)) return null;

  if (!webhookURL) return null;

  return (
    <Popover
      placement="bottom-start"
      closeOnBlur
      onOpen={onOpen}
      onClose={onClose}
      isOpen={isOpen}
    >
      <PopoverTrigger>
        <Button className="btn-primary-outline !h-[35px] hover:!shadow-md">
          <Icons.DropdownIcon size={20} className="cursor-pointer " />
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent className="!w-[400px] !py-3">
          <PopoverArrow />
          <PopoverBody>
            {!!webhookURL && (
              <div className="space-y-1 font-title">
                <p className="text-left font-semibold text-[#000000]">
                  Webhook URL
                </p>

                <p className="text-sm">
                  You can send data to this source by including key-value pairs
                  in the body of a <strong>POST</strong> request sent to this
                  URL.
                </p>
                <Tooltip
                  label={webhookURL}
                  placement="right"
                  hasArrow
                  arrowSize={15}
                >
                  <div className="w-full rounded-md bg-[#343841] p-2 text-white">
                    <p className="w-370px truncate-lines-2 max-w-[370px] text-left text-sm">
                      {webhookURL}
                    </p>
                  </div>
                </Tooltip>
                <div className="!mt-1 flex justify-end">
                  <button
                    onClick={() => {
                      handleCopyToClipboard(webhookURL);
                    }}
                    className="flex size-fit items-center justify-center space-x-0.5 rounded-md bg-[#D9DCE2] px-2.5 py-1 text-xs text-[#000]"
                  >
                    <Icons.CopyIcon className="" /> <span>copy</span>
                  </button>
                </div>
              </div>
            )}
            {!!webhookURL && !!shareableLink && <Divider className="my-3" />}
            {!!shareableLink && !isExpired && (
              <div className="space-y-1 font-title">
                <p className="text-left font-semibold text-[#000000]">
                  Table Shareable Link
                </p>

                <p className="text-sm">
                  You can use this link to share your table with others.
                </p>
                <Tooltip label={shareableLink} placement="top">
                  <div className="w-full rounded-md bg-[#343841] p-2 text-white">
                    <p className="w-370px truncate-lines-2 max-w-[370px] text-left text-sm">
                      {shareableLink}
                    </p>
                  </div>
                </Tooltip>
                <div className="!mt-1 flex justify-end">
                  <button
                    onClick={() => {
                      handleCopyToClipboard(shareableLink);
                    }}
                    className="flex size-fit items-center justify-center space-x-0.5 rounded-md bg-[#D9DCE2] px-2.5 py-1 text-xs text-[#000]"
                  >
                    <Icons.CopyIcon className="" /> <span>copy</span>
                  </button>
                </div>
              </div>
            )}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default TableInfo;
