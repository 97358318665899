import { useRef } from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";

import { useSearchLeadsStore } from "@/stores/searchleads.store";
import FiltersSection from "./FiltersSection";
import Header from "./Header";
import PeopleSearchResultTable from "./PeopleSearchResultTable";
import Sidebar from "./Sidebar";
import ErrorModal from "./ErrorModal";

const PeopleSearch = () => {
  const uniqueSocketIdRef = useRef(`search-${uuidv4()}`);
  const isShowErrorModal = useSearchLeadsStore(
    (state) => state.isShowErrorModal,
  );

  const updateState = useSearchLeadsStore((state) => state.updateState);

  const handleClose = () => {
    updateState({
      isShowErrorModal: false,
    });
  };

  return (
    <>
      <div>
        <Header />
        <Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(1, 25% 1fr)"
          overflowY={"auto"}
          height={"calc(100vh - 60px)"}
        >
          <GridItem className="border border-[#F0EFF1] bg-[#F5F3FF]">
            <Sidebar />
          </GridItem>
          <GridItem overflow="auto" className="relative flex flex-col">
            <FiltersSection />

            {/* Table */}
            <div className="mt-[10px] h-full min-h-[550px] px-[32px]">
              <PeopleSearchResultTable
                uniqueSocketId={uniqueSocketIdRef.current}
              />
            </div>
          </GridItem>
        </Grid>
      </div>
      <ErrorModal
        isShowErrorModal={!!isShowErrorModal}
        handleClose={handleClose}
      />
    </>
  );
};

export default PeopleSearch;
