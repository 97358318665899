import { useEffect, useState } from "react";
import { useTable } from "@/components/DataTable/Table/hooks/useTable";
import useSocket from "@/lib/socket";
import { ISocketActiveJobsData } from "@/types/socket.types";

export const useJobSync = () => {
  const { refresh, flashSelectedRows } = useTable();
  const [syncStatus, setStatus] = useState<"loading" | "finished">("finished");
  const { subscribe, connect, disconnect } = useSocket();
  useEffect(() => {
    connect();
    subscribe("activeJobs-processing", async (data: ISocketActiveJobsData) => {
      if (data.isRefetch) {
        flashSelectedRows(2000);
        setStatus("finished");
        refresh();
      } else if (data.status === "started") {
        setStatus("loading");
      }
    });
    return () => {
      disconnect();
    };
  }, []);
  return syncStatus;
};
