import type { Field } from "react-querybuilder";
import { toFullOption } from "react-querybuilder";
import { useMemo } from "react";
import { useTableStore } from "@/stores/table.store";

export const useFields = () => {
  const columns = useTableStore((state) => state.tableData.columns);
  //   useEffect(() => {
  //     if (!columns) return;
  //     debugger;
  //   }, [columns]);
  return useMemo(
    () =>
      [
        ...columns.map(
          (col) =>
            ({
              name: col._id,
              label: col.name,
              datatype: "column",
              valueSources: ["value", "field"],
            }) as Field,
        ),
      ].map((o) => toFullOption(o)),
    [],
  );
};
