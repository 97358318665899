import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { useWorkspaceTableList } from "@/hooks/useTableList";
import tableService from "@/services/table.service";
import { useTableState } from "./useTableState.store";
import {
  TableListItem,
  TFolderRow,
  TFoldersListItem,
  TRow,
  TRowId,
} from "../types";
import { useToast } from "@chakra-ui/react";
import Swal from "sweetalert2";
import { getAvailableName, getNewFolder } from "../utils";
import {
  NEW_FOLDER_ID_PLACEHOLDER,
  NEW_FOLDER_NAME_PLACEHOLDER,
  ROOT_FOLDER_ID,
} from "../constants";
import { RowDataUpdatedEvent } from "ag-grid-community";
import { useTableStore } from "@/stores/table.store";

export const useFolderList = () => {
  const workspaceId = useTableStore((state) => state.selectedWorkSpace?._id);

  return useQuery({
    queryKey: ["folderList", workspaceId],
    queryFn: async () => {
      return await tableService.getFolderList();
    },
  });
};

export const useMoveFolders = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const folderList = useTableState((state) => state.folderList);
  const tableList = useTableState((state) => state.tableList);
  const updateState = useTableState((state) => state.updateState);

  return useMutation({
    mutationFn: async (params: {
      sourceType: string;
      sourceId: TRowId;
      targetId: TRowId;
    }) => {
      if (params.sourceType === "folder") {
        updateState({
          folderList: folderList?.map((item) => {
            if (item._id === params.sourceId) {
              // item.parentFolderId = params.targetId;
              return { ...item, parentFolderId: params.targetId };
            }
            return item;
          }),
        });
      } else if (params.sourceType === "table") {
        updateState({
          tableList: tableList?.map((item) => {
            if (item._id === params.sourceId) {
              return { ...item, folderId: params.targetId };
            }
            return item;
          }),
        });
      }
      try {
        await tableService.moveFolderItems(params);
        queryClient.invalidateQueries({
          queryKey: [params.sourceType === "table" ? "tables" : "folderList"],
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        toast({
          title: "Failed to move folder",
          description: error?.message || error?.error?.message,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        updateState({ tableList, folderList });
      }
    },
  });
};

export const useSyncTableState = () => {
  const { data: tableListAtServer = null, isLoading: isTableListLoading } =
    useWorkspaceTableList();
  const { data: folderListAtServer = null, isLoading: isFolderListLoading } =
    useFolderList();

  const updateState = useTableState((state) => state.updateState);
  useEffect(() => {
    if (!(isTableListLoading && isTableListLoading)) {
      updateState({
        tableList: (tableListAtServer ?? []) as TableListItem[],
        folderList: (folderListAtServer?.data ?? []) as TFoldersListItem[],
      });
    }
  }, [
    isTableListLoading,
    isFolderListLoading,
    tableListAtServer,
    folderListAtServer,
    updateState,
  ]);
};

export const useDeleteFolder = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (folderId: TRowId) => {
      try {
        if (folderId === NEW_FOLDER_ID_PLACEHOLDER) return;
        const { isConfirmed, value } = await Swal.fire({
          title: "Confirm Deletion",
          text: "You won't be able to revert this!",
          showCancelButton: true,
          animation: false,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#AA6BFA",
          confirmButtonText: "Confirm",
          showLoaderOnConfirm: true,
          cancelButtonText: "Cancel",
          input: "checkbox",
          inputPlaceholder: "Delete child folders and table",
          inputAutoFocus: true,
        });
        if (!isConfirmed) {
          throw new Error("Cancelled");
        }
        const result = tableService.deleteFolder(folderId, value);
        toast.promise(result, {
          loading: {
            title: "Archiving folder..",
            isClosable: true,
            position: "top-right",
          },
          success: (res) => ({
            title: "Folder archived successfully",
            isClosable: true,
            description: res.data.message,
            position: "top-right",
          }),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          error: (error: any) => ({
            title: "Failed to delete folder",
            description: error?.message || error?.error?.message,
            isClosable: true,
            position: "top-right",
          }),
        });
        await result;
        queryClient.invalidateQueries({ queryKey: ["folderList"] });
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  });
};

export const useRenameFolder = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const folderList = useTableState((state) => state.folderList);
  const updateState = useTableState((state) => state.updateState);
  const createFolder = useCreateFolder();
  return useMutation({
    mutationFn: async (params: { folderId: string; newName: string }) => {
      updateState({
        folderList: folderList?.map((item) => {
          if (item._id === params.folderId) {
            return { ...item, name: params.newName };
          }
          return item;
        }),
      });
      if (params.folderId === NEW_FOLDER_ID_PLACEHOLDER) {
        return createFolder.mutateAsync({
          folderName: params.newName,
        });
      }
      try {
        const promise = tableService.renameFolder(
          params.folderId,
          params.newName,
        );
        toast.promise(promise, {
          loading: {
            title: "Renaming folder..",
            isClosable: true,
            position: "top-right",
          },
          success: {
            title: "Folder renamed successfully",
            isClosable: true,
            position: "top-right",
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          error: (error: any) => ({
            title: "Failed to rename folder",
            description: error?.message || error?.error?.message,
            isClosable: true,
            position: "top-right",
          }),
        });
        await promise;
        queryClient.invalidateQueries({ queryKey: ["folderList"] });
      } catch (error) {
        console.log(error);
        updateState({
          folderList,
        });
        throw error;
      }
    },
  });
};

export const useRenameTable = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const tableList = useTableState((state) => state.tableList);
  const updateState = useTableState((state) => state.updateState);
  return useMutation({
    mutationFn: async (params: { tableId: string; newName: string }) => {
      updateState({
        tableList: tableList?.map((item) => {
          if (item._id === params.tableId) {
            return { ...item, name: params.newName };
          }
          return item;
        }),
      });
      try {
        const promise = tableService.updateTableDataV2(params.tableId, {
          name: params.newName,
        });
        toast.promise(promise, {
          loading: {
            title: "Renaming table..",
            isClosable: true,
            position: "top-right",
          },
          success: {
            title: "Table renamed successfully",
            isClosable: true,
            position: "top-right",
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          error: (error: any) => ({
            title: "Failed to rename table",
            description: error?.message || error?.error?.message,

            isClosable: true,
            position: "top-right",
          }),
        });
        await promise;
        queryClient.invalidateQueries({ queryKey: ["tables"] });
      } catch (error) {
        console.log(error);
        updateState({
          tableList,
        });
        throw error;
      }
    },
  });
};

export const useDeleteTable = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const tableList = useTableState((state) => state.tableList);
  const updateState = useTableState((state) => state.updateState);
  return useMutation({
    mutationFn: async (tableId: TRowId) => {
      try {
        await Swal.fire({
          title: "Confirm Deletion",
          text: "You won't be able to revert this!",
          animation: false,
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#AA6BFA",
          confirmButtonText: "Confirm",
          showLoaderOnConfirm: true,
          cancelButtonText: "Cancel",
        }).then((result) => {
          if (!result.isConfirmed) {
            throw new Error("Cancelled");
          }
        });

        updateState({
          tableList: tableList?.filter((item) => item._id !== tableId),
        });

        const result = tableService.deleteTable(tableId);
        toast.promise(result, {
          loading: {
            title: "Archiving table..",
            isClosable: true,
            position: "top-right",
          },
          success: {
            title: "Table archived successfully",
            isClosable: true,
            position: "top-right",
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          error: (error: any) => ({
            title: "Failed to archive table",
            description: error?.message || error?.error?.message,
            isClosable: true,
            position: "top-right",
          }),
        });
        await result;
        queryClient.invalidateQueries({ queryKey: ["tables"] });
      } catch (error) {
        console.log(error);
        updateState({
          tableList,
        });
        throw error;
      }
    },
  });
};

export const useCreateEmptyFolder = () => {
  const gridApi = useTableState((state) => state.gridApi);
  const folderList = useTableState((state) => state.folderList);
  const folderMap = useTableState((state) => state.folderMap);
  const updateState = useTableState((state) => state.updateState);
  return useMemo(
    () => ({
      create(parentFolder: TFolderRow | null) {
        let tried = 30;
        const newFolderName = getAvailableName(
          NEW_FOLDER_NAME_PLACEHOLDER,
          folderMap
            ?.get(parentFolder?._id ?? ROOT_FOLDER_ID)
            ?.folderItems.map((item) => item.name) ?? [],
        );
        const newFolder = getNewFolder(newFolderName, parentFolder);
        updateState({
          folderList: [...(folderList ?? []), newFolder],
        });
        const renameNewFolder = (e: RowDataUpdatedEvent<TRow>) => {
          const folderClosedStatus =
            useTableState.getState().folderClosedStatus;
          if (tried < 0) {
            e.api.removeEventListener("rowDataUpdated", renameNewFolder);
            e.api.removeEventListener("filterChanged", renameNewFolder);
            return;
          }
          tried--;
          const newFolderNode = e.api.getRowNode(newFolder._id);
          const newClosedStatus = new Map();
          const newFolderPath = newFolderNode?.data?.path ?? [];
          newFolderPath.forEach((item) => {
            if (folderClosedStatus.get(item) !== false)
              newClosedStatus.set(item, false);
          });
          if (newClosedStatus.size)
            updateState({
              folderClosedStatus: new Map([
                ...folderClosedStatus,
                ...newClosedStatus,
              ]),
            });
          else if (newFolderNode) {
            e.api.removeEventListener("rowDataUpdated", renameNewFolder);
            e.api.removeEventListener("filterChanged", renameNewFolder);
            e.api.setFocusedCell(newFolderNode.rowIndex!, "name");
            e.api.ensureIndexVisible(newFolderNode.rowIndex!);
            updateState({
              editing: newFolderNode.data?._id,
            });
          }
        };
        gridApi?.addEventListener("rowDataUpdated", renameNewFolder);
        gridApi?.addEventListener("filterChanged", renameNewFolder);
      },
    }),
    [gridApi, folderList, updateState],
  );
};

export const useCreateFolder = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation({
    mutationFn: async ({
      folderName,
      parentFolderId,
    }: {
      folderName: string;
      parentFolderId?: TRowId;
    }) => {
      try {
        const promise = tableService.createFolder(folderName, parentFolderId);
        toast.promise(promise, {
          loading: {
            title: "Creating folder..",
            isClosable: true,
            position: "top-right",
          },
          success: {
            title: "Folder created successfully",
            isClosable: true,
            position: "top-right",
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          error: (error: any) => ({
            title: "Failed to create folder",
            description: error?.message || error?.error?.message,
            isClosable: true,
            position: "top-right",
          }),
        });
        await promise;
        queryClient.invalidateQueries({ queryKey: ["folderList"] });
        return promise;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["folderList"] });
    },
  });
};
