import { Spinner } from "@chakra-ui/react";

interface Props {
  processedPercentage: number;
  processedRows: number;
  totalRows: number;
  isQueued?: boolean;
}

const ProcessingBar = ({
  processedPercentage,
  processedRows,
  totalRows,
  isQueued,
}: Props) => {
  if (isQueued) {
    return (
      <div className="relative mb-2 ml-1 mt-2 h-4 w-[200px] overflow-hidden rounded-full bg-slate-300"></div>
    );
  }

  if (!totalRows) {
    return (
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="purple.300"
        size="md"
        className="ml-5"
      />
    );
  }
  return (
    <div className="relative mb-2 ml-1 mt-2 h-4 w-[200px] overflow-hidden rounded-full bg-bgSecondary">
      <div
        className={`animated-gradient d-flex h-4 items-center justify-center rounded-full ${!processedPercentage ? "animated-gradient-empty" : ""}`}
        style={{
          width: `${processedPercentage || 100}%`,
          transition: "width 0.5s ease-in",
        }}
      ></div>
      {!!totalRows && (
        <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[8px] font-extrabold text-navy-900">
          {processedRows} out of {totalRows} rows
        </span>
      )}
    </div>
  );
};

export default ProcessingBar;
