import SelectColumnDropdown from "@/components/Enrichment/Common/SelectColumnDropdown";
import SelectableTextEditor from "@/components/Enrichment/Common/SelectableTextEditor";
import { TSelectedColumnOption } from "@/types/common.types";
import { Divider, Input, Text } from "@chakra-ui/react";

type Props = {
  mailDetails: {
    subject: string;
    body: string;
  };
  selectedOption: TSelectedColumnOption | null;
  setSelectedOption: (val: any) => void;
  handleDetails: (key: string, value: string) => void;
};

const LeftSection = ({
  mailDetails,
  selectedOption,
  handleDetails,
  setSelectedOption,
}: Props) => {
  return (
    <div className="h-full w-[100%]">
      <div className="flex items-center justify-between px-4 py-2">
        <Text className="text-md font-[500]">Email Template</Text>
      </div>
      <Divider />
      <div className="px-4 py-2">
        <div className="mb-4">
          <p className="pb-1 text-sm font-semibold">Send To:</p>
          <SelectColumnDropdown
            selectedOption={selectedOption}
            setSelectedOption={(newValue: any) => setSelectedOption(newValue)}
          />
        </div>

        <Divider className="my-2" />
        <Input
          placeholder="Subject Text, type / for commands"
          size={"sm"}
          variant={"unstyled"}
          className="ml-2"
          value={mailDetails.subject}
          onChange={(e) => handleDetails("subject", e.target.value)}
        />
        <Divider className="mt-2" />
        <SelectableTextEditor
          title=""
          placeholder="Subject Text, type / for commands"
          setText={(value) => {
            //@ts-ignore
            handleDetails("body", value);
          }}
          rows={12}
          text={mailDetails.body}
          position="Right"
        />
      </div>
    </div>
  );
};

export default LeftSection;
