import { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { useJobTracker } from "./useTrack";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ANY = any;
export const useImportModelProps = () => {
  const [query, setQuery] = useState("");
  const [location, setLocation] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleJobTracking, isTrackingJobChanges } = useJobTracker();

  return {
    isOpen,
    isLoading: isTrackingJobChanges,
    selectedFilters: {
      jobTitle: query,
      location,
    } as ANY,
    updateSelectedFilters: (filter: string, val: ANY) => {
      if (filter === "jobTitle") {
        setQuery(val);
      } else {
        setLocation(val);
      }
    },
    setIsOpen: (val: boolean) => {
      setQuery("");
      setLocation("");
      if (!val) {
        onClose();
      } else {
        onOpen();
      }
    },
    handleTrack: () => {
      handleJobTracking({
        query,
        location,
      }).then(() => {
        onClose();
      });
    },
  };
};
