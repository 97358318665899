import { Box, Text } from "@chakra-ui/react";

type Props = {
  label: string;
  isActive: boolean;
  isCompleted: boolean;
};
const TabHeader = ({ label, isActive, isCompleted }: Props) => {
  return (
    <Box
      className={`flex gap-2 items-center justify-center text-sm font-medium border-b-2 pb-2 transition-all duration-600 ${
        isActive
          ? isCompleted
            ? "text-[#098849]"
            : "text-[#2c2c2c]"
          : "text-[#AFAFAF]"
      }`}
      borderColor={isActive ? (isCompleted ? "#098849" : "#2c2c2c") : "#AFAFAF"}
    >
      <Text
        maxWidth="188px"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {label}
      </Text>
      {isCompleted && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
        >
          <path
            opacity="0.3"
            d="M10.8333 18.3333C15.4357 18.3333 19.1667 14.6023 19.1667 9.99996C19.1667 5.39759 15.4357 1.66663 10.8333 1.66663C6.23095 1.66663 2.49999 5.39759 2.49999 9.99996C2.49999 14.6023 6.23095 18.3333 10.8333 18.3333Z"
            fill="#098849"
          ></path>
          <path
            d="M14.8074 6.51474C15.1215 6.17828 15.6488 6.1601 15.9853 6.47412C16.3217 6.78815 16.3399 7.31548 16.0259 7.65193L10.1925 13.9019C9.88787 14.2284 9.38003 14.2566 9.041 13.9661L6.12434 11.4661C5.7749 11.1665 5.73443 10.6404 6.03395 10.291C6.33347 9.94157 6.85955 9.9011 7.20899 10.2006L9.51916 12.1808L14.8074 6.51474Z"
            fill="#098849"
          ></path>
        </svg>
      )}
    </Box>
  );
};

export default TabHeader;
