import { useMutation } from "@tanstack/react-query";

import leadService from "@/services/leads.service";
import { useSearchLeadsStore } from "@/stores/searchleads.store";
import { SearchPeopleType } from "@/types/leads.types";

export const usePeopleSearch = () =>
  useMutation({
    mutationFn: ({ payload }: { payload: SearchPeopleType }) =>
      leadService.searchPeople(payload, "leads/search-people-2"),
    onSuccess: (response, { payload }) => {
      useSearchLeadsStore.getState().updateState({
        currentSearchPayload: payload,
        searchLeadsResult: {
          profiles: response?.data.profiles || [],
          pagination: response?.data.pagination || {
            total_entries: 0,
            total_pages: 0,
            page: 1,
            per_page: 100,
          },
          cacheKey: response?.data.cacheKey || "",
          apiPath: "leads/search-people-2?per_page=100&want_to_import=true",
        },
        selectionAction: "clear-all",
        selectionCount: 0,
        selectedLeads: [],
        isSearching: false,
      });
    },
    onError: (error) => {
      console.log(error, "error");
      useSearchLeadsStore.getState().updateState({
        isSearching: false,
        isShowErrorModal: error?.message?.includes("Upgrade"),
      });
    },
  });
