import { PresetEnrichmentType } from "@/types/presets.types";

export const modalTypeTitleMapping: Record<PresetEnrichmentType, string> = {
  "use-ai": "Use AI (ChatGPT) Presets",
  "ask-question": "Use AI + Ask a Question Presets",
  "sales-emails": "Generate Sales Emails Presets",
  "google-search": "Google search Presets",
  "http-api": "HTTP API Presets",
  "persana-agent": "Persana Agent Presets",
};

export const filterButtons = [
  {
    name: "Persana Presets",
    value: "persanaTemplates",
  },
  {
    name: "My Presets",
    value: "myTemplates",
  },
];
