import { Button } from "@chakra-ui/react";
import Icons from "../Icons";
import { useNavigate } from "@tanstack/react-router";
import { allTemplates } from "../Leads/UseTemplateModal/constants";

const TemplateDetails = ({ templateId }: { templateId: string }) => {
  const navigate = useNavigate();
  const templateData = allTemplates.find(
    (item) => item.heading === decodeURIComponent(templateId),
  );

  return (
    <div>
      <div className="py-[8px] px-[32px] border border-[#F0EFF1] flex items-center justify-between bg-white">
        <p className="text-[24px] font-bold leading-[32px]">Playbook</p>
        <Button
          colorScheme="primary"
          variant="outline"
          leftIcon={<Icons.BackIcon />}
          className="!bg-light !text-textPurple !border-none h-[40px] rounded-md"
          onClick={() => {
            navigate({ to: "/playbooks" });
          }}
        >
          Go Back
        </Button>
      </div>
      {templateData && (
        <div className="flex gap-4 my-6 px-[32px] py-[32px]">
          <div
            className="w-[55%] border border-[#E5E7EB] rounded-md p-2 cursor-pointer"
            onClick={() => {
              window.open(templateData?.url, "_blank");
            }}
          >
            <img src={templateData?.image} alt="" />
          </div>
          <div className="w-[45%] flex flex-col gap-4 px-[60px] pt-8">
            <p className="font-bold text-[28px] leading-[40px] text-[#0F0D1C] text-left">
              {templateData?.heading}
            </p>
            <p className="text-[#4A5264] text-[18px]">{templateData?.title}</p>
            <Button
              w={"fit-content"}
              h={"38px"}
              fontSize={14}
              className="!bg-primary-gradient hover:opacity-80"
              colorScheme="primary"
              borderRadius={"6px"}
              onClick={() => {
                window.open(templateData?.url, "_blank");
              }}
            >
              Try Now
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TemplateDetails;
