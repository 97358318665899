import MakeAPICall from "@/lib/apiCalls";

import { Option } from "@/components/Common/MultiSelectDropdown/types";
import type { Actors } from "@/types/apify.types";
import type { ProviderListResponse } from "@/types/providers.types";

class ProviderService {
  constructor(private apiCaller = MakeAPICall) {}

  async getProviderList() {
    const apiCall = new this.apiCaller({
      apiPath: "providers/list",
    });
    const response = await apiCall.get<ProviderListResponse["data"]>();
    return response;
  }

  async deleteProvider(payload: { providerId: string }) {
    const apiCall = new this.apiCaller({
      apiPath: `providers/delete-provider/${payload.providerId}`,
      payload,
    });
    const response = await apiCall.delete();
    return response;
  }
  async setDefaultKeys(payload: {
    providerId: string;
    preferKey: boolean;
    alwaysPreferKey: boolean;
  }) {
    const apiCall = new this.apiCaller({
      apiPath: `providers/set-default-key`,
      payload,
    });
    const response = await apiCall.put();
    return response;
  }
  async saveKey(payload: {
    apiKey: string;
    providerId?: string;
    providerName: string;
    preferKey?: boolean; // choose this key over persana key
    alwaysPreferKey?: boolean;
  }) {
    const apiCall = new this.apiCaller({
      apiPath: "providers/save-api-key",
      payload,
    });
    const response = await apiCall.post();
    return response;
  }

  async getSmarLeadsCampaigns() {
    const apiCall = new this.apiCaller({
      apiPath: "providers/smartleads-campaigns",
    });
    const response = await apiCall.get();
    return response;
  }

  async getLgmAudiences(): Promise<Option[]> {
    const apiCall = new this.apiCaller({
      apiPath: "providers/lgm-audiences",
    });
    const response = await apiCall.get();
    return response.data || [];
  }

  openGithubOAuth() {
    const clientId = import.meta.env.VITE_GITHUB_CLIENT_ID;
    const URL = `https://github.com/login/oauth/authorize?client_id=${clientId}&scope=user:email`;
    window.open(URL, "_blank");
  }

  async githubOAuth(code: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/oauth/github`,
      params: {
        code,
      },
    });
    const response = await apiCall.get();
    return response;
  }

  openGmailOAuth() {
    const clientId = import.meta.env.VITE_GMAIL_CLIENT_ID;
    const redirectUri = `${window.location.origin}/oauth/gmail/`;
    const scopes = [
      "https://mail.google.com/",
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
    ];

    const URL = `https://accounts.google.com/o/oauth2/auth?access_type=offline&client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scopes.join(
      "%20",
    )}&prompt=consent`;

    window.open(URL, "_blank");
  }

  async gmailOAuth(code: string) {
    const apiCall = new this.apiCaller({
      apiPath: "providers/gmail-oauth",
      payload: { code },
    });
    const response = await apiCall.post();
    return response;
  }

  async getApifyActors() {
    const apiCall = new this.apiCaller({
      apiPath: "providers/apify/actors",
    });
    const response = await apiCall.get<Actors[]>();
    return response;
  }

  async hubSpotOAuth(code: string) {
    const apiCall = new this.apiCaller({
      apiPath: "providers/hubspot/oauth",
      payload: { code },
    });
    const response = await apiCall.post();
    return response;
  }

  openHubSpotOAuth() {
    const clientId = import.meta.env.VITE_HUBSPOT_CLIENT_ID;
    const redirectUri = import.meta.env.VITE_HUBSPOT_REDIRECT_URI;
    const scopes = [
      "oauth",
      "crm.objects.companies.write",
      "crm.objects.companies.read",
      "crm.objects.contacts.read",
      "crm.objects.contacts.write",
    ];
    const URL = `https://app.hubspot.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join("%20")}`;

    window.open(URL, "_blank");
  }

  openSalesforceOAuth() {
    const clientId = import.meta.env.VITE_SALESFORCE_CLIENT_ID;
    const redirectUri = import.meta.env.VITE_SALESFORCE_REDIRECT_URI;
    const authUrl = "https://login.salesforce.com/services/oauth2/authorize";

    const URL = `${authUrl}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=full offline_access`;

    window.open(URL, "_blank");
  }

  async salesforceOAuth(code: string) {
    const apiCall = new this.apiCaller({
      apiPath: "providers/salesforce/oauth",
      payload: { code },
    });
    const response = await apiCall.post();
    return response;
  }

  openOutreachOAuth() {
    const clientId = import.meta.env.VITE_OUTREACH_CLIENT_ID;
    const redirectUri = import.meta.env.VITE_OUTREACH_REDIRECT_URI;
    const authUrl = "https://api.outreach.io/oauth/authorize";

    const URL = `${authUrl}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=accounts.read`;

    window.open(URL, "_blank");
  }

  async outreachOAuth(code: string) {
    const apiCall = new this.apiCaller({
      apiPath: "providers/outreach/oauth",
      payload: { code },
    });
    const response = await apiCall.post();
    return response;
  }

  openSalesoftOAuth() {
    const clientId = import.meta.env.VITE_SALESOFT_CLIENT_ID;
    const redirectUri = import.meta.env.VITE_SALESOFT_REDIRECT_URI;
    const authUrl = "https://accounts.salesloft.com/oauth/authorize";

    const URL = `${authUrl}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;

    window.open(URL, "_blank");
  }

  async salesoftOAuth(code: string) {
    const apiCall = new this.apiCaller({
      apiPath: "providers/salesoft/oauth",
      payload: { code },
    });
    const response = await apiCall.post();
    return response;
  }

  async getInstantlyCampaigns() {
    const apiCall = new this.apiCaller({
      apiPath: "providers/instantly-campaigns",
    });
    const response = await apiCall.get();
    return response;
  }

  async getSendsparkCampaigns(workspaceId: string) {
    const apiCall = new this.apiCaller({
      apiPath: "providers/sendspark-campaigns",
      payload: workspaceId,
    });
    const response = await apiCall.post();
    return response;
  }

  async getSendsparkWorkspaces() {
    const apiCall = new this.apiCaller({
      apiPath: "providers/sendspark-workspaces",
    });
    const response = await apiCall.get();
    return response;
  }

  async getHeyReachCampaigns() {
    const apiCall = new this.apiCaller({
      apiPath: "providers/heyReach-campaigns",
    });
    const response = await apiCall.get();
    return response;
  }

  async getHeyReachLinkedinAccounts() {
    const apiCall = new this.apiCaller({
      apiPath: "providers/heyReach-linkedin-accounts",
    });
    const response = await apiCall.get();
    return response;
  }

  async getLemListCampaigns() {
    const apiCall = new this.apiCaller({
      apiPath: "providers/lemList-campaigns",
    });
    const response = await apiCall.get();
    return response;
  }

  openSlackOAuth() {
    const clientId = import.meta.env.VITE_SLACK_CLIENT_ID;
    const redirectUri = import.meta.env.VITE_SLACK_REDIRECT_URL;
    const scopes =
      "chat:write,channels:read,channels:join,groups:read,im:read,mpim:read";

    const URL = `https://slack.com/oauth/v2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes}`;

    window.open(URL, "_blank");
  }

  async slackOAuth(code: string) {
    const apiCall = new this.apiCaller({
      apiPath: "providers/slack-oauth",
      payload: { code },
    });
    const response = await apiCall.post();
    return response;
  }

  async getSlackChannelList(){
     const apiCall = new this.apiCaller({
      apiPath: "providers/slack-channels",
    });
    const response = await apiCall.get();
    return response;
  }

    async sendSlackNotification(channelId:string, text:string){
     const apiCall = new this.apiCaller({
      apiPath: "providers/send-slack-message",
      payload: {
        channelId,
        text
      }
    });
    const response = await apiCall.post();
    return response;
  }
}

const providerService = new ProviderService();

export default providerService;
