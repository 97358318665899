import Swal from "sweetalert2";
import { Button, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import SalesoftLogo from "@/assets/images/salesloft-icon.png";
import { useProviderList } from "@/hooks/useProviderData";
import providerService from "@/services/providers.service";
import { userStore } from "@/stores/user.store";

const SalesoftIntegration = () => {
  const toast = useToast();
  const currentUser = userStore((state) => state.currentUser);
  const { data: providerList, refetch } = useProviderList();
  const salesoftProvider = providerList?.data?.find(
    (provider) => provider.name === "salesoft",
  );
  const isSalesoftConnected = salesoftProvider?.metaData;

  const handleIntegration = () => {
    if (currentUser?.planType === "Free") {
      Swal.fire({
        title: "Warning!",
        icon: "warning",
        html:
          "Please contact " +
          '<b><a href="mailto:support@persana.ai">support@persana.ai</a></b> ' +
          "to upgrade your plan",
        focusConfirm: true,
      });
    } else {
      providerService.openSalesoftOAuth();
    }
  };

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () =>
      providerService.deleteProvider({
        providerId: salesoftProvider?._id || "",
      }),
    onError: (error) => {
      toast({
        title: error.message || "Something went wrong.",
        status: "error",
        isClosable: true,
      });
    },
    onSuccess: (response) => {
      if (!response.success) {
        toast({
          title: response?.error || "Something went wrong.",
          status: "error",
          isClosable: true,
        });
      } else {
        refetch();
        toast({
          title: response?.data?.message || "Disconnected successfully",
          status: "success",
          isClosable: true,
        });
      }
    },
  });

  return (
    <div className="flex h-[150px] w-full flex-col justify-between rounded-md bg-white p-4 shadow">
      <div className="mb-4 flex items-center gap-4">
        <img src={SalesoftLogo} alt={"Salesoft"} className="w-[2rem]" />
        <h2 className="font-semibold">Salesoft</h2>
      </div>
      <div>
        {/* FIXME: fix Salesloft spelling in entire project  */}
        <p className="mb-4">{`Connect your Salesloft Account`}</p>
        <Button
          colorScheme={isSalesoftConnected ? "red" : "green"}
          variant="outline"
          size="sm"
          isLoading={isPending}
          onClick={() => {
            if (isSalesoftConnected) {
              mutateAsync();
            } else {
              handleIntegration();
            }
          }}
        >
          {isSalesoftConnected ? "Disconnect" : "Connect"}
        </Button>
      </div>
    </div>
  );
};

export default SalesoftIntegration;
