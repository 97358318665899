import StarterChecklist from "@/components/StarterChecklist";

const RightSection = () => {
  return (
    <div className="mt-[16px] flex flex-col gap-4 w-full">
      <StarterChecklist />
    </div>
  );
};

export default RightSection;
