import { ColDef } from "ag-grid-community";
import { ActiveJobsType } from "@/types/accountMonitoring.types";
import { WithLoader } from "@/components/DataTable/Table/columns/WithLoader";
import { HeaderCheckboxSelection } from "@/components/DataTable/Table/columns/HeaderCheckboxSelection";
import { RowCheckboxRenderer } from "@/components/DataTable/Table/columns/Checkbox";
import {
  CompanyNameRender,
  JobBoardsRenderer,
  JobTitleRenderer,
  LinkCopyOpenRenderer,
} from "./CellRenderers";

export const colDefs: ColDef<ActiveJobsType>[] = [
  {
    headerName: "",
    headerClass: "flex size-full place-content-center",
    width: 48,
    sortable: false,
    pinned: "left",
    cellRenderer: RowCheckboxRenderer,
    headerComponent: HeaderCheckboxSelection,
  },
  {
    field: "companyName",
    headerName: "Company",
    cellRenderer: WithLoader(CompanyNameRender),
  },
  // {
  //   field: "status",
  //   headerName: "Status",
  //   cellRenderer: StatusRenderer,
  // },
  {
    field: "title",
    headerName: "Job Title",
    cellRenderer: WithLoader(JobTitleRenderer),
  },
  {
    field: "location",
    headerName: "Job Location(s)",
    cellClass:
      "flex text-[#677084] !text-sm items-center !font-normal truncate",
  },

  {
    field: "jobType",
    headerName: "Work Style(s)",
    cellClass:
      "flex items-center text-[#677084] !text-sm !font-normal truncate",
  },
  {
    field: "jobBoard",
    headerName: "Job Boards",
    cellRenderer: WithLoader(JobBoardsRenderer),
    // cellStyle: { borderRight: "1px solid #e2e8f0" },
  },
  {
    headerName: "Job URL",
    field: "jobPostedUrl",
    cellRenderer: WithLoader(({ value }: { value: string }) => (
      <LinkCopyOpenRenderer link={value} />
    )),
    cellClass:
      "flex items-center  text-[#5024DC] !text-sm !font-normal truncate",
  },
  {
    headerName: "Job Posted",
    field: "jobPosted",
    cellClass:
      "flex items-center  text-[#5024DC] !text-sm !font-normal truncate",
  },
  // {
  //   field: "benefits",
  //   headerName: "Benefits",
  //   // cellStyle: { borderRight: "1px solid #e2e8f0" },
  // },
];
