import { useEffect, useState } from "react";

import CustomButton from "../Common/CustomButton";

import bulbSvg from "@/assets/svgs/bulb.svg";
import closeSvg from "@/assets/svgs/close.svg";
import { useModalStore } from "@/stores/modal.store";
import { userStore } from "@/stores/user.store";

const FreeTrialBanner = () => {
  const updateState = useModalStore((state) => state.updateState);
  const [creditsLeft, setCreditsLeft] = useState<number | null>(0);
  const creditDetails = userStore((state) => state.creditDetails);
  const [showBanner, setShowBanner] = useState<boolean | null>(null);

  useEffect(() => {
    const bannerState = localStorage.getItem("showFreeTrialBanner");
    if (bannerState !== null) {
      setShowBanner(JSON.parse(bannerState));
    } else {
      setShowBanner(true);
    }
  }, []);

  useEffect(() => {
    if (showBanner !== null) {
      localStorage.setItem("showFreeTrialBanner", JSON.stringify(showBanner));
    }
  }, [showBanner]);

  useEffect(() => {
    const creditLeft = Math.max(
      0,
      (creditDetails?.creditLeft || 0) - (creditDetails?.creditUsed || 0),
    );

    setCreditsLeft(creditLeft);
  }, [creditDetails]);

  if (showBanner === null) {
    return null;
  }

  return (
    <>
      {showBanner ? (
        <div className="py-[12px] px-[32px] border border-borderGray flex items-center justify-between sticky top-0 z-10 bg-borderGray">
          <div className="flex items-center gap-[24px] text-textBlack">
            <div className="py-[6px] px-[12px] bg-lilac rounded-[4px]">
              <p className="text-[16px]">
                <span className="font-bold">{creditsLeft}</span> credits left
              </p>
            </div>
            <div className="flex items-center gap-[6px]">
              <img src={bulbSvg} alt="" />
              <p className="text-[16px]">
                {"Upgrade now to unlock all of Persana"}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-[24px]">
            <CustomButton
              variant="primary"
              buttonText="Upgrade"
              onClick={() => {
                updateState({
                  isOpenModal: true,
                  modalData: { modalType: "pricing" },
                });
              }}
            />
            <img
              src={closeSvg}
              alt=""
              width={24}
              height={24}
              className="cursor-pointer"
              onClick={() => setShowBanner(false)}
            />
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-end mt-4 mr-2">
          <CustomButton
            variant="primary"
            buttonText="Upgrade"
            onClick={() => {
              updateState({
                isOpenModal: true,
                modalData: { modalType: "pricing" },
              });
            }}
          />
        </div>
      )}
    </>
  );
};

export default FreeTrialBanner;
