import { TProviderOption } from "@/components/Common/ProviderWaterfall/types";
import leadMagicImg from "@/assets/svgs/leadmagic.svg";
import foraragerImg from "@/assets/svgs/forager.svg";
import nimblerImg from "@/assets/svgs/nimbler.svg";
import datagmaImg from "@/assets/svgs/datagma.svg";
const providerOptionsList: TProviderOption[] = [
  {
    value: "nimbler",
    label: "Nimbler",
    icon: nimblerImg,
    apikeyParams: {
      keyName: "nimbler",
      enablePersanaApiKey: true,
      enableUserApiKey: true,
    },
  },
  {
    value: "forager",
    label: "Forager",
    icon: foraragerImg,
    apikeyParams: {
      keyName: "forager",
      enableUserApiKey: true,
      enablePersanaApiKey: true,
    },
  },
  {
    value: "leadmagic",
    label: "Leadmagic",
    icon: leadMagicImg,
    credits: 3,
    apikeyParams: {
      keyName: "leadMagicKey",
      enableUserApiKey: true,
      enablePersanaApiKey: true,
    },
  },
  {
    value: "Datagma",
    label: "Datagma",
    icon: datagmaImg,
    credits: 15,
    apikeyParams: {
      keyName: "Datagma",
      enableUserApiKey: true,
      enablePersanaApiKey: true,
    },
  },
];
export const enabledProviders = ["Datagma", "leadmagic"];
export const providerOptions = providerOptionsList.filter((provider) =>
  enabledProviders.includes(provider.value),
);
export const defaultProviders = providerOptions.filter((provider) =>
  ["Datagma", "leadmagic"].includes(provider.value),
);
