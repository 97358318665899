import { Box, Link } from "@chakra-ui/react";
import { DotLottiePlayer } from "@dotlottie/react-player";
import sorryEmoji from "@/assets/animated/sorryEmoji.lottie";

export function PayWall() {
  return (
    <div className="mt-20 flex items-center justify-center rounded-[2rem] bg-[rgba(255,_255,_255,_0.4)] backdrop-blur-[7px]">
      <Box
        w={500}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        className="flex flex-col items-center justify-center gap-4 px-10 py-5"
      >
        <div className="w-[150px]">
          <DotLottiePlayer
            src={sorryEmoji}
            autoplay
            loop
            style={{ height: "auto", width: "auto" }}
          />
        </div>
        <p className="font-[400]">
          Currently, this feature is in beta available for paid users only. If
          you&rsquo;d like early access please book a quick{" "}
          <Link
            style={{ textDecoration: "none" }}
            href="https://calendly.com/d/2xg-4vb-t3c/persana-ai-onboarding"
            target="_blank"
          >
            <span className="text-[#0284fe]">onboarding call</span>
          </Link>{" "}
          or email{" "}
          <a className="text-[#0284fe]" href="mailto: sriya@persana.ai">
            sriya@persana.ai
          </a>{" "}
          for access.
        </p>
      </Box>
    </div>
  );
}
