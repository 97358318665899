import { Divider } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";
import { useRef, useState } from "react";

import Icons from "@/components/Icons";
import CustomErrorBoundary from "../Common/ErrorComponent/CustomErrorBoundary";
import SidebarLinks from "./Links";

import PersanaLogo from "@/assets/images/logo.png";
import { getCurrentDateInYYYYMM } from "@/lib/utils";
import userService from "@/services/user.service";
import { useModalStore } from "@/stores/modal.store";
import { userStore } from "@/stores/user.store";
import FreeTrial from "./FreeTrial";

const Sidebar = () => {
  const [isHovering, setIsHovering] = useState(false);
  const [isMouseLeave, setIsMouseLeave] = useState(false);
  const [expandSidebar, setExpandSidebar] = useState(false);

  const navigate = useNavigate();
  const updateState = useModalStore((state) => state.updateState);
  const creditDetails = userStore((state) => state.creditDetails);
  const currentUser = userStore((state) => state.currentUser);

  const handleLogout = async () => {
    localStorage.removeItem("persana-alradyShowedIntro");
    navigate({ to: "/login" });
    await userService.logout();
    window.location.reload();
  };

  const buttons = [
    {
      name: "Upgrade",
      icon: <Icons.CrownIcon className="size-5" />,
      onClick: () =>
        updateState({ isOpenModal: true, modalData: { modalType: "pricing" } }),
    },
    {
      name: "Book Onboarding Call",
      icon: <Icons.PhoneIcon className="size-5" />,
      onClick: () => {
        window.open(
          `https://calendly.com/d/2xg-4vb-t3c/persana-ai-onboarding?month=${getCurrentDateInYYYYMM()}`,
          "_blank",
        );
      },
    },
    {
      name: "Join Community",
      icon: <Icons.SlackIcon className="size-5" />,
      onClick: () => {
        window.open(
          "https://join.slack.com/t/persanaaicommunity/shared_invite/zt-2hkyc3iaf-HQYWmcTIQnT9I6tcou4XEA",
          "_blank",
        );
      },
    },
    {
      name: "Help Center",
      icon: <Icons.HelpIcon className="size-5" />,
      onClick: () => {
        window.open(
          "https://persana.ai/learn-persana/persana-academy",
          "_blank",
        );
      },
    },
    {
      name: "Logout",
      icon: <Icons.LogoutIcon className="size-5" />,
      onClick: handleLogout,
    },
  ];

  const hoverTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    setExpandSidebar(true);
    setIsMouseLeave(false);
    hoverTimeout.current = setTimeout(() => {
      // Store the timeout ID
      setIsHovering(true);
    }, 250);
  };

  const handleMouseLeave = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current); // Cancel the timeout
    }
    setIsMouseLeave(true);
    setIsHovering(false);
    setExpandSidebar(false);
  };

  // const creditLeft = Math.max(creditDetails?.creditLeft || 0 - creditDetails?.creditUsed || 0, 0);

  return (
    <div className="flex h-full">
      <div className="!duration-1000">
        <div
          className={`sidebar fixed !z-[100] flex max-h-full min-h-full flex-col overflow-y-auto !bg-[#F9FAFB] pb-3 shadow-2xl shadow-white/5 transition-all duration-[400ms] dark:text-white ${
            expandSidebar ? "w-[17rem]" : "w-[4rem]"
          }`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <span className="absolute right-4 top-4 block cursor-pointer text-white xl:hidden dark:text-white">
            <Icons.HiXIcon />
          </span>
          <div>
            <div className="flex items-center justify-center pb-4">
              <img
                src={PersanaLogo}
                className="pointer-events-none my-[10px] h-auto w-[30px]"
              />
            </div>
          </div>
          <ul
            className="mb-auto w-full"
            style={{
              scrollbarColor: "transparent transparent",
            }}
          >
            <SidebarLinks isHovering={isHovering} />
          </ul>
          <div
            className={!isMouseLeave && isHovering ? "visible" : "invisible"}
          >
            <Divider className="my-2" />
            <div className="px-5">
              <FreeTrial />
            </div>
          </div>
          <Divider className="my-2" />
          <div className="sidebar w-full">
            <div
              className={`w-full px-5 pb-5 ${!isMouseLeave && isHovering ? "visible" : "invisible"}`}
            >
              <div className="flex items-center justify-between space-x-10 pb-2 font-poppins">
                <div className="flex items-center gap-x-2">
                  <span className="font-medium text-[#475467]">
                    {creditDetails?.planType || "Free Plan"}
                  </span>
                </div>
                <p className="flex items-center gap-x-1 text-[#344054]">
                  <span className="font-medium text-[#AAB2BA]">
                    {creditDetails?.creditUsed || 0}
                  </span>{" "}
                  <span className="font-semibold text-[#344054]">/</span>
                  <span className="font-semibold text-[#344054]">
                    {creditDetails?.creditLeft || 0}
                  </span>
                  {/* <span className="text-[#90969c]"> credits left:</span>
                    <span className="font-semibold">
                      {creditLeft || 0}
                    </span> */}
                </p>
              </div>

              {creditDetails?.creditUsed != null &&
              creditDetails?.creditLeft != null ? (
                <div className="h-3 w-full rounded-full bg-[#E4E9ED]">
                  <div
                    className="h-3 rounded-full bg-[#0284FE]"
                    style={{
                      width: `${
                        (creditDetails?.creditUsed /
                          creditDetails?.creditLeft) *
                        100
                      }%`,
                    }}
                  ></div>
                </div>
              ) : null}
            </div>

            {buttons.map((button, index) => (
              <div
                key={index}
                onClick={button.onClick}
                className="w-full cursor-pointer !bg-[#F9FAFB] py-[5px] transition-all"
              >
                <button className="relative w-full py-1 duration-300 hover:cursor-pointer hover:!bg-[#dce6f15d]">
                  <div className="flex cursor-pointer items-center px-1">
                    <span
                      className={`flex w-12 items-center justify-center text-xs font-medium text-[#000000]/70`}
                    >
                      {button.icon}
                    </span>
                    {isHovering && (
                      <span
                        className={`font-medium leading-none text-[#000]/80`}
                      >
                        {button.name}
                      </span>
                    )}
                  </div>
                </button>
              </div>
            ))}
            <div className="pl-[8px]">
              <button className="mt-3 flex cursor-pointer items-center justify-center gap-x-2 px-1">
                <span className="flex size-9 items-center justify-center rounded-full bg-[#dce6f15d] text-base uppercase text-[#1C1C1C]">{`${
                  currentUser?.profileInfo?.firstName?.[0] || "P"
                }`}</span>{" "}
                {isHovering && (
                  <div className="flex flex-col items-start text-[#1C1C1C] ">
                    <span className="truncate text-sm">
                      {currentUser?.profileInfo?.firstName}{" "}
                      {currentUser?.profileInfo?.lastName}
                    </span>
                    <span className="truncate text-[10px] ">
                      {currentUser?.Email}
                    </span>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const WrappedSidebar = () => (
  <CustomErrorBoundary>
    <Sidebar />
  </CustomErrorBoundary>
);

export default WrappedSidebar;
