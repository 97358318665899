import { useSearchLeadsStore } from "@/stores/searchleads.store";
import CustomSelection from "./CustomSelection";
import PeopleSearchResultTableActions from "./PeopleSearchResultTableActions";

const FiltersSection = () => {
  const currentSearchPayload = useSearchLeadsStore(
    (state) => state.currentSearchPayload,
  );

  return (
    <div className="flex items-center justify-between border border-[#F0EFF1] px-[32px] py-[6px]">
      <div className="flex items-center gap-[16px]">
        <CustomSelection />
      </div>
      <div className="flex items-center gap-[12px]">
        <PeopleSearchResultTableActions
          buttonText={"Import to Table"}
          currentSearchPayload={currentSearchPayload}
          isForFindEmail={false}
          variant="secondary"
        />
        <PeopleSearchResultTableActions
          buttonText={"Export & Find Email"}
          currentSearchPayload={currentSearchPayload}
          isForFindEmail={true}
          variant="primary"
        />
      </div>
    </div>
  );
};

export default FiltersSection;
