import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";

type Actions = {
  updateState: (payload: Partial<State>) => void;
};

export type PaginationType = {
  total_entries: number;
  total_pages: number;
  page: number;
  per_page: number;
};

export type State = {
  searchLeadsResult: {
    profiles: any[];
    apiPath?: string;
    pagination: PaginationType;
    cacheKey?: string;
    columsMapping?: any[];
  } | null;
  searchLeadsColumnNames: any[];
  searchLeadsResultRows: any[];
  selectedLeads: any[];
  selectionAction:
    | "select-all"
    | "clear-all"
    | "select-page"
    | "range-selection"
    | "";
  selectionCount: number;
  currentSearchPayload: any;
  isSearching?: boolean;
  isShowErrorModal?: boolean;
};

export const useSearchLeadsStore = create<State & Actions>((set) => ({
  searchLeadsResult: null,
  searchLeadsColumnNames: [],
  searchLeadsResultRows: [],
  selectedLeads: [],
  selectionAction: "",
  selectionCount: 0,
  currentSearchPayload: {},
  isSearching: false,
  isShowErrorModal: false,
  updateState: (payload) => set(() => payload),
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("SearchLeads", useSearchLeadsStore);
}
