import { useState } from "react";

import Icons from "@/components/Icons";
import { createANewColumnName } from "@/components/Table/utils";
import { useDisclosure } from "@chakra-ui/react";
import ActionButtons from "./ActionButtons";
import { ResponseDropdownPropsTypes } from "./ResponseDropdown";

function ObjectResponseButton({
  toggleExpand,
  isExpanded,
  itemKey,
  value,
  path,
  handleAddNewColumn,
  handleCopyToClipboard,
  enrichmentName,
}: Partial<ResponseDropdownPropsTypes>) {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [newColumnName, setNewColumnName] = useState(
    createANewColumnName(itemKey as string),
  );
  const keys = Object?.keys?.(value);
  const firstKey = keys?.[0];

  const peopleAtCompanyCellValueTitle = `${value?.name || ""} || ${value?.title || ""}`;
  const isFindPeopleAtCompany = enrichmentName === "findPeopleAtCompany";
  const isFindPeopleAtCompanyApollo =
    enrichmentName === "findPeopleAtCompanyApollo";

  return (
    <div
      className="relative w-full"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered || isOpen ? (
        <ActionButtons
          handleCopyToClipboard={
            handleCopyToClipboard as (value: string) => void
          }
          value={value}
          handleAddNewColumn={handleAddNewColumn as (value: {}) => void}
          path={path || ""}
          itemKey={itemKey as string}
          isOpen={isOpen}
          onToggle={onToggle}
          onClose={onClose}
          newColumnName={newColumnName}
          setNewColumnName={setNewColumnName}
          isObjectKey={true}
        />
      ) : null}
      <button
        className={`flex items-center gap-x-1.5 rounded-md border px-4 py-1.5 ${
          isHovered ? "border-primary bg-[#8ebff73f]" : "bg-white"
        }`}
        onClick={(event) => {
          if (toggleExpand) {
            toggleExpand(event, itemKey as string);
          }
        }}
      >
        {isExpanded?.[itemKey as string] ? (
          <Icons.ChevronDownIcon />
        ) : (
          <Icons.ChevronRightIcon />
        )}
        <p className="flex items-center space-x-1">
          <span className="inline-block max-w-[250px] truncate font-semibold">
            {typeof value?.[firstKey] === "string" && !isNaN(Number(itemKey))
              ? (isFindPeopleAtCompany || isFindPeopleAtCompanyApollo) &&
                peopleAtCompanyCellValueTitle?.length > 8
                ? peopleAtCompanyCellValueTitle
                : value?.[firstKey]
              : itemKey}
          </span>{" "}
          <span>({Object?.keys?.(value)?.length})</span>
        </p>
      </button>
    </div>
  );
}

export default ObjectResponseButton;
