import { useEffect, useState } from "react";
import { Skeleton, Spinner, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";

import PremiumIcon from "../Common/PremiumIcon";

import { useStarterList } from "@/hooks/useStarterList";
import tableService from "@/services/table.service";
import { useModalStore } from "@/stores/modal.store";

const StarterList = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [selectedId, setSelectedId] = useState("");

  const updateState = useModalStore((state) => state.updateState);
  const modalData = useModalStore((state) => state.modalData);
  const { isLoading, data, error } = useStarterList();
  const { isPending, mutateAsync } = useMutation({
    mutationFn: (id: string) => tableService.createAndGetStarterTable(id),
    onSuccess: (response) => {
      if (!response?.tableData) return;

      navigate({
        to: `/table/$tableId`,
        params: {
          tableId: response?.tableData._id!,
        },
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleCardClick = async (id: string) => {
    setSelectedId(id);
    await mutateAsync(id);
    updateState({
      isOpenModal: false,
      modalData: { modalType: "", metaData: "" },
    });
    setSelectedId("");
  };

  useEffect(() => {
    if (modalData?.starterTableId) {
      handleCardClick(modalData.starterTableId);
    }
  }, [modalData?.starterTableId]);

  if (isLoading) {
    return (
      <div className="flex justify-center gap-2">
        <Skeleton height="200px" width="400px" />
        <Skeleton height="200px" width="400px" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-2xl text-red-500">
        Error: {error.message}
      </div>
    );
  }

  return (
    <div id="starterTables" className={`startwith-action-container`}>
      <h2 className="pb-2.5 text-[14px] font-medium leading-[18px] text-[#7E889F]">
        Persana Starter Tables
      </h2>
      <div
        className="grid gap-5"
        style={{
          gridTemplateColumns: `repeat(3, minmax(0, 1fr))`,
        }}
      >
        {data?.map((list, index) => (
          <div
            key={list._id}
            id={`persana_starter_list_${index}`}
            onClick={() => {
              if (isPending) return;
              handleCardClick(list._id);
            }}
          >
            <div className="startWithCard">
              <div
                className={`relative flex min-h-[150px] w-full items-start gap-[25px] rounded-[8px] p-[24px] ${isPending ? "cursor-not-allowed" : "cursor-pointer"} ${selectedId === list._id ? "animate-bg-color" : "bg-white"} shadow-lg`}
              >
                <div>
                  <div className="flex items-start justify-between gap-1">
                    <p
                      className={`mb-[12px] text-left text-[16px] font-medium text-black`}
                    >
                      {list?.name}
                    </p>
                    {list.isPremium && <PremiumIcon />}
                  </div>

                  <p
                    className={`text-left text-[14px] font-[400] text-[#7A7A7A]`}
                  >
                    <span>{list?.description}</span>
                  </p>
                </div>
                {isPending && selectedId === list._id && (
                  <Spinner
                    size="xs"
                    color="purple"
                    className="absolute bottom-2 right-2"
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StarterList;
