import { Button } from "@chakra-ui/react";
import { allTemplates } from "../Leads/UseTemplateModal/constants";

const FindCompanyTechnologies = () => {
  const data = allTemplates.find(
    (item) => item.heading === "Find Company Technologies",
  );
  return (
    <div className="flex gap-4 my-6 px-[32px] py-[32px]">
      <div className="w-[45%] flex flex-col gap-4 px-[60px] pt-8">
        <p className="font-bold text-[28px] leading-[40px] text-[#0F0D1C] text-left">
          Try this workflow
        </p>
        <p className="text-[#4A5264] text-[18px]">{data?.title}</p>
        <Button
          w={"fit-content"}
          h={"38px"}
          fontSize={14}
          className="!bg-primary-gradient hover:opacity-80"
          colorScheme="primary"
          borderRadius={"6px"}
          onClick={() => {
            window.open(data?.url, "_blank");
          }}
        >
          Try Now
        </Button>
      </div>
      <div
        className="w-[55%] border border-primaryPink rounded-md p-2 cursor-pointer"
        onClick={() => {
          window.open(data?.url, "_blank");
        }}
      >
        <img src={data?.image} alt="" />
      </div>
    </div>
  );
};

export default FindCompanyTechnologies;
