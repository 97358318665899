import {
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import PersanaLogo from "@/assets/images/logo.png";
import OnboardingForm from "./OnboardingForm";

type Props = {
  callFrom: string;
  token: string;
  redirectUrl: string;
  onSubmit: (redirectUrl: string) => void;
};

const InHouseOnboardingModal = ({
  callFrom,
  redirectUrl,
  token,
  onSubmit,
}: Props) => {
  return (
    <>
      <Modal
        isOpen={true}
        onClose={() => {}}
        isCentered
        size={"6xl"}
        scrollBehavior="inside"
      >
        {callFrom !== "screen" && <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />}
        <ModalContent className="h-[90vh] p-0">
          <ModalBody className="flex h-full p-0" p={0}>
            <div className="flex h-full w-1/2 flex-col overflow-x-auto px-10">
              <p className="mx-auto mt-10 font-extrabold text-[#5F6B77]">
                Welcome to Persana
              </p>
              <p className="mx-auto p-[20px] text-[20px] text-[#2c2c2c] font-[600]">
                Tell us more about you and your company
              </p>
              <Divider />
              <OnboardingForm
                callFrom={callFrom}
                redirectUrl={redirectUrl}
                token={token}
                onSubmit={onSubmit}
              />
            </div>
            <div className="flex w-1/2 items-center justify-center bg-[#0C336A] text-white">
              <div className="flex flex-col items-center font-poppins space-y-4">
                <img src={PersanaLogo} className="w-[8rem]" />
                <div className="text-xl sm:text-lg flex flex-col items-center">
                  <p>Get AI superpowers</p>
                  <p>
                    Rated <span className="font-semibold italic">#1</span> AI
                    Prospecting Platform of 2024
                  </p>
                </div>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default InHouseOnboardingModal;
