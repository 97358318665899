import { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  Button,
  NumberInput,
  NumberInputField,
  Text,
  useToast,
} from "@chakra-ui/react";
import accountMonitoringService from "@/services/accountMonitoring.service";
import { useModalStore } from "@/stores/modal.store";

const HubspotImportModal = () => {
  const toast = useToast();
  const { isOpenModal, modalData, updateState } = useModalStore(
    (state) => state,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [numberOfLeads, setNumberOfLeads] = useState(1);

  const onClose = () => {
    setNumberOfLeads(1);
    updateState({
      isOpenModal: false,
      modalData: {
        modalType: "",
        metaData: {},
      },
    });
  };

  const handleImport = async () => {
    const apiPath =
      isOpenModal && modalData.modalType === "job-tracking"
        ? "api/users/hubspot-leads"
        : "api/users/hubspot-companies";
    setIsLoading(true);
    const res = await accountMonitoringService.importDataFromHubspot(
      apiPath,
      numberOfLeads,
    );
    if (res?.success) {
      modalData?.metaData?.callback();
      onClose();
    } else {
      toast({
        position: "top-right",
        title: res?.data?.message || "Something went wrong",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
      onClose();
    }
    setIsLoading(false);
    setNumberOfLeads(1);
  };

  return (
    <>
      <Modal
        onClose={() => {
          onClose();
        }}
        isOpen={
          isOpenModal &&
          (modalData.modalType === "job-opening" ||
            modalData.modalType === "job-tracking")
        }
        size={"2xl"}
        scrollBehavior="outside"
        isCentered
      >
        <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
        <ModalContent className="pb-4 font-poppins">
          <ModalHeader className="border-b-[1px] border-b-dividerColor text-center">
            <div className="d-flex items-center">
              <Text className="flex-1">Hubspot Import</Text>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="mt-4 flex items-center gap-2 pb-1">
              <p className="text-sm font-semibold ">
                {`Number of ${
                  modalData.modalType === "job-tracking" ? "leads" : "companies"
                } to fetch`}
              </p>
            </div>
            <div className="relative h-full">
              <NumberInput
                min={0}
                value={numberOfLeads}
                onChange={(val) => {
                  setNumberOfLeads(Number(val));
                }}
              >
                <NumberInputField />
              </NumberInput>
            </div>
            <div className="mt-[20px] flex w-full items-center justify-center">
              <Button
                isDisabled={!numberOfLeads}
                isLoading={isLoading}
                variant="solid"
                h={"38px"}
                fontSize={14}
                className="!bg-primary-gradient hover:opacity-80"
                colorScheme="primary"
                borderRadius={"6px"}
                onClick={() => {
                  handleImport();
                }}
              >
                <span className="font-semibold">Import</span>
              </Button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default HubspotImportModal;
