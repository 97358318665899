import {
  Divider,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import Icons from "@/components/Icons";
import tableService from "@/services/table.service";
import { useTableStore } from "@/stores/table.store";
import type { TableView } from "@/types/table.types";

const TableViews = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const toast = useToast();

  // const tableViews = useTableStore((state) => state.tableData.views);
  // const selectedViewId = useTableStore(
  //   (state) => state.tableData.selectedViewId,
  // );
  const updateState = useTableStore((state) => state.updateState);
  const tableData = useTableStore((state) => state.tableData);

  const tableViews = tableData.views || [];
  const selectedViewId = tableData.selectedViewId;
  const tableId = tableData._id;

  console.log("myLog tableViews", tableViews);
  console.log("myLog selectedViewId", selectedViewId);

  const [currentView, setCurrentView] = useState<TableView>(
    () => tableViews.find((view) => view._id === selectedViewId)!,
  );
  const [isEditing, setIsEditing] = useState(false);

  const { mutateAsync: createOrUpdateView, isPending } = useMutation({
    mutationFn: (
      payload: Parameters<typeof tableService.createOrUpdateView>[0],
    ) => tableService.createOrUpdateView(payload),
  });

  const { mutateAsync: updateSelectedView, isPending: isSelectedViewUpdating } =
    useMutation({
      mutationFn: ({ tableId, viewId }: { tableId: string; viewId: string }) =>
        tableService.updateTableDataV2(tableId, {
          selectedViewId: viewId,
        }),
    });

  const { mutateAsync: deleteView, isPending: isDeleting } = useMutation({
    mutationFn: (payload: Parameters<typeof tableService.deleteView>[0]) =>
      tableService.deleteView(payload),
  });

  const toggleEdit = () => {
    setIsEditing((prev) => !prev);
  };

  const handleViewClick = (view: TableView) => async () => {
    onClose();
    await updateSelectedView(
      { tableId, viewId: view._id },
      {
        onSuccess: async (response) => {
          console.log("response", response, view);
          updateState({
            tableData: {
              ...tableData,
              selectedViewId: view._id,
            },
            isTablePageReRender: true,
          });
          window.location.reload(); // TODO: remove it later
          // queryClient.invalidateQueries({
          //   queryKey: ["table", tableId],
          // });
        },
        onError: (error) => {
          toast({
            title: "Error",
            description: error.message,
            status: "error",
            duration: 3000,
          });
        },
      },
    );
  };

  const handleBlur = async () => {
    toggleEdit();
    await createOrUpdateView(
      {
        tableId,
        viewId: currentView?._id,
        bodyData: {
          name: currentView?.name,
        },
      },
      {
        onSuccess: (response) => {
          if (!response.success) {
            toast({
              title: "Error",
              description: response.message || "Something went wrong",
              status: "error",
              duration: 3000,
            });
            return;
          }
          updateState({
            tableData: {
              ...tableData,
              views: response.data!,
            },
          });
        },
        onError: (error) => {
          toast({
            title: "Error",
            description: error.message,
            status: "error",
            duration: 3000,
          });
        },
      },
    );
  };

  const handleDuplicateView = async () => {
    onClose();

    const copiedCurrentView = { ...currentView };

    // @ts-ignore
    delete copiedCurrentView?._id;

    await createOrUpdateView(
      {
        tableId,
        viewId: "new",
        bodyData: {
          ...copiedCurrentView,
          name: `${currentView?.name} Copy`,
        },
      },
      {
        onSuccess: (response) => {
          if (!response.success || !response.data?.length) {
            toast({
              title: "Error",
              description: response.message || "Something went wrong",
              status: "error",
              duration: 3000,
            });
            return;
          }
          updateState({
            tableData: {
              ...tableData,
              views: response.data!,
            },
          });

          const recentView = response.data[response.data.length - 1];
          handleViewClick(recentView)();
        },
        onError: (error) => {
          toast({
            title: "Error",
            description: error.message,
            status: "error",
            duration: 3000,
          });
        },
      },
    );
    // TODO: Update views state
  };

  const handleDeleteView = (viewId: string) => async () => {
    onClose();
    await deleteView(
      {
        tableId,
        viewId,
      },
      {
        onSuccess: (response) => {
          if (!response.success) {
            toast({
              title: "Error",
              description: response.message || "Something went wrong",
              status: "error",
              duration: 3000,
            });
            return;
          }
          updateState({
            tableData: {
              ...tableData,
              views: response.data!,
            },
          });
        },
        onError: (error) => {
          toast({
            title: "Error",
            description: error.message,
            status: "error",
            duration: 3000,
          });
        },
      },
    );
  };
  console.log("currentView", currentView);

  return (
    <div className="flex items-center gap-2 border" key={selectedViewId}>
      <div className="w-fit">
        {isEditing ? (
          <Input
            className="w-fit"
            onBlur={handleBlur}
            autoFocus
            value={currentView?.name}
            onChange={(e) => {
              setCurrentView((prev) => ({
                ...prev!,
                name: e.target.value,
              }));
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleBlur();
              }
            }}
          />
        ) : (
          <span className="w-fit px-2 text-sm" onClick={toggleEdit}>
            {currentView?.name}
          </span>
        )}
      </div>
      <Popover
        placement="top-start"
        closeOnBlur
        onOpen={onOpen}
        onClose={onClose}
        isOpen={isOpen}
      >
        <PopoverTrigger>
          <IconButton
            aria-label="Open Views"
            icon={<Icons.ArrowUpIcon />}
            className="!h-[35px] !border-0 bg-slate-100"
            variant="outline"
            isLoading={isPending || isSelectedViewUpdating || isDeleting}
          />
        </PopoverTrigger>
        <Portal>
          <PopoverContent className="!w-fit min-w-[200px] !p-0">
            <PopoverArrow />
            <PopoverBody className="w-full !p-2">
              {tableViews.map((view) => (
                <div
                  key={view._id}
                  className="mb-2 flex items-center justify-between gap-2 shadow-sm"
                >
                  <span
                    className="flex flex-1 cursor-pointer items-center gap-2 p-[4px] text-sm hover:bg-slate-200"
                    onClick={handleViewClick(view)}
                  >
                    <span
                      className={
                        currentView?._id !== view._id ? "invisible" : ""
                      }
                    >
                      <Icons.CheckMarkIcon />
                    </span>
                    <span>{view.name}</span>
                  </span>
                  <IconButton
                    aria-label="Delete View"
                    icon={
                      <Icons.TrashIcon
                        size={16}
                        className={
                          view._id === "new"
                            ? "text-darkTextGray"
                            : "text-red-500"
                        }
                      />
                    }
                    className={`!h-[20px] !border-0 bg-none ${view._id === "new" ? "cursor-not-allowed" : "hover:bg-red-500"}`}
                    variant="outline"
                    isDisabled={view._id === currentView?._id}
                    onClick={
                      view._id === currentView?._id
                        ? undefined
                        : handleDeleteView(view._id)
                    }
                  />
                </div>
              ))}
              <Divider className="my-2" />
              <button
                className="flex items-center gap-2 rounded-md p-1 hover:bg-slate-200"
                onClick={handleDuplicateView}
              >
                <Icons.CopyIcon />
                <span>Duplicate View</span>
              </button>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </div>
  );
};

export default TableViews;
