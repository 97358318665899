import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { twMerge } from "tailwind-merge";

interface TailwindModalLayoutProps {
    children: React.ReactNode;
    modalOpen: boolean;
    setModalOpen: (value: boolean) => void;
    closeOnOuterClick?: boolean;
    overlayClassName?: string;
    dialogClassName?: string;
};

function TailwindModalLayout({ children, modalOpen, setModalOpen, closeOnOuterClick, overlayClassName, dialogClassName }: TailwindModalLayoutProps) {
    return (
        <Transition.Root show={modalOpen} as={Fragment}>
            <Dialog
                as="div"
                className={`${twMerge("fixed inset-0 !z-[200] overflow-y-auto", dialogClassName)}`}
                onClose={
                    closeOnOuterClick ? setModalOpen : () => { }
                }
            >
                <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className={`${twMerge("fixed inset-0 bg-[rgb(0_0_0_/_0.45)] bg-opacity-75 transition-opacity", overlayClassName)}`} />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:h-screen sm:align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        {children}
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default TailwindModalLayout
