import { QuestProvider, Referral as ReferEarn } from "@questlabs/react-sdk";

import questConfig from "@/constants/questConfig";
import { useUserData } from "@/hooks/useUserData";
import { useModalStore } from "@/stores/modal.store";

export default function Referral() {
  const userData = useUserData();
  const isReferralPopup = useModalStore(
    (state) => state?.modalData?.modalType === "referral" && state.isOpenModal,
  );

  const closeModal = (e: any) => {
    if (document.getElementById("modal_box")?.contains(e.target)) {
      /* empty */
    } else {
      useModalStore
        .getState()
        .updateState(isReferralPopup ? { isOpenModal: false } : {});
    }
  };

  if (!isReferralPopup) {
    return null;
  }
  return (
    <div
      className="fixed left-0 top-0 flex h-screen w-screen cursor-pointer items-center justify-center bg-[rgba(0,0,0,0.45)]"
      style={{ zIndex: "100" }}
      onClick={closeModal}
    >
      <div id={"modal_box"} className={"cursor-default"}>
        <QuestProvider
          apiKey={questConfig.API_KEY}
          apiSecret={questConfig.API_SECRET}
          entityId={questConfig.ENTITY_ID}
        >
          <ReferEarn
            questId={questConfig.REFERRAL_SCREN_ID}
            token={questConfig.ADMIN_TOKEN}
            userId={questConfig.ADMIN_ID}
            // @ts-ignore
            uniqueEmailId={userData?.data?.Email}
            uniqueUserId={userData?.data?.Email}
            heading={"Free Persana Credits 🚀"}
            description={
              "Share your unique referral link with friends and receive up to 6,000 credits each time a friend signs up for paid accounts!"
            }
            referralLink={`${window.location.origin}/login?ref=`}
            showReferralCode={false}
            showFooter={false}
            gradientBackground
            gradientBackgroundColor="linear-gradient(127deg, rgb(105, 61, 199) 0%, rgb(191, 114, 225) 100%)"
            styleConfig={{
              PrimaryButton: {
                background:
                  "linear-gradient(127deg, rgb(105, 61, 199) 0%, rgb(191, 114, 225) 100%)",
              },
            }}
          />
        </QuestProvider>
      </div>
    </div>
  );
}
