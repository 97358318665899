import Soc2Image from "@/assets/images/soc2-compliant.png";
import ProfileByEmailImage from "@/assets/images/find-profile-by-email.png";
import EnrichProfileImage from "@/assets/images/enrich-profile.png";
import FindJobsImage from "@/assets/images/find-jobs.png";
import CompanyNewsImage from "@/assets/images/company-news.png";
import WorkEmailImage from "@/assets/images/work-email.png";
import CompanyHiringImage from "@/assets/images/company-hiring.png";
import PricingModelImage from "@/assets/images/pricing-model.png";
import CompanyCompetitorsImage from "@/assets/images/company-comp.png";
import DecisionMakersImage from "@/assets/images/decisioin-maker.png";
import CompanyTechImage from "@/assets/images/company-tech.png";

export type TemplateTypes = {
  templateId: string;
  heading: string;
  title: string;
  url: string;
  image: any;
};

export const allTemplates: TemplateTypes[] = [
  {
    templateId: "1",
    heading: "Find Companies",
    title: "Find Whether Companies are Soc 2 Compliant",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjYyMDQ0ZmUxMDEwYmFmYzIxMmNhOTNiIiwiaWF0IjoxNzEzMzkyNjY5LCJleHAiOjE3NDQ5NTAyNjl9.LwJNo8WPwgWBDsRjmyEV2Yws9c3XK7nsEG7gMNm4Bq0",
    image: Soc2Image,
  },
  {
    templateId: "2",
    heading: "Enrich Profiles by Email ID",
    title:
      "Find LinkedIn Profiles and Enrich People by their work email address",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjYyMDY4NjgxMDEwYmFmYzIxMmNhYTk3IiwiaWF0IjoxNzEzNDAwMDY1LCJleHAiOjE3NDQ5NTc2NjV9.-9kckIRqtTjxaPbn6cDbIjyTCYFS764cQgEbIDF2SOw",
    image: ProfileByEmailImage,
  },
  {
    templateId: "3",
    heading: "Enrich Profiles",
    title: "Find LinkedIn Profiles and Enrich People by their Name and Company",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjYyMDUxZTZhZTM4M2EzODdiMDhmMzA1IiwiaWF0IjoxNzEzMzk0MTY0LCJleHAiOjE3NDQ5NTE3NjR9.fgWRDCem6lf_t8fLNWXxNNYffkKqb-vhM9v4HaW3Q5A",
    image: EnrichProfileImage,
  },
  {
    templateId: "4",
    heading: "Track Companies Hiring",
    title: "Track a list of companies for hiring",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjYyMDY5MzkyOTY2NzlkYzQwOGE1ZjJiIiwiaWF0IjoxNzEzNDAwMzM2LCJleHAiOjE3NDQ5NTc5MzZ9.fvqSxou_r5YChbvWLKXYyuoi932vdiulzCyOo_UM9n4",
    image: FindJobsImage,
  },
  {
    templateId: "5",
    heading: "Find Company News",
    title: "Find Company News from Company URL",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjYyMDZhMWYyOTY2NzlkYzQwOGE1ZjUwIiwiaWF0IjoxNzEzNDAwMzk3LCJleHAiOjE3NDQ5NTc5OTd9.A-5y_DM31SVgjpBLDrSgKnNBfZjqFOPg23lVv2NiOGY",
    image: CompanyNewsImage,
  },
  {
    templateId: "6",
    heading: "Find Work Email",
    title:
      "Find LinkedIn Profiles and Enrich People by their work email address",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjYyMDY3ZTljNDdjNjAwZDUxOGMwZGI3IiwiaWF0IjoxNzEzMzk5ODExLCJleHAiOjE3NDQ5NTc0MTF9.reFlB9y9dGr9h6xz0azhj8--sa3fSw5ZPZwogH-cMNg",
    image: WorkEmailImage,
  },
  {
    templateId: "7",
    heading: "Find Open Roles in Companies Hiring",
    title:
      "Get started with streamlining the recruiting process by observing this starter table.",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjY2YjJjNDY4MTczN2U3NGM4MTZjYjA1IiwiaWF0IjoxNzE4Mjk5NzIxLCJleHAiOjE3NDk4NTczMjF9.AMaF2VM22dkWBkZKomtuLmVnHeUhluXV6xds098kmKc",
    image: CompanyHiringImage,
  },
  {
    templateId: "8",
    heading: "Identify pricing models by scoring SaaS brands",
    title: "Qualify SaaS leads by cross-referencing their pricing page.",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjY2OGI0MTM4Y2UxMzYyYWVjMjM4MGVkIiwiaWF0IjoxNzE4MTM3ODgxLCJleHAiOjE3NDk2OTU0ODF9.jecPMWhuwmg4ESfl-xnQN5gzZ_14sWGUA65qhb0OBsE",
    image: PricingModelImage,
  },
  {
    templateId: "9",
    heading: "Find company competitors",
    title:
      "Survey competitors and pinpoint differences using Persana's AI Agent.",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjY2YTA5OGMyZDJjMmRlM2E4ODkwYjYwIiwiaWF0IjoxNzE4MjI1Mjk0LCJleHAiOjE3NDk3ODI4OTR9.cNDn0ggq0d7DpCAN8ZZfzILJ4xtoLQYxuHYtulYEMdQ",
    image: CompanyCompetitorsImage,
  },
  {
    templateId: "10",
    heading: "Find key decision makers + enrich leads",
    title: "Upload leads onto La Growth Machine with Persana.",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjY2OWZkOWRlZGM2MTRjNzYyZDc1ZjkzIiwiaWF0IjoxNzE4MjIyMjQwLCJleHAiOjE3NDk3Nzk4NDB9.KpPxE6vgukVr0F67wZPZ2csMN22ksthuMGGxElyUUpE",
    image: DecisionMakersImage,
  },
  {
    templateId: "11",
    heading: "Find Company Technologies",
    title: "Gain insight on a company's tech stack with Persana.",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjY2YjJhYWU5ZjA0MDI3M2RkNzkzMTc2IiwiaWF0IjoxNzE4Mjk5MzEzLCJleHAiOjE3NDk4NTY5MTN9.svDh_SKWMVfWx-VL6oVCxt8Jz5B2Uq3aASqNSuSp5jc",
    image: CompanyTechImage,
  },
];
