import Icons from "@/components/Icons";
import { Tooltip } from "@chakra-ui/react";

type Props = {
  name?: string;
  checked: boolean;
  setChecked?: (event: any) => void;
  label?: string;
  labelExplanation?: string;
};

const RadioButton = ({ name, label, labelExplanation, checked, setChecked }: Props) => {
  return (
    <div className="flex items-center">
      <input
        id={name}
        type="radio"
        name={name}
        checked={checked}
        onClick={setChecked}
        className="h-4 w-4 border-gray-300 focus:outline-none"
        aria-labelledby={`${label}`}
        aria-describedby={`${label}`}
      />
      <div className="flex items-center gap-x-1.5">
        <label
          htmlFor={name}
          className="ml-1.5 block font-title text-sm font-semibold text-[#141313b2]"
        >
          {label}
        </label>
        {labelExplanation && (
          <Tooltip
            hasArrow
            borderRadius={"5px"}
            textAlign={"center"}
            top={"-2px"}
            label={labelExplanation}
            aria-label="A tooltip"
            placement="top"
          >
            <span className="block"><Icons.CircleQuestionIcon className="text-[0.7rem] relative top-[-3px] text-purple-700" /></span>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default RadioButton;
