import { useCallback, useEffect } from "react";
import Swal from "sweetalert2";
import { useMutation } from "@tanstack/react-query";
import { Portal, useToast } from "@chakra-ui/react";

import Icons from "@/components/Icons";
import CustomErrorBoundary from "@/components/Common/ErrorComponent/CustomErrorBoundary";

import { useTableStore } from "@/stores/table.store";
import tableService from "@/services/table.service";
import { useProcessingStore } from "@/stores/processing.store";

const CellAction = () => {
  const isTablePreviewPage =
    window.location.pathname.startsWith("/table-preview");
  const toast = useToast();
  const processingData = useProcessingStore((state) => state.processingData);
  const isAnyProcessRunning = !!processingData?.length;

  const contextMenuState = useTableStore((state) => state.contextMenuState);
  const updateState = useTableStore((state) => state.updateState);
  const deleteRow = useTableStore((state) => state.deleteRow);
  const tableId = useTableStore((state) => state.tableData._id);

  const { mutateAsync } = useMutation({
    mutationFn: ({ tableId, rowId }: { tableId: string; rowId: string }) =>
      tableService.removeRows([rowId], tableId),
  });

  const callToDeleteRow = async () => {
    await mutateAsync(
      {
        rowId: contextMenuState.metaData.rowId!,
        tableId,
      },
      {
        onSuccess: (response) => {
          if (response.success == false) {
            toast({
              title: "Something went wrong",
              description: response.error.message,
              status: "error",
              duration: 9000,
              isClosable: true,
              position: "top-right",
            });
            return;
          }

          // Update state, remove the row
          deleteRow(contextMenuState.metaData.rowId!);

          toast({
            title: "Row deleted successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
            position: "top-right",
          });
        },
        onError: (error) => {
          toast({
            title: "Something went wrong",
            description: error.message,
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top-right",
          });
        },
      },
    );
  };

  const handleAction =
    (action: "insert_top" | "delete" | "insert_bottom") => () => {
      if (isAnyProcessRunning) return;
      if (action === "delete") {
        localStorage.getItem("dontShowRowDeleteConfirmation") === "true"
          ? callToDeleteRow()
          : Swal.fire({
              title: "Are you sure?",
              text: "You won't be able to revert this!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#d33",
              cancelButtonColor: "#AA6BFA",
              confirmButtonText: "Yes, delete it!",
              input: "checkbox",
              inputValue: 0,
              inputPlaceholder: "Don't show this again",
              showLoaderOnConfirm: true,
              preConfirm: async (dontShowAgain: boolean) => {
                if (dontShowAgain) {
                  localStorage.setItem("dontShowRowDeleteConfirmation", "true");
                }
                return callToDeleteRow();
              },
            });
        return;
      }
    };

  const clearContextMenu = useCallback(() => {
    updateState({
      contextMenuState: {
        x: 0,
        y: 0,
        metaData: {
          rowId: null,
        },
      },
    });
  }, []);

  // Inside your component
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        clearContextMenu();
      }
    };

    document.addEventListener("click", clearContextMenu);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("click", clearContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  if (isTablePreviewPage) return null;

  return (
    <Portal>
      <div
        style={{
          position: "absolute",
          top: contextMenuState.y,
          left: contextMenuState.x,
          zIndex: 1000,
          display: contextMenuState.x ? "block" : "none",
        }}
        className="w-fit space-y-0.5 rounded-md bg-[#343841] p-[4px] text-white shadow duration-300"
      >
        <button
          className={`flex w-full items-center gap-x-1 rounded-md py-1.5 pl-3 pr-14 duration-300 hover:bg-white/10 hidden ${
            isAnyProcessRunning ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          onClick={handleAction("insert_top")}
        >
          <Icons.ArrowUpIcon className="text-xl" />
          <span className="text-xs">Insert Row</span>
        </button>
        <button
          className={`flex w-full items-center gap-x-1 rounded-md py-1.5 pl-3 pr-14 duration-300 hover:bg-white/10 hidden ${
            isAnyProcessRunning ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          onClick={handleAction("insert_bottom")}
        >
          <Icons.ArrowDownIcon className="text-xl" />
          <span className="text-xs">Insert Row</span>
        </button>
        <button
          className={`flex w-full items-center gap-x-1 rounded-md py-1.5 pl-3 pr-14 duration-300 hover:bg-white/10 ${
            isAnyProcessRunning ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          onClick={handleAction("delete")}
        >
          <Icons.DeleteBinIcon className="text-xl  text-red-500" />
          <span className="text-xs text-red-500">Delete Row</span>
        </button>
      </div>
    </Portal>
  );
};

const WrappedCellAction = () => (
  <CustomErrorBoundary>
    <CellAction />
  </CustomErrorBoundary>
);

export default WrappedCellAction;
