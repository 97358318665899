import { Switch } from "@chakra-ui/react";
import { useState } from "react";

import tableService from "@/services/table.service";
import { useTableStore } from "@/stores/table.store";

const AutoRun = () => {
  const tableData = useTableStore((state) => state.tableData);

  const updateTableMetaDta = useTableStore((state) => state.updateTableMetaDta);

  const [isEnableAutoRun, setIsEnableAutoRun] = useState(
    () => !!tableData.metaData?.isEnableAutoRun,
  );

  return (
    <div className="flex !h-[35px] cursor-pointer items-center rounded-md border !border-[#8080801c] !bg-gray px-2 !text-sm hover:!shadow-md">
      <label className="mr-2 cursor-pointer" htmlFor="auto-run-switch">
        Auto Run
      </label>
      <Switch
        isChecked={isEnableAutoRun}
        size="lg"
        onChange={(e) => {
          setIsEnableAutoRun(e.target.checked);
          tableService.updateTableDataV2(tableData._id, {
            metaData: {
              isEnableAutoRun: e.target.checked,
            },
          });
          updateTableMetaDta({
            isEnableAutoRun: e.target.checked,
          });
        }}
        id="auto-run-switch"
      />
    </div>
  );
};

export default AutoRun;
