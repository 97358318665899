import { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useRouter } from "@tanstack/react-router";

import Integrations from "@/components/Integrations";
import ProfileDetails from "@/components/ProfileDetails";
import CreditHistory from "@/components/Settings/CreditHistory";

const Settings = () => {
  const router = useRouter();
  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    {
      name: "Profile",
      component: ProfileDetails,
    },
    {
      name: "Integrations",
      component: Integrations,
    },
    {
      name: "Credits History",
      component: CreditHistory,
    },
  ];

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const integrationParam = searchParams.get("integration");
    if (integrationParam === "true") {
      setActiveTab(1);
    }
  }, [location.search]);

  useEffect(() => {
    const searchStr = router.state.location.searchStr;
    if (searchStr?.includes("integrations")) {
      setActiveTab(1);
    }
  }, [router]);

  return (
    <section>
      <Tabs index={activeTab} onChange={(index) => setActiveTab(index)}>
        <div className="dark:shadow-none">
          <TabList>
            {tabs.map((tab, index) => (
              <Tab fontSize={"xl"} fontWeight={"bold"} key={index}>
                {tab.name}
              </Tab>
            ))}
          </TabList>
        </div>

        <TabPanels>
          {tabs.map((tab, index) => (
            <TabPanel key={index}>{<tab.component />}</TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </section>
  );
};

export default Settings;
