import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";

import ErrorImg from "@/assets/svgs/persana-magic-error.svg";
import { useModalStore } from "@/stores/modal.store";
import Icons from "../Icons";

type Props = {
  isShowErrorModal: boolean;
  handleClose: () => void;
};
const ErrorModal = ({ handleClose, isShowErrorModal }: Props) => {
  const updateModalState = useModalStore((state) => state.updateState);
  return (
    <Modal isOpen={!!isShowErrorModal} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <div className="mb-2 flex flex-col items-center justify-center gap-2">
            <img src={ErrorImg} alt="" />
            <p className="text-[20px] mt-[-60px] text-black">
              Want to access more leads?
            </p>
            <p className="text-[14px] text-[#373F51]">
              Your plan only allows you to access 25 leads
            </p>
            <div className="flex flex-col justify-center gap-1 text-[#7E889F] text-[13px]">
              <div className="flex items-center gap-2">
                <Icons.CheckCircleIcon className="text-[#00B41D]" />
                <p>Maximize productivity to find the perfect leads</p>
              </div>
              <div className="flex items-center gap-2">
                <Icons.CheckCircleIcon className="text-[#00B41D]" />
                <p>Save 40+ hours per week with bulk select</p>
              </div>
              <div className="flex items-center gap-2">
                <Icons.CheckCircleIcon className="text-[#00B41D]" />
                <p>Select up to 1000 leads at a time</p>
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <div className="w-full">
            <Button
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              className={`!w-full !rounded-[6px] !bg-[#5024DC] !px-4 !py-[15px] !font-title !text-sm !font-semibold !tracking-wide !text-white`}
              onClick={() => {
                handleClose();
                updateModalState({
                  isOpenModal: true,
                  modalData: { modalType: "pricing" },
                });
              }}
            >
              View pricing plans
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ErrorModal;
