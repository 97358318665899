import { Checkbox } from "@chakra-ui/react";
import { CustomCellRendererProps } from "ag-grid-react";
import { useTableStore } from "../hooks/useGridState.store";

export function RowCheckboxRenderer({ node }: CustomCellRendererProps) {
  const isSelected = useTableStore(
    (state) => state.isSelectAll || node.isSelected(),
  );
  const setIsSelectAll = useTableStore((state) => state.setIsSelectAll);
  const toggleSelect = (val: boolean) => {
    if (val) {
      if (!node.isSelected()) node.setSelected(true);
    } else {
      if (node.isSelected()) node.setSelected(false);
      setIsSelectAll(false);
    }
  };
  return (
    <Checkbox
      isChecked={isSelected}
      onChange={(e) => toggleSelect(e.target.checked)}
      className="m-auto place-self-center"
    />
  );
}
