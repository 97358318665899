import { useEffect, useState } from "react";

import WatchTutorial from "@/components/Common/WatchTutorial";
import Examples from "./Examples";
import KeySelect from "./KeySelect";
import ModelSelect from "./ModelSelect";
import NewCustomPreset from "./NewCustomPreset";
import ProviderSelect from "./ProviderSelect";
import SelectTemplate from "./SelectTemplate";
import VersionSelect from "./VersionSelect";

import ConditionalFormula from "../../Common/ConditionalFormula";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";
import SelectableTextEditor from "../../Common/SelectableTextEditor";

import { useChatGPTTutorialLink } from "../../constants";
import { ProviderType } from "./constants";

import { useEnrichStore } from "@/stores/enrich.store";
import { usePresetsStore } from "@/stores/presets.store";
import { claudeModel, gptVersion } from "@/types/enrichment.types";
import { EnrichmentMetaData } from "@/types/table.types";
import { FormControl, FormLabel, Switch } from "@chakra-ui/react";

interface EnrichmentDataType extends EnrichmentMetaData {
  isReturnJson: boolean;
  prompts: string;
  isUseOwnKey: boolean;
  provider: ProviderType;
  gptVersion: gptVersion;
  claudeModel: claudeModel;
  examples: {
    input?: string;
    output?: string;
  }[];
}

const UseAi = () => {
  const [selectedProvider, setSelectedProvider] =
    useState<ProviderType>("chat-gpt");
  const [isReturnJson, setIsReturnJson] = useState(false);

  const updateState = usePresetsStore((state) => state.updateState);
  const { updateState: updateEnrichState } = useEnrichStore();
  const selectedPresetValues = usePresetsStore(
    (state) => state.selectedPresetValues,
  );

  const gptVersion = useEnrichStore((state) => state.gptVersion);
  const claudeModel = useEnrichStore((state) => state.claudeModel);
  const _examples = useEnrichStore((state) => state.examples);
  const updateEnrichmentState = useEnrichStore((state) => state.updateState);
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );

  const payload = {
    prompts: `${selectedPresetValues}`,
    examples: _examples,
    gptVersion,
    apiKeyName: selectedProvider === "claude" ? "claude" : "openAiApiKey",
    provider: selectedProvider,
    claudeModel,
    isReturnJson,
  };

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType;

      if (enrichmentData) {
        updateState({
          selectedPresetValues: enrichmentData.prompts || "",
        });
        updateEnrichmentState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
          keySelect: enrichmentData.isUseOwnKey ? "ownApiKey" : "persanaApiKey",
          gptVersion: enrichmentData.gptVersion || "gpt-4o",
          claudeModel: enrichmentData.claudeModel || "claude-3-opus-20240229",
        });

        if (enrichmentData?.examples?.length > 0) {
          updateEnrichmentState({
            examples: enrichmentData.examples,
          });
        }

        if (enrichmentData.provider) {
          setSelectedProvider(enrichmentData.provider);
        }
        if (enrichmentData.isReturnJson) {
          setIsReturnJson(!!enrichmentData.isReturnJson);
        }
      }
    }
  }, [selectedColumnToEdit]);

  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <WatchTutorial
          subText={"Use AI to answer any question"}
          link={useChatGPTTutorialLink}
        />
        <SelectTemplate />
        <SelectableTextEditor
          title="Write your prompt"
          text={selectedPresetValues || ""}
          setText={(newInputText) => {
            updateState({
              selectedPresetValues: newInputText,
            });
          }}
          rows={8}
          callFrom="use-ai"
        />
        <Examples />
        <NewCustomPreset />
        <KeySelect selectedProvider={selectedProvider} />
        <ProviderSelect
          value={selectedProvider}
          onChange={(newValue: ProviderType) => {
            setSelectedProvider(newValue);
            newValue === "claude" &&
              updateEnrichState({
                keySelect: "ownApiKey",
              });
          }}
        >
          {selectedProvider === "chat-gpt" ? (
            <VersionSelect />
          ) : (
            <ModelSelect />
          )}
        </ProviderSelect>
        <div className="my-4 border p-[5px]">
          <FormControl
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <FormLabel
              htmlFor="email-alerts"
              mb="0"
              className="flex-1 cursor-pointer !text-sm"
            >
              Return as JSON Object
            </FormLabel>
            <Switch
              id="email-alerts"
              colorScheme="teal"
              size="md"
              isChecked={isReturnJson}
              onChange={(e) => {
                setIsReturnJson(e.target.checked);
              }}
            />
          </FormControl>
          <p className="mt-2 text-[12px] font-normal leading-[20px] text-darkGray">
            If activated, the result will be a JSON object and make sure to
            mention the word <strong>json</strong> in your prompt.
          </p>
        </div>
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedPresetValues}
        apiPayload={payload}
      />
    </>
  );
};

export default UseAi;
