import { useEffect, useState } from "react";

import SelectColumnDropdown from "@/components/Enrichment/Common/SelectColumnDropdown";
import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import WatchTutorial from "@/components/Common/WatchTutorial";
import ConditionalFormula from "../../Common/ConditionalFormula";

import { REGEX } from "../../constants";
import { useEnrichStore } from "@/stores/enrich.store";
import { getInitialColumn, preselectEnrichmentState } from "@/utils";
import type { TSelectedColumnOption } from "@/types/common.types";
import { EnrichmentMetaData } from "@/types/table.types";
import KeySelect from "@/components/Common/KeySelect";

interface EnrichmentDataType extends EnrichmentMetaData {
  isUseOwnKey: boolean;
  extraColumns?: string[];
  excludedJobTitles?: Option[];
  includedJobTitles?: Option[];
  isPrioritizeJobTitle?: boolean;
  companyNameId?: string;
}

const FindPeopleAtCompany2 = () => {
  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);
  const [includedJobTitles, setIncludedJobTitles] = useState<Option[] | []>([]);

  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedEnrichment = useEnrichStore(
    (state) => state.selectedEnrichments,
  );
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);

  const updateJobTitlesState = (titles: string | string[], setState: any) => {
    if (Array.isArray(titles)) {
      setState(
        titles.map((item) => ({
          label: item,
          value: item,
        })),
      );
    } else {
      const jobTitles = titles.split(",");
      setState(
        jobTitles.map((item) => ({
          label: item,
          value: item,
        })),
      );
    }
  };

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType;
      if (enrichmentData) {
        preselectEnrichmentState(enrichmentData, setSelectedOption);
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
          keySelect: enrichmentData?.isUseOwnKey
            ? "ownApiKey"
            : "persanaApiKey",
        });

        updateJobTitlesState(
          // @ts-ignore
          enrichmentData.includedJobTitles || [],
          setIncludedJobTitles,
        );
      }

      return;
    }

    const initialColumn = getInitialColumn(REGEX.WEBSITE);
    if (initialColumn) {
      setSelectedOption({
        key: initialColumn.name,
        keyId: initialColumn._id,
        iconType: initialColumn.metaData?.iconType || "url",
      });
    }
  }, [viewMode, selectedColumnToEdit]);

  return (
    <>
      <div className="max-h-[70vh]  grow overflow-y-auto p-4">
        <WatchTutorial subText="Find People At Company by Website" link={""} />
        {selectedEnrichment?.isUseOwnKey ? (
          <KeySelect
            providerName={"apolloio"}
            providerTitle="Apollo.io"
            disablePersanaKey={selectedEnrichment?.isUseOwnKey}
          />
        ) : null}
        <div className="py-2">
          <p className="pb-1 text-base font-semibold">Company Website</p>
          <SelectColumnDropdown
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>
        <div className="space-y-4 pt-3">
          <div className="">
            <div className="flex items-center gap-x-3">
              <p className="text-base font-semibold">
                Job Title{" "}
                <span className="text-darkTextGra font-title text-xs font-bold">
                  (Optional)
                </span>
              </p>
            </div>

            <MultiSelectDropdown
              value={includedJobTitles}
              options={[]}
              onChange={(selectedValues) => {
                setIncludedJobTitles(selectedValues as Option[]);
              }}
              placeholder="Add job titles to include"
            />
          </div>
        </div>
        <ConditionalFormula />
      </div>

      <SaveAndRunEnrichment
        isDisabled={!selectedOption?.key}
        apiPayload={{
          apiKeyName: "apolloio",
          columnId: selectedOption?.keyId,
          includedJobTitles: includedJobTitles?.map((item) => item.value),
        }}
      />
    </>
  );
};

export default FindPeopleAtCompany2;
