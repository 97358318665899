import { useEffect, useState } from "react";

import ConditionalFormula from "../../Common/ConditionalFormula";
import SaveAndRun from "../../Common/SaveAndRun";
import SelectColumnDropdown from "../../Common/SelectColumnDropdown";

import { useEnrichStore } from "@/stores/enrich.store";
import { TSelectedColumnOption } from "@/types/common.types";
import { getInitialColumn, preselectEnrichmentState } from "@/utils";
import { REGEX } from "../../constants";

const CompanyGrowth = () => {
  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);

  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as any;

      if (enrichmentData) {
        preselectEnrichmentState(enrichmentData, setSelectedOption);
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
      }
    } else {
      const initialColumn = getInitialColumn(REGEX.WEBSITE);
      if (initialColumn) {
        setSelectedOption({
          key: initialColumn.name,
          keyId: initialColumn._id,
          iconType: initialColumn.metaData?.iconType || "url",
        });
      }
    }
  }, [selectedColumnToEdit, viewMode]);
  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <p className="pb-1 text-base font-semibold">Company Website</p>
        <SelectColumnDropdown
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
        <ConditionalFormula />
      </div>
      <SaveAndRun
        isDisabled={!selectedOption?.keyId}
        apiPayload={{
          columnId: selectedOption?.keyId,
        }}
      />
    </>
  );
};

export default CompanyGrowth;
