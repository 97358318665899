import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { countries } from "countries-list";
import { useCallback, useEffect, useMemo, useState } from "react";

import AccordionButton from "../Common/AccordionButton";
import MultiSelectDropdown from "../Common/MultiSelectDropdown";
import PersanaInput from "../Common/PersanaInput";
import SearchInputAccordion from "../Common/SearchInputAccordion";

import FilterIcon from "@/assets/svgs/filter.svg";
import CompanyIcon from "@/assets/svgs/people-search-company.svg";
import JobTitleIcon from "@/assets/svgs/people-search-job-title.svg";
import LocationIcon from "@/assets/svgs/people-search-location.svg";
import NameIcon from "@/assets/svgs/people-search-name.svg";
import leadService from "@/services/leads.service";
import { useSearchLeadsStore } from "@/stores/searchleads.store";
import { SearchCompanyType } from "@/types/leads.types";
import {
  extractNameAndContinent,
  filterValidObjKeys,
  generateId,
  handlePushToArray,
} from "@/utils";
import { apolloCompanySize, jobTitles } from "@/utils/constants";
import Icons from "../Icons";
import { usePeopleSearch } from "./hooks/usePeopleSearch";

interface StaticType {
  name: string;
  value?: string;
}

interface OptionType {
  label: string;
  value: string;
}

interface InputtedData {
  search_text: string;
  person_titles: OptionType[];
  person_not_titles: OptionType[];
  person_past_titles: OptionType[];
  person_seniorities: StaticType[];
  q_person_name: string;
  organization_ids: any; // assuming ids are numbers
  not_organization_ids: any; // assuming ids are numbers
  person_past_organization_ids: any; // assuming ids are numbers
  organization_industry_tag_ids: StaticType[]; // assuming ids are numbers
  organization_num_employees_ranges: StaticType[]; // ["20001,5000"]
  q_organization_keyword_tags: StaticType[]; // array of company tags
  q_not_organization_keyword_tags: StaticType[]; // array of company tags
  person_locations: OptionType[]; // array of locations
  person_not_locations: OptionType[]; // array of locations
  person_location_name: string; // string of zip (if location radius selected)
  person_location_radius: string; // string of radius (if location radius selected)
  // contact_email_status: string[]; // array of email statuses
  // extrapolated_email_confidence: string[]; // ["75to85"] array of email confidences
}

const Sidebar = () => {
  const toast = useToast();
  let timer: NodeJS.Timeout;
  const [fetchedCompanies, setFetchedCompanies] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<"Filters" | "Lookalikes">(
    "Filters",
  );
  const [inputtedData, setInputtedData] = useState<InputtedData>({
    search_text: "",
    person_titles: [],
    person_not_titles: [],
    person_past_titles: [],
    person_seniorities: [],
    q_person_name: "",
    organization_ids: [], // array of company ids
    not_organization_ids: [], // array of company ids
    person_past_organization_ids: [], // array of company ids
    organization_industry_tag_ids: [], // array of industry ids
    organization_num_employees_ranges: [], // array of employee range ["20001,5000"],
    q_organization_keyword_tags: [], // array of company tags
    q_not_organization_keyword_tags: [], // array of company tags
    person_locations: [], // array of locations
    person_not_locations: [], // array of locations
    person_location_name: "", // string of zip (if location radius selected)
    person_location_radius: "", // string of radius (if location radius selected)
  });

  const updateLeadState = useSearchLeadsStore((state) => state.updateState);
  const isSearching = useSearchLeadsStore((state) => state.isSearching);

  const { mutateAsync } = useMutation({
    mutationFn: ({ payload }: { payload: SearchCompanyType }) =>
      leadService.searchCompany(payload),
  });

  const { mutateAsync: searchPeopleMutateAsync } = usePeopleSearch();

  const calculateAppliedFiltersCount = (data: InputtedData) => {
    let totalLength = 0;

    for (const key in data) {
      if (key !== "company_names" && key !== "fetchedCompanies") {
        // @ts-ignore
        const val = data[key];
        if (Array.isArray(val)) {
          totalLength += val.length;
        } else if (typeof val === "string") {
          totalLength += val.length > 0 ? 1 : 0;
        }
      }
    }

    return totalLength;
  };

  const totalFilters = useMemo(
    () => calculateAppliedFiltersCount(inputtedData),
    [inputtedData],
  );

  const handleCompanySearch = async (companyName: string) => {
    // Clear previous timer if it exists
    clearTimeout(timer);

    // Set a new timer to call the API after 0.5s of user inactivity
    timer = setTimeout(async () => {
      await mutateAsync(
        {
          payload: {
            q_organization_name: companyName,
            per_page: 50,
          },
        },
        {
          onSuccess: (response) => {
            // Update the state
            setFetchedCompanies(response?.data.companies || []);
          },
          onError: (error) => {
            console.log(error);
          },
        },
      );
    }, 500);
  };

  const handleSearch = useCallback(
    async (inputtedData: InputtedData) => {
      const convertedInputtedData = {
        person_titles: inputtedData.person_titles.map((title) => title?.label),
        person_not_titles: inputtedData.person_not_titles.map(
          (title) => title?.label,
        ),
        person_past_titles: inputtedData.person_past_titles.map(
          (title) => title?.label,
        ),
        organization_ids: inputtedData.organization_ids.map(
          (company: any) => company?.organization_id || company?.id,
        ),
        not_organization_ids: inputtedData.not_organization_ids.map(
          (company: any) => company?.organization_id,
        ),
        person_past_organization_ids:
          inputtedData.person_past_organization_ids.map(
            (company: any) => company?.organization_id,
          ),
        organization_num_employees_ranges:
          inputtedData.organization_num_employees_ranges.map((size) =>
            size?.name?.replace("-", ","),
          ),
        q_organization_keyword_tags:
          inputtedData.q_organization_keyword_tags.map((tag) => tag?.name),
        q_not_organization_keyword_tags:
          inputtedData.q_not_organization_keyword_tags.map((tag) => tag?.name),
        person_locations: inputtedData.person_locations.map(
          (location) => location?.label,
        ),
        person_not_locations: inputtedData.person_not_locations.map(
          (location) => location?.label,
        ),
        q_person_name: inputtedData.q_person_name,
        q_keywords: inputtedData.search_text,
      } as any;

      const validInputtedData = filterValidObjKeys(convertedInputtedData);

      if (Object.keys(validInputtedData || {}).length === 0) {
        toast({
          title: "No data to search",
          description: "Please input some data to search",
          status: "error",
          isClosable: true,
          duration: 2000,
          position: "top-right",
        });
        return;
      }

      updateLeadState({
        isSearching: true,
      });
      await searchPeopleMutateAsync({
        payload: validInputtedData,
      });
    },
    [searchPeopleMutateAsync, updateLeadState, toast],
  );

  useEffect(() => {
    const peopleSearchPayload = window.peopleSearchPayload;

    if (peopleSearchPayload) {
      if (
        peopleSearchPayload.fetchedCompanies ||
        peopleSearchPayload.excludeFetchedCompanies
      ) {
        setFetchedCompanies(
          peopleSearchPayload.fetchedCompanies ||
            peopleSearchPayload.excludeFetchedCompanies ||
            [],
        );
        setInputtedData((prevState) => ({
          ...prevState,
          organization_ids:
            peopleSearchPayload.fetchedCompanies?.slice(0, 1) || [],
          not_organization_ids:
            peopleSearchPayload.excludeFetchedCompanies?.slice(0, 1) || [],
        }));
      }

      const newInputData = {} as any;
      Object.entries(peopleSearchPayload).forEach(([key, value]: any) => {
        if (key === "organization_num_employees_ranges") {
          newInputData[key] = value?.map((item: any) => ({
            name: item.replace(",", "-"),
          }));
        } else {
          newInputData[key] = value?.map((item: any) => ({
            label: item,
            value: item,
          }));
        }
      });

      setInputtedData((prevState) => {
        const newState = {
          ...prevState,
          ...newInputData,
        };
        handleSearch(newState);
        return newState;
      });
    }
    window.peopleSearchPayload = null;
  }, [handleSearch]);

  return (
    <div className="flex size-full flex-col justify-between">
      {/* Top section */}
      <div className="flex h-fit w-full gap-[16px] px-[16px] pt-[16px] text-[14px] font-medium leading-[20px]">
        <div
          onClick={() => setActiveTab("Filters")}
          className={`flex w-[100%] cursor-pointer items-center justify-center px-[16px] pb-[16px] ${activeTab === "Filters" && "border-b-2 border-b-[#C03EDC]"}`}
        >
          <div className="flex items-center gap-3">
            <img src={FilterIcon} alt="" />
            <p>Filters</p>
            {totalFilters > 0 && (
              <div className="flex size-[18px] items-center justify-center rounded-full bg-[#5024DC] text-white">
                <span className="text-[12px] font-medium">{totalFilters}</span>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Filters Tab */}
      <div className="h-[5rem] grow  space-y-4 overflow-y-auto bg-white px-2 py-4">
        <>
          <InputGroup className="rounded-md">
            <InputLeftElement pointerEvents="none">
              <Icons.SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              type="text"
              rounded="md"
              placeholder="Search People..."
              value={inputtedData.search_text}
              onChange={(e) => {
                setInputtedData((prevState) => ({
                  ...prevState,
                  search_text: e.target.value,
                }));
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch(inputtedData);
                }
              }}
            />
          </InputGroup>
          <SearchInputAccordion
            defaultIndex={0}
            button={
              <AccordionButton icon={<img src={JobTitleIcon} />}>
                Job Titles
              </AccordionButton>
            }
          >
            <div className="space-y-3">
              <div>
                <p className="mb-2 text-[14px] font-[600] text-[#1a202c]">
                  Job Titles to include
                </p>
                <MultiSelectDropdown
                  placeholder="e.g. CEO, CTO"
                  options={jobTitles}
                  onChange={(selectedValues) => {
                    handlePushToArray(
                      { name: "person_titles", value: selectedValues },
                      inputtedData,
                      setInputtedData,
                    );
                  }}
                  isCreateAble
                  value={inputtedData?.person_titles}
                />
              </div>
              <div>
                <p className="mb-2 text-[14px] font-[600] text-[#1a202c]">
                  Job Titles to exclude
                </p>
                <MultiSelectDropdown
                  placeholder="e.g. Intern, Manager"
                  options={jobTitles}
                  onChange={(selectedValues) => {
                    handlePushToArray(
                      { name: "person_not_titles", value: selectedValues },
                      inputtedData,
                      setInputtedData,
                    );
                  }}
                  isCreateAble
                  value={inputtedData?.person_not_titles}
                />
              </div>
              <div>
                <p className="mb-2 text-[14px] font-[600] text-[#1a202c]">
                  Past Job Titles to include
                </p>
                <MultiSelectDropdown
                  placeholder="e.g. Intern, Manager"
                  options={jobTitles}
                  onChange={(selectedValues) => {
                    handlePushToArray(
                      { name: "person_past_titles", value: selectedValues },
                      inputtedData,
                      setInputtedData,
                    );
                  }}
                  isCreateAble
                  value={inputtedData?.person_past_titles}
                />
              </div>
            </div>
          </SearchInputAccordion>
          <SearchInputAccordion
            defaultIndex={0}
            button={
              <AccordionButton icon={<img src={CompanyIcon} />}>
                Company
              </AccordionButton>
            }
          >
            <div className="space-y-3">
              <PersanaInput
                value={inputtedData?.organization_ids}
                placeholder={"e.g. Google, Facebook, Apple"}
                name={"organization_ids"}
                label={"Companies to include"}
                showDropdown
                isDynamic
                arrayOfValues={fetchedCompanies}
                onChange={(target) => {
                  handleCompanySearch(target.value);
                }}
                onClick={(target) => {
                  console.log("target", target);

                  handlePushToArray(target, inputtedData, setInputtedData);
                }}
              />
              <PersanaInput
                value={inputtedData?.not_organization_ids}
                placeholder={"e.g. Google, Facebook, Apple"}
                name={"not_organization_ids"}
                label={"Companies to exclude"}
                showDropdown
                isDynamic
                arrayOfValues={fetchedCompanies}
                onChange={(target) => {
                  handleCompanySearch(target.value);
                }}
                onClick={(target) => {
                  handlePushToArray(target, inputtedData, setInputtedData);
                }}
              />
              <PersanaInput
                value={inputtedData?.person_past_organization_ids}
                placeholder={"e.g. Google, Facebook, Apple"}
                name={"person_past_organization_ids"}
                label={"Past Companies to include"}
                showDropdown
                arrayOfValues={fetchedCompanies}
                onChange={(target) => {
                  handleCompanySearch(target.value);
                }}
                onClick={(target) => {
                  handlePushToArray(target, inputtedData, setInputtedData);
                }}
              />
              <PersanaInput
                value={inputtedData?.organization_num_employees_ranges}
                placeholder={"e.g. 11-20 , 101-200"}
                name={"organization_num_employees_ranges"}
                label={"Number of Employees"}
                showDropdown
                disabledCustomSelect
                arrayOfValues={apolloCompanySize}
                onClick={(target) => {
                  handlePushToArray(target, inputtedData, setInputtedData);
                }}
              />
              <PersanaInput
                value={inputtedData?.q_organization_keyword_tags}
                placeholder={"e.g. Cloud, AWS"}
                name={"q_organization_keyword_tags"}
                label={"Keywords to Include"}
                showDropdown
                arrayOfValues={[]}
                onClick={(target) => {
                  handlePushToArray(target, inputtedData, setInputtedData);
                }}
              />
              <PersanaInput
                value={inputtedData?.q_not_organization_keyword_tags}
                placeholder={"e.g. Cloud, AWS"}
                name={"q_not_organization_keyword_tags"}
                label={"Keywords to exclude"}
                showDropdown
                arrayOfValues={[]}
                onClick={(target) => {
                  handlePushToArray(target, inputtedData, setInputtedData);
                }}
              />
            </div>
          </SearchInputAccordion>
          <SearchInputAccordion
            defaultIndex={0}
            button={
              <AccordionButton icon={<img src={LocationIcon} />}>
                Location
              </AccordionButton>
            }
          >
            <div className="space-y-3">
              <div>
                <p className="mb-2 text-[14px] font-[600] text-[#1a202c]">
                  City / State / Country or Any Location
                </p>
                <MultiSelectDropdown
                  placeholder="e.g. San Francisco, United States"
                  options={extractNameAndContinent(countries)}
                  onChange={(selectedValues) => {
                    handlePushToArray(
                      { name: "person_locations", value: selectedValues },
                      inputtedData,
                      setInputtedData,
                    );
                  }}
                  isCreateAble
                  value={inputtedData?.person_locations}
                />
              </div>
              <div>
                <p className="mb-2 text-[14px] font-[600] text-[#1a202c]">
                  Location to exclude
                </p>
                <MultiSelectDropdown
                  placeholder="e.g. San Francisco, United States"
                  options={extractNameAndContinent(countries)}
                  onChange={(selectedValues) => {
                    handlePushToArray(
                      { name: "person_not_locations", value: selectedValues },
                      inputtedData,
                      setInputtedData,
                    );
                  }}
                  isCreateAble
                  value={inputtedData?.person_not_locations}
                />
              </div>
            </div>
          </SearchInputAccordion>
          <SearchInputAccordion
            button={
              <AccordionButton icon={<img src={NameIcon} />}>
                Name
              </AccordionButton>
            }
          >
            <div className="space-y-3">
              <PersanaInput
                value={inputtedData?.q_person_name}
                placeholder={"e.g. John Doe"}
                name={"q_person_name"}
                label={"Person Name"}
                onChange={(e) => {
                  setInputtedData((prevState) => ({
                    ...prevState,
                    q_person_name: e.target.value,
                  }));
                }}
              />
            </div>
          </SearchInputAccordion>
        </>
      </div>
      <div className="p-4 !font-title">
        <Button
          isLoading={isSearching}
          id={generateId("persana-search-button")}
          loadingText="Searching"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          className={`!w-full !rounded-[6px] !bg-[#5024DC] !px-4 !py-[15px] !font-title !text-sm !font-semibold !tracking-wide !text-white`}
          onClick={() => {
            handleSearch(inputtedData);
          }}
        >
          Search
        </Button>
      </div>
    </div>
  );
};

export default Sidebar;
