import { Button, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";
import { FormEvent, SetStateAction, useState } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Swal from "sweetalert2";

import CheckEmailModal from "@/components/CheckEmailModal";
import CommonCarousel from "@/components/Common/Carousel";

import PersanaLogo from "@/assets/svgs/persana-logo.svg";
import Icons from "@/components/Icons";

import userService from "@/services/user.service";
import { CarouselDataData } from "./constants";

const Login = () => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const navigate = useNavigate();
  const [checkEmailModalOpen, setCheckEmailModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userInput, setUserInput] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<any>("");

  const handleChange = (e: { target: { value: SetStateAction<string> } }) => {
    setErrorMessage("");
    setUserInput(e.target.value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    if (!emailRegex.test(userInput)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }
    setIsLoading(true);
    e.preventDefault();
    const data = (await userService.getOtp(userInput)) as any;

    if (data?.data?.blocked) {
      //@ts-ignore
      navigate({ to: "/closed-beta" });
    }
    if (data.success) {
      setCheckEmailModalOpen(true);
    } else {
      setErrorMessage(data?.data?.message);
      if (data?.data?.notAllowed) {
      }
      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: data?.isSpam
          ? data?.message
          : `
        If you would like to use Persana with a ${data?.data?.domain} account,
        please email <b><a style="color:#0284fe;" href="mailto:sriya@persana.ai">sriya@persana.ai</a></b> to request access.
      `,
        focusConfirm: true,
      });
      localStorage.clear();
    }
    setIsLoading(false);
  };

  return (
    <section className="flex !h-[100vh] w-full flex-col lg:flex-row">
      <div className=" order-2 flex w-full flex-col justify-center gap-6 bg-[url('/src/assets/images/login-bg.png')] bg-cover bg-no-repeat py-1 lg:order-1 lg:w-[40%]">
        <div className="slider-container !flex  !max-w-64 !flex-col !justify-center self-center !text-center sm:!max-w-96	">
          <CommonCarousel CommonCarouselData={CarouselDataData} />
        </div>
      </div>
      <div className="text-primary-text order-1 flex size-full flex-col items-center justify-center  bg-[#F8F0FF] px-4 py-14  shadow-2xl sm:px-12 lg:order-2  lg:w-[60%] lg:rounded-tr-none">
        <div className="custom1230:w-[60%] font-primary w-full lg:w-[80%]">
          <div className="flex flex-col items-center justify-center text-center">
            <img src={PersanaLogo} className="mb-[40px]" alt="logo" />
            <div className="flex flex-col items-center justify-center rounded-[40px] bg-white px-[30px] py-9 text-center md:w-[75%] md:px-[40px] md:py-12">
              <p className="mb-[30px] text-[24px]  font-[700] text-[#222] md:mb-[20px]">
                Welcome to <span className="text-textPurple">Persana</span>
              </p>
              <p className="mb-[25px]  text-center text-[14px] text-sm font-[500] text-[#222] md:mb-[50px] md:text-base">
                Rated <span className="text-textPurple">#1</span> AI Prospecting
                Platform of 2024
              </p>
              <form
                onSubmit={handleSubmit}
                className="flex w-full flex-col gap-[30px]"
              >
                <div>
                  <div className=" !h-14 w-full">
                    <InputGroup className="!h-full w-full">
                      <InputLeftElement
                        pointerEvents="none"
                        className="!h-full"
                      >
                        <Icons.MailIcon className="text-gray-400 !h-full w-5" />
                      </InputLeftElement>
                      <Input
                        type="text"
                        name="email"
                        placeholder="Enter your email here"
                        className={`!h-full w-full rounded-lg border p-3 !text-sm font-medium text-[#272727d8] placeholder:text-[#B4B4B4] focus:outline-none md:!text-base ${errorMessage ? "!border-[#EB5757]" : "!border-[#B4B4B4]"}`}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </div>
                </div>
                <div className="hidden !items-center !justify-center">
                  <p className="cursor-pointer text-sm md:text-base ">
                    Having trouble signing in?
                  </p>
                </div>

                <Button
                  height={"auto"}
                  type="submit"
                  className="h-[60px] !rounded-[8px] !px-[20px] !py-[15px] !text-[18px] font-[600] !text-white"
                  isDisabled={!userInput}
                  isLoading={isLoading}
                  style={{
                    background:
                      "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%)",
                  }}
                >
                  Get Started
                </Button>
                <p className="text-center text-[14px] font-[400] text-[#222]">
                  By logging in, I agree to the Terms of Service Privacy Policy,
                  and AI Content Policy. Please view the policies
                  <a
                    target="_blank"
                    href="https://persana-ai.notion.site/Persana-Help-Center-72626a6f86d64b69a0975fd7de7462c4"
                    className="text-[#693DC7]"
                    rel="noreferrer"
                  >
                    {` here`}
                  </a>
                  .
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>

      <CheckEmailModal
        isOpen={checkEmailModalOpen}
        onClose={() => setCheckEmailModalOpen(false)}
        email={userInput}
      />
    </section>
  );
};

export default Login;
