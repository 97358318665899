import Icons from "@/components/Icons";
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useCreateEmptyFolder } from "../hooks/useTableData";
import { useModalStore } from "@/stores/modal.store";
import { TFolderRow } from "../types";
interface CreateNewProps extends MenuButtonProps {
  newItemRoot?: TFolderRow | null;
}
export const CreateNew = ({ newItemRoot = null, ...props }: CreateNewProps) => {
  const { create } = useCreateEmptyFolder();
  const { updateState: updateModalStore } = useModalStore();
  return (
    <Box className=" relative mb-1 flex w-full justify-end">
      <Menu variant={"persana"}>
        <MenuButton
          variant={"outline"}
          size={"sm"}
          fontWeight={"normal"}
          as={Button}
          leftIcon={<Icons.PlusIcon />}
          rightIcon={<Icons.ChevronDownIcon />}
          {...props}
        >
          Create New
        </MenuButton>
        <MenuList zIndex={"dropdown"}>
          <MenuItem
            icon={<Icons.FolderClosedIcon />}
            onClick={() => create(newItemRoot)}
          >
            Create Folder
          </MenuItem>
          <MenuItem
            icon={<Icons.ViewTableIcon />}
            onClick={() =>
              updateModalStore({
                isOpenModal: true,
                modalData: { modalType: "get-started" },
              })
            }
          >
            Create Table
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};
