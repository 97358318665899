import MakeAPICall from "@/lib/apiCalls";

import {
  type SearchCompanyResponseType,
  type SearchCompanyType,
  type SearchLeadsResponseType,
  type SearchPeopleType,
  type StarterListType,
  HubspotListResponseDataType,
  IScraperCompanySearchPayload,
} from "@/types/leads.types";

class LeadsService {
  async getStarterList() {
    const apiCall = new MakeAPICall({
      apiPath: "leads/starter-list",
    });
    const response = await apiCall.get<StarterListType[]>();
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async createWebhookTable() {
    const apiCall = new MakeAPICall({
      apiPath: "table/blank-table",
      payload: {
        tableName: `Webhook - ${new Date().toISOString()}`,
        columns: [
          {
            name: "Webhook",
            metaData: {
              event: "modal",
              enrichmentMetaData: {
                enrichmentName: "webhook",
              },
            },
          },
        ],
        metaData: {
          isWebhook: true,
        },
      },
    });
    const response = await apiCall.post<{
      success: boolean;
      data?: { tableId: string };
      message?: string;
    }>();
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async uploadCSV(tableId: string, payload: FormData) {
    const apiCall = new MakeAPICall({
      apiPath: `leads/upload-csv/${tableId}`,
      payload,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const response = await apiCall.post<{
      success: boolean;
      message: string;
      data?: { tableId: string };
    }>();
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async searchCompany(payload: SearchCompanyType) {
    const apiCall = new MakeAPICall({
      apiPath: "leads/search-company",
      payload: {
        ...payload,
        page: payload.page || 1,
      },
    });
    const response = await apiCall.post<SearchCompanyResponseType>();
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async searchPeople(payload: SearchPeopleType, apiPath?: string) {
    const apiCall = new MakeAPICall({
      apiPath: apiPath || "leads/search-people",
      payload,
    });
    const response = await apiCall.post<SearchLeadsResponseType>();

    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async addLeadsToTable(payload: SearchPeopleType) {
    const apiCall = new MakeAPICall({
      apiPath: "leads/add-leads-to-table",
      payload: payload,
    });
    const response = await apiCall.post<SearchLeadsResponseType>();

    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async searchPeopleMixrank(payload: SearchPeopleType) {
    const apiCall = new MakeAPICall({
      apiPath: "leads/search-people-mixrank",
      payload: payload,
    });
    const response = await apiCall.post<any>();
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async searchCompanyIscraper(
    payload: IScraperCompanySearchPayload,
    apiPath?: string,
  ) {
    const apiCall = new MakeAPICall({
      apiPath: apiPath || "leads/search-company-linkedin",
      payload: payload,
    });
    const response = await apiCall.post<SearchLeadsResponseType>();
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async getLeadSearchHistory(searchType: any) {
    const apiCall = new MakeAPICall({
      apiPath: `leads/search-history/${searchType}`,
    });
    const response = await apiCall.get<any[]>();
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async getHubspotLists() {
    const apiCall = new MakeAPICall({
      apiPath: `leads/hubspot-list`,
    });
    const response = await apiCall.get<HubspotListResponseDataType>();
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async deleteLeadSearchHistory(id: string, isDeleteRecords?: boolean) {
    const apiCall = new MakeAPICall({
      apiPath: `leads/search-history/${id}`,
      params: { isDeleteRecords },
    });
    const response = await apiCall.delete();
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async searchPeopleUsingGPT({
    context,
    prompt,
  }: {
    context?: string;
    prompt: string;
  }) {
    const apiCall = new MakeAPICall({
      apiPath: "leads/search-people-using-gpt",
      payload: { prompt, context },
    });
    const response = await apiCall.post();
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }
}

const leadService = new LeadsService();
export default leadService;
