import { useEffect, useMemo, useState } from "react";

import WatchTutorial from "@/components/Common/WatchTutorial";
import ConditionalFormula from "../../Common/ConditionalFormula";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";

import KeySelect from "@/components/Common/KeySelect";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import { useEnrichStore } from "@/stores/enrich.store";
import { usePresetsStore } from "@/stores/presets.store";
import SelectableTextEditor from "../../Common/SelectableTextEditor";

const PerplexityAI = () => {
  const models = useMemo(
    () => [
      {
        value: "llama-3-sonar-small-32k-chat",
        label: "llama-3-sonar-small-32k-chat",
      },
      {
        value: "llama-3-sonar-small-32k-online",
        label: "llama-3-sonar-small-32k-online",
      },
      {
        value: "llama-3-sonar-large-32k-chat",
        label: "llama-3-sonar-large-32k-chat",
      },
      {
        value: "llama-3-sonar-large-32k-online",
        label: "llama-3-sonar-large-32k-online",
      },
      {
        value: "llama-3-8b-instruct",
        label: "llama-3-8b-instruct",
      },
      {
        value: "llama-3-70b-instruct",
        label: "llama-3-70b-instruct",
      },
      {
        value: "mixtral-8x7b-instruct",
        label: "mixtral-8x7b-instruct",
      },
    ],
    [],
  );
  const [selectedOption, setSelectedOption] = useState<Option>();
  const updateState = usePresetsStore((state) => state.updateState);
  const selectedPresetValues = usePresetsStore(
    (state) => state.selectedPresetValues,
  );
  const viewMode = useEnrichStore((state) => state.viewMode);
  const updateEnrichmentState = useEnrichStore((state) => state.updateState);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as any;

      if (enrichmentData) {
        updateState({
          selectedPresetValues: enrichmentData.prompts || "",
        });
        updateEnrichmentState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
          keySelect: enrichmentData.isUseOwnKey ? "ownApiKey" : "persanaApiKey",
        });

        if (enrichmentData.model) {
          const model = models.find(
            (model) => model.value === enrichmentData.model,
          );
          setSelectedOption(model || { value: "", label: "" });
        }
      }
    }
  }, [selectedColumnToEdit, viewMode]);

  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <WatchTutorial
          subText="Get the up-to-date, and factual responses to all your questions using Perplexity AI"
          link={""}
        />
        <KeySelect
          providerName={"perplexityApiKey"}
          providerTitle="Perplexity"
        />
        <div className="py-2">
          <p className="pb-1 text-base font-semibold">Select Model</p>
          <MultiSelectDropdown
            options={models}
            onChange={(selectedValues) => {
              setSelectedOption(selectedValues as Option);
            }}
            value={selectedOption}
            isMulti={false}
            isCreateAble={false}
            closeMenuOnSelect
          />
        </div>
        <div>
          <SelectableTextEditor
            title="Write your query here"
            text={selectedPresetValues || ""}
            setText={(newInputText: any) => {
              updateState({
                selectedPresetValues: newInputText,
              });
            }}
            rows={8}
            callFrom="persana-agent-2"
            placeholder="In which locations across Asia does the /company have a presence?"
          />
        </div>
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedOption?.value || !selectedPresetValues}
        apiPayload={{
          model: selectedOption?.value,
          prompts: selectedPresetValues,
          apiKeyName: "perplexityApiKey",
        }}
      />
    </>
  );
};

export default PerplexityAI;
