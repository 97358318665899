import { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Input,
  Select,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";
import { useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";

import TabHeader from "./TabHeader";

import userService from "@/services/user.service";
import { userStore } from "@/stores/user.store";

type Props = {
  callFrom: string;
  token: string;
  redirectUrl: string;
  onSubmit: (redirectUrl: string) => void;
};

const OnboardingForm = ({ callFrom, token, redirectUrl, onSubmit }: Props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const currentUser = userStore((state) => state.currentUser);
  const [currentTab, setCurrentTab] = useState(1);
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    jobTitle: "",
    companyURL: "https://",
    companyDescription: "",
    assistYouWith: "",
    companyTarget: "",
  });

  const aboutDetailsFilled =
    userInfo.firstName && userInfo.jobTitle && userInfo.companyURL
      ? true
      : false;
  const allRequiredDetailsFilled =
    aboutDetailsFilled && userInfo.companyDescription ? true : false;

  const handleUserInfo = (key: string, value: string) => {
    setUserInfo((prevInfo) => ({ ...prevInfo, [key]: value }));
  };

  const handleAnswer = async () => {
    try {
      const response = await userService.updateUserProfileData(
        `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/${
          callFrom === "screen"
            ? `updateProfileInfoByToken/${token}`
            : "updateProfileInfo"
        }`,
        currentUser?.Email,
        userInfo,
      );
      if (response?.success) {
        await queryClient.invalidateQueries({
          queryKey: ["user-details"],
        });
        callFrom !== "screen" && navigate({ to: "/" });
        redirectUrl && onSubmit(redirectUrl);
      }
    } catch (err) {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const handleUpdate = async () => {
        if (userInfo.companyDescription !== "") {
          return;
        }
        const response = await userService.generateUserCompanyDescription(
          userInfo.companyURL,
        );
        if (response?.success && response?.data?.updated.description) {
          handleUserInfo(
            "companyDescription",
            response?.data?.updated.description,
          );
        }
      };
      if (userInfo.companyURL !== "https://" && userInfo.companyURL !== "") {
        handleUpdate();
      }
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [userInfo.companyURL]);

  return (
    <div className="p-[20px] w-full mx-auto">
      <Box className="p-4">
        <Box className="mb-4">
          <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={4}>
            <TabHeader
              label="About You"
              isActive={true}
              isCompleted={aboutDetailsFilled}
            />
            <TabHeader
              label="Details"
              isActive={currentTab === 2}
              isCompleted={allRequiredDetailsFilled}
            />
          </Box>
        </Box>

        {currentTab === 1 ? (
          <VStack spacing={4}>
            <Box className="w-full">
              <label htmlFor="firstName" className="text-sm text-[#4c4c4c]">
                Name *
              </label>
              <Input
                type="text"
                name="firstName"
                value={userInfo.firstName}
                placeholder="Name"
                fontFamily="Inter, sans-serif"
                onChange={(e) => handleUserInfo("firstName", e.target.value)}
              />
            </Box>
            <Box className="w-full">
              <label htmlFor="jobTitle" className="text-sm text-[#4c4c4c]">
                Job Title *
              </label>
              <Input
                type="text"
                name="jobTitle"
                value={userInfo.jobTitle}
                placeholder="Job Title"
                fontFamily="Inter, sans-serif"
                onChange={(e) => handleUserInfo("jobTitle", e.target.value)}
              />
            </Box>
            <Box className="w-full">
              <label htmlFor="companyURL" className="text-sm text-[#4c4c4c]">
                Company URL *
              </label>
              <Input
                type="text"
                name="companyURL"
                value={userInfo.companyURL}
                placeholder="Company URL"
                defaultValue="https://"
                fontFamily="Inter, sans-serif"
                onChange={(e) => handleUserInfo("companyURL", e.target.value)}
              />
            </Box>
          </VStack>
        ) : (
          <VStack spacing={4}>
            <Box className="w-full">
              <label
                htmlFor="companyDescription"
                className="text-sm text-[#4c4c4c]"
              >
                What does your company do? *
              </label>
              <Textarea
                resize={"none"}
                rows={5}
                value={userInfo.companyDescription}
                name="companyDescription"
                placeholder="What does your company do?"
                fontFamily="Inter, sans-serif"
                onChange={(e) =>
                  handleUserInfo("companyDescription", e.target.value)
                }
              />
            </Box>
            <Box className="w-full">
              <label className="text-sm text-[#4c4c4c]">
                What can we assist you with?
              </label>
              <Select
                placeholder="Select..."
                value={userInfo.assistYouWith}
                onChange={(e) =>
                  handleUserInfo("assistYouWith", e.target.value)
                }
              >
                <option value="Explore">Explore</option>
                <option value="Lead Enrichment">Lead Enrichment</option>
                <option value="Generate Emails">Generate Emails</option>
                <option value="Help Book More Calls">
                  Help Book More Calls
                </option>
                <option value="Other">Other</option>
              </Select>
            </Box>
            <Box className="w-full">
              <label htmlFor="companyTarget" className="text-sm text-[#4c4c4c]">
                Who do you sell to?
              </label>
              <Input
                type="text"
                name="companyTarget"
                value={userInfo.companyTarget}
                placeholder="Who do you sell to?"
                fontFamily="Inter, sans-serif"
                onChange={(e) =>
                  handleUserInfo("companyTarget", e.target.value)
                }
              />
            </Box>
          </VStack>
        )}
        <Box className="mt-4 w-full">
          {currentTab === 1 ? (
            <Button
              className="w-full flex h-[48px] items-center rounded-md !bg-primary-gradient px-[28px] py-[8px] text-[16px] font-semibold !text-white hover:!shadow-md"
              fontFamily="Inter, sans-serif"
              onClick={() => setCurrentTab(2)}
              isDisabled={!aboutDetailsFilled}
            >
              Continue
            </Button>
          ) : (
            <ButtonGroup className="w-full">
              <Button
                className="w-full"
                variant="outline"
                fontFamily="Inter, sans-serif"
                onClick={() => setCurrentTab(1)}
              >
                Previous
              </Button>
              <Button
                className="w-full flex h-[48px] items-center rounded-md !bg-primary-gradient px-[28px] py-[8px] text-[16px] font-semibold !text-white hover:!shadow-md"
                fontFamily="Inter, sans-serif"
                isDisabled={!allRequiredDetailsFilled}
                onClick={handleAnswer}
              >
                Get Started
              </Button>
            </ButtonGroup>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default OnboardingForm;
