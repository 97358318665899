import { useState } from "react";

import Icons from "../Icons";

interface Props {
  title: string;
  children: React.ReactNode;
  titleClassName?: string;
  isDefaultOpen?: boolean;
}

const CustomAccordion = ({
  title,
  children,
  titleClassName = "",
  isDefaultOpen = false,
}: Props) => {
  const [accordionOpen, setAccordionOpen] = useState(isDefaultOpen);

  return (
    <div className="mt-4">
      <button
        className={`flex w-full items-center space-x-1 rounded-md text-left font-semibold ${titleClassName} text-[0.9rem]`}
        onClick={(e) => {
          e.preventDefault();
          setAccordionOpen(!accordionOpen);
        }}
        aria-expanded={accordionOpen}
        aria-controls={`accordion-text-01`}
      >
        <Icons.CircleArrowIcon
          className={`ttransform shrink-0 origin-center fill-primaryPink text-base transition duration-200 ease-out ${
            !accordionOpen ? "-rotate-90" : ""
          }`}
        />
        <span className="w-full">{title}</span>
      </button>
      <div
        id={`accordion-text-01`}
        role="region"
        aria-labelledby={`accordion-title-01`}
        className={`${
          accordionOpen ? "block" : "hidden"
        } text-gray-500 mt-2 text-sm`}
      >
        <div>{children}</div>
      </div>
    </div>
  );
};

export default CustomAccordion;
