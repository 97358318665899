import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useState } from "react";

import Icons from "../Icons";
import { useEnrichStore } from "@/stores/enrich.store";

interface Props {
  subText: string;
  link: string;
}

const WatchTutorial = ({ subText, link }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const selectedEnrichments = useEnrichStore(
    (state) => state.selectedEnrichments,
  );

  return (
    <>
      <div>
        <div className="flex items-center justify-between">
          <p className="pb-1.5 text-sm text-[#000000]/90">{subText}</p>
          <div className="flex items-center justify-center gap-[4px] rounded-[4px] bg-[#e6fce9] px-[4px] py-[2px] text-[#2bbf71]">
            <span className="text-[12px] font-[500] uppercase">
              {selectedEnrichments?.credits}
            </span>
            <Icons.CoinsIcon className="text-sm text-[#2bbf71]" />
          </div>
        </div>
        <button
          className={`mb-4 flex items-center gap-x-2 rounded-full  px-2.5 py-1 text-xs font-medium text-white ${
            link
              ? "cursor-pointer bg-primary"
              : "cursor-not-allowed bg-lightGray"
          }`}
          onClick={() => {
            if (link) {
              setIsOpen(true);
            }
          }}
        >
          <Icons.PlayIcon />
          <span>Watch Tutorial</span>
        </button>
      </div>
      <Modal
        isCentered
        isOpen={isOpen}
        size="3xl"
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <iframe
              src={`${link}?autoplay=1&mute=1`}
              title="YouTube video player"
              className="min-h-[400px] w-full"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default React.memo(WatchTutorial);
