import { v4 as uuidv4 } from "uuid";

import { useSearchLeadsStore } from "@/stores/searchleads.store";
import SearchLeadsResultTable from "../Common/SearchLeadsResultTable";
import { getNestedObjValue } from "@/utils";

type Props = {
  isSearching: boolean;
  currentSearchData: any;
  onCloseModal: (closeAll?: boolean) => void;
};

const SearchLinkedInComapnyResultIscraper = ({
  isSearching,
  currentSearchData,
  onCloseModal,
}: Props) => {
  const { searchLeadsResult: searchPeopleResult, updateState } =
    useSearchLeadsStore();

  const inputType = {
    eventType: "input",
    iconType: "text",
    event: "doubleClick",
    selectAble: true,
  };

  const getFormattedColumnRows = (columns: any, rowsToFormat: any) => {
    const formattedData = columns?.map((item: any) => {
      const rows = rowsToFormat?.map((rowItem: any) => {
        const value = getNestedObjValue(rowItem, item.path);

        if (item.action === "join") {
          return {
            value: value?.join(", ") || "",
          };
        }

        return {
          value: value || "",
        };
      });

      return {
        colId: item.id,
        rows,
      };
    });

    return formattedData;
  };

  const generateInitialResultColumns = () => {
    const columnsMapping = searchPeopleResult?.columsMapping || [];

    const idAddedColumNames = columnsMapping.map((item) => {
      return {
        id: uuidv4(),
        value: item?.name,
        path: item?.path,
        ...inputType,
      };
    });

    updateState({
      searchLeadsColumnNames: idAddedColumNames,
    });

    if (searchPeopleResult && searchPeopleResult?.profiles?.length > 0) {
      const columnRows = getFormattedColumnRows(
        idAddedColumNames,
        searchPeopleResult?.profiles,
      );

      updateState({ searchLeadsResultRows: columnRows });
    }
  };

  return (
    <SearchLeadsResultTable
      isSearching={isSearching}
      currentSearchData={currentSearchData}
      getFormattedColumnRows={getFormattedColumnRows}
      generateInitialResultTable={generateInitialResultColumns}
      onCloseModal={onCloseModal}
    />
  );
};

export default SearchLinkedInComapnyResultIscraper;
