/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef } from "react";
import { Socket, io } from "socket.io-client";

const serverUrl = `${import.meta.env.VITE_BE_BASE_URL}`;

const useSocket = () => {
  const socketRef = useRef<Socket | null>(null);

  const connect = () => {
    if (!socketRef.current) {
      socketRef.current = io(serverUrl, {
        withCredentials: true,
        transports: ["websocket"],
        path: "/websocket",
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
      });
      socketRef.current.on("connect", () => {
        console.log("Connected to server");
      });
      socketRef.current.on("disconnect", () => {
        console.log("Disconnected from server");
      });
    }
  };

  const disconnect = () => {
    socketRef.current?.disconnect();
    socketRef.current = null;
  };
  useEffect(() => {
    return () => {
      disconnect();
    };
  }, []);

  const send = (eventName: string, data: any) => {
    socketRef.current?.emit(eventName, data);
  };

  const subscribe = (eventName: string, callback: (data: any) => void) => {
    socketRef.current?.on(eventName, (_data, ack) => {
      callback(_data);
      ack(true);
    });

    return () => {
      socketRef.current?.off(eventName, (_data) => {
        callback(_data);
      });
    };
  };

  return { connect, disconnect, send, subscribe };
};

export default useSocket;
