import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { PriceBox } from "./PriceBox";

import logo from "@/assets/images/logoWithText.png";
import TailwindModalLayout from "@/components/Common/TailwindModalLayout";
import Icons from "@/components/Icons";
import { getCurrentDateInYYYYMM } from "@/lib/utils";
import userService from "@/services/user.service";
import { useModalStore } from "@/stores/modal.store";
import { PricingType } from "@/types/pricing.types";

const PricingModal = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isOpenModal, modalData, updateState } = useModalStore(
    (state) => state,
  );

  const { mutateAsync } = useMutation({
    mutationFn: ({ priceId }: { priceId: string }) =>
      userService.getSubscriptionLink(priceId),
  });

  const pricings: PricingType[] = [
    {
      title: "Starter",
      description: "Best for individuals and recruiters",
      credits: 2000,
      price: 85,
      priceBeforeDiscount: 170,
      frequency: "month",
      features: [
        "Create unlimited tables",
        "Unlimited users",
        "50+ Bulk enrichment",
        "Share workspace",
        "Use your own API keys",
        "Prompt library",
        "AI research agent",
        "Export to CSV",
        "Chrome extension (LinkedIn)",
        "Email responder (Gmail)",
      ],
      priceId: "price_1OsCoTDoimYFMUv0ZvaeGNe3",
      mostPopular: false,
      action: "upgrade",
    },
    {
      title: "Growth",
      credits: 5000,
      frequency: "month",
      description: "Best for early stage startups and growth agencies.",
      price: 189,
      priceBeforeDiscount: 378,
      features: [
        "Everything from Starter",
        "Email tool integration",
        "HTTP API",
        "Webhooks",
        "Company news tracking",
        "Custom Intent Scoring",
      ],
      priceId: "price_1OsCpwDoimYFMUv0cmPW9nrE",
      mostPopular: true,
      action: "upgrade",
    },
    {
      title: "Pro",
      credits: 10000,
      description: "Best for sales teams and growing companies.",
      frequency: "month",
      price: 350,
      priceBeforeDiscount: 700,
      features: [
        "Everything from Growth",
        "CRM Integration",
        "Intent/Triggers",
        "Job Change alerts",
        "Hiring triggers",
        "Priority Support",
      ],
      priceId: "price_1OsCr7DoimYFMUv0wyLVqRgD",
      mostPopular: false,
      action: "upgrade",
    },
    {
      title: "Enterprise",
      price: "Contact us",
      description: "Best for custom plans and higher credits",
      features: [
        "Everything from Pro",
        "Super Priority support",
        "Higher volume of credits",
      ],
      action: "contact",
    },
  ];

  const handleClose = () => {
    updateState({
      isOpenModal: false,
      modalData: { modalType: "" },
    });
  };

  const handleActionBtnClick = async (priceId: string) => {
    if (!priceId) {
      window.open(
        `https://calendly.com/d/2xg-4vb-t3c/persana-ai-onboarding?month=${getCurrentDateInYYYYMM()}`,
        "_blank",
      );
    } else {
      setIsLoading(true);

      await mutateAsync(
        { priceId },
        {
          onError: (error) => {
            console.log("error", error);
            setIsLoading(false);
          },
          onSuccess: (data) => {
            setIsLoading(false);
            const newWindow = window.open();
            if (newWindow) {
              // @ts-ignore
              newWindow.location.href = data?.link;
            }
          },
        },
      );
    }
  };

  return (
    <TailwindModalLayout
      modalOpen={isOpenModal && modalData.modalType === "pricing"}
      setModalOpen={handleClose}
      closeOnOuterClick
      overlayClassName="bg-[rgba(0,_0,_0,_0.18)]"
      dialogClassName="!z-[2000]"
    >
      <div className="relative inline-block h-full overflow-hidden p-4 text-left align-bottom font-title transition-all sm:w-full sm:max-w-[87rem] sm:align-middle">
        <div className="size-full overflow-hidden rounded-[2rem] bg-[rgba(255,_255,_255,_0.4)] p-12 backdrop-blur-[7px]">
          <Icons.FillCloseCircleIcon
            className="absolute right-4 top-4 cursor-pointer text-4xl text-[#693DC7]"
            onClick={handleClose}
          />

          <div className="flex items-center gap-x-3 pb-8">
            <img src={logo} alt="logo" className="w-36" />
            {/* <p className="font-title gradient-text text-xl">
             ( Launch special 50% off for a limited time )
            </p> */}
          </div>
          <div className="grid grid-cols-4 content-center gap-6">
            {pricings.map((pricing, index) => (
              <PriceBox
                key={`pricing-${index}`}
                pricing={pricing}
                onClick={handleActionBtnClick}
                isLoading={isLoading}
              />
            ))}
          </div>
        </div>
      </div>
    </TailwindModalLayout>
  );
};

export default PricingModal;
