import {
  ANY,
  ActiveJobsType,
  LeadEntry,
} from "@/types/accountMonitoring.types";
import { TableDataQueryParams } from "@/types/table.types";
import { FilterModel } from "ag-grid-community";
import MakeAPICall, { APIResponse } from "./../lib/apiCalls";

class AccountMonitoringService {
  constructor(private apiCaller = MakeAPICall) {}

  async quickAdd(url: string, useCase: "job-tracking" | "job-opening") {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/salesforce/quickAdd`,
      payload: {
        url,
        useCase,
      },
    });
    const response = await apiCall.post();
    if (!response.success) {
      throw response;
    }
    return response;
  }

  async getAllCompanies() {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/salesforce/companyEntries`,
    });
    const response = await apiCall.get();
    const formattedRes = Object.values(response)?.map((item) => item);
    return { success: true, data: formattedRes };
  }

  async deleteCompanies(payload: { companies: string[] }) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/salesforce/companies`,
      payload,
    });
    const response = await apiCall.post();
    return response;
  }

  async uploadCompanies(formData: FormData) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/salesforce/hiring/importCsv`,
      payload: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const response = await apiCall.post();
    return response;
  }

  async getJobOpenings(payload: ANY) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/salesforce/jobOpenings`,
      payload,
      timeout: 10000,
    });
    const response = await apiCall.post();
    return response;
  }

  async getAllLeads(params?: Partial<TableDataQueryParams>) {
    const apiCall = new this.apiCaller({
      apiPath: "account-monitoring/job-changes/leads",
      payload: params,
    });
    const response = await apiCall.post<LeadEntry[]>();
    return response.data as unknown as APIResponse<LeadEntry[]>;
  }
  async getAllLeadCompaniesOptions(): Promise<
    APIResponse<{ id: string; value: string; logo?: string }[]>
  > {
    const apiCall = new this.apiCaller({
      apiPath: "account-monitoring/job-changes/lead/filter/companies",
    });
    const response = await apiCall.get();
    return response;
  }
  async getHiringCompaniesOptions(): Promise<
    APIResponse<{ id: string; value: string; logo?: string }[]>
  > {
    const apiCall = new this.apiCaller({
      apiPath: "account-monitoring/get-active-jobs/filter/companies",
    });
    const response = await apiCall.get();
    return response;
  }
  async deleteLeads(payload: {
    leads: string[] | "all";
    filters?: FilterModel | null;
  }) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/salesforce/leads`,
      payload,
    });
    const response = await apiCall.post();
    if (response.data.success) {
      return response;
    } else {
      throw response;
    }
  }

  async uploadLeads(payload: FormData) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/salesforce/importCsv`,
      payload,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const response = await apiCall.post();
    return response;
  }

  async getJobChanges(payload: ANY) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/salesforce/jobChanges`,
      payload,
      timeout: 10000,
    });
    const response = await apiCall.post();
    return response;
  }

  async importDataFromHubspot(apiPath: string, numberOfLeads: number) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/${apiPath}`,
      payload: {
        numberOfLeads,
      },
      timeout: 10000,
    });
    const response = await apiCall.post();
    return response;
  }

  async hubspotContactImport(numberOfLeads: number, listId: string) {
    const apiCall = new this.apiCaller({
      apiPath: `leads/import-contacts-hubspot`,
      payload: {
        maxImport: numberOfLeads,
        listId,
      },
      timeout: 10000,
    });
    const response = await apiCall.post();
    return response;
  }

  async getAllActiveJobs(payload: TableDataQueryParams) {
    const apiCall = new this.apiCaller({
      apiPath: "account-monitoring/get-active-jobs",
      payload,
    });
    const response = await apiCall.post();
    return response.data as unknown as APIResponse<ActiveJobsType[]>;
  }

  async deleteActiveJobs(payload: {
    jobs: string[] | "all";
    filters?: FilterModel | null;
  }) {
    const apiCall = new MakeAPICall({
      apiPath: "account-monitoring/delete-active-jobs",
      payload,
    });
    const response = await apiCall.post();
    return response;
  }

  async trackActiveJobs(payload: { query: string; location: string }) {
    const apiCall = new MakeAPICall({
      apiPath: "account-monitoring/track-active-jobs",
      payload,
    });
    const response = await apiCall.post();
    return response;
  }

  async createTable(payload: {
    totalRows: number;
    isForAll?: boolean;
    monitoringType: string;
    tableName?: string;
    selectedRowsIds: string[];
    filter?: FilterModel | null;
    sortingKey?: string;
    sortOrder?: string;
  }) {
    const apiCall = new this.apiCaller({
      apiPath: "account-monitoring/add-into-table",
      payload,
    });
    const response = await apiCall.post();
    return response;
  }

  async trackJobChanges(payload: {
    isForAll: boolean;
    selectedIds?: string[];
  }) {
    const apiCall = new this.apiCaller({
      apiPath: "account-monitoring/job-changes/track",
      payload,
    });
    const response = await apiCall.post();
    return response;
  }
}

const accountMonitoringService = new AccountMonitoringService();
export default accountMonitoringService;
