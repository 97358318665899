import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import ReactSelect, { components as defaultComponents } from "react-select";
import CreatableSelect from "react-select/creatable";

import Menu from "./Menu";

import { customStyles } from "./config";
import type { Option } from "./types";

interface Props {
  isCreateAble?: boolean;
  options: Option[];
  htmlNameAttribute?: string;
  placeholder?: string;
  isMulti?: boolean;
  onChange: (selected: Option[] | Option) => void;
  value?: Option[] | Option | null;
  limit?: number;
  closeMenuOnSelect?: boolean;
  isInlined?: boolean;
  isLoading?: boolean;
}

const MultiSelectDropdown = ({
  isCreateAble = true,
  options = [],
  htmlNameAttribute = "",
  isMulti = true,
  onChange,
  value = [],
  placeholder,
  limit = 0,
  closeMenuOnSelect = false,
  isInlined = false,
  isLoading = false,
}: Props) => {
  const toast = useToast();
  const SelectComponent = isCreateAble ? CreatableSelect : ReactSelect;
  const [inputValue, setInputValue] = useState("");

  const Noop = () => null;

  const createOption = (inputValue: string): Option => ({
    label: inputValue,
    value: inputValue,
  });

  const getOptionsWithCreate = (
    inputValue: string,
    options: Option[],
  ): Option[] => {
    if (inputValue && !options.find((option) => option.value === inputValue)) {
      return [createOption(inputValue), ...options];
    }
    return options;
  };

  return (
    <>
      <SelectComponent
        value={value}
        isMulti={isMulti}
        options={
          isCreateAble ? getOptionsWithCreate(inputValue, options) : options
        }
        isClearable
        isLoading={isLoading}
        placeholder={placeholder || "Select items from the dropdown or type"}
        noOptionsMessage={() =>
          isInlined
            ? ""
            : isCreateAble
              ? "Write something and press enter"
              : "No options available"
        }
        menuPortalTarget={document.body}
        components={
          {
            Menu: isInlined ? Noop : Menu,
            DropdownIndicator: isInlined
              ? Noop
              : (props: any) => (
                  <defaultComponents.DropdownIndicator {...props} />
                ),
          } as any
        }
        name={htmlNameAttribute}
        styles={{
          ...(customStyles as any),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Increase z-index
          menu: (base) => ({ ...base, maxHeight: "320px" }),
          placeholder: (base) => ({ ...base, textAlign: "left" }),
        }}
        onMenuClose={() => {
          if (limit > 0 && Array.isArray(value) && value.length > limit) {
            toast({
              position: "top-right",
              title: `You can only select up to ${limit} items.`,
              status: "error",
              duration: 2000,
              isClosable: true,
            });
            return;
          }

          if (inputValue && isCreateAble) {
            if (
              value &&
              Array.isArray(value) &&
              !options.find((item) => item.value === inputValue) &&
              !value?.find((item) => item.value === inputValue)
            ) {
              onChange([...value, createOption(inputValue)]);
            }
          }
        }}
        onInputChange={(input) => setInputValue(input)}
        closeMenuOnSelect={closeMenuOnSelect}
        onChange={(selected) => {
          if (limit > 0 && Array.isArray(selected) && selected.length > limit) {
            toast({
              position: "top-right",
              title: `You can only select up to ${limit} items.`,
              status: "error",
              duration: 2000,
              isClosable: true,
            });
            return;
          }
          if (isMulti) {
            onChange(selected as Option[]);
          } else {
            onChange(selected as Option);
          }
          setInputValue("");
        }}
      />
    </>
  );
};

export default MultiSelectDropdown;
