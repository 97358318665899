import ApiFyLogo from "@/assets/images/apify.png";
import Campaign from "@/assets/svgs/campaign.svg";
import GPT from "@/assets/svgs/enrichment-gpt.svg";
import News from "@/assets/svgs/enrichment-news.svg";
import Verify from "@/assets/svgs/enrichment-verify.svg";
import Icons from "@/components/Icons";

import ImportData from "../../Leads/ImportData";
import ApiFy from "./Apify";
import AskAQuestionAiAgent from "./AskAQuestionAiAgent";
import CapterraScrape from "./CapterraScrape";
import CompanyDetails from "./CompanyDetails";
import CompanyGrowth from "./CompanyGrowth";
import CompanyNews from "./CompanyNews";
import CompanyTechnologies from "./CompanyTechnologies";
import CreateLeadLGM from "./CreateLeadLGM";
import EnrichProfileByMixrank from "./EnrichProfileByMixrank";
import FindLinkedInUrl from "./FindLinkedInUrl";
import FindMobileNumber from "./FindMobileNumber";
import FindNewsResults from "./FindNewsResults";
import FindPeopleAtCompany from "./FindPeopleAtCompany";
import FindPeopleAtCompany2 from "./FindPeopleAtCompany2";
import FindPeopleAtCompanyProxyCurl from "./FindPeopleAtCompanyProxyCurl";
import FindShoppingResults from "./FindShoppingResults";
import FindWorkEmail from "./FindWorkEmail";
import Formalizer from "./Formalizer";
import EditFormula from "./Formula";
import GenerateSalesEmails from "./GenerateSalesEmails";
import GoogleSearch from "./GoogleSearch";
import HttpApi from "./HttpApi";
import HubspotCompany from "./HubspotCompany";
import HubspotContact from "./HubspotCotact";
import JobOpenings from "./JobOpenings";
import LeadToHeyReach from "./LeadToHeyReach";
import LeadToInstantly from "./LeadToInstantly";
import LeadToLemList from "./LeadToLemList";
import LeadToSmartLeads from "./LeadToSmartLeads";
import LinkedInProfileDetails from "./LinkedInProfileDetails";
import MergeColumns from "./MergeColumns";
import PerplexityAI from "./PerplexityAI";
import PersanaAgent2 from "./PersanaAgent2";
import PersanaAgent3 from "./PersanaAgent3";
import PhoneWaterfall from "./PhoneWaterFall";
import RecentLinkedInPost from "./RecentLinkedinPost";
import { RowScorer } from "./RowScorer";
import ScrapeWebsite from "./ScrapeWebsite";
import SearchPhoneNumber from "./SearchPhoneNumber";
import SendsparkProspect from "./SedsparkProspect";
import TwitterDetails from "./TwitterDetails";
import UseAi from "./UseAi";
import VerifyEmail from "./VerifyEmail";
import Zenrows from "./Zenrows";

export const filterComponentMaps = {
  apifyActor: {
    title: "Apify Actor",
    Icon: () => <img src={ApiFyLogo} alt="Apify" className="w-[26px]" />,
    Component: ApiFy,
  },
  companyGrowthRate: {
    title: "Company Growth",
    Icon: () => <Icons.GrowthIcon size={26} color="#1972f5" />,
    Component: CompanyGrowth,
  },
  webhook: {
    title: "Webhook",
    Icon: () => <Icons.WebhookIcon size={26} color="#1972f5" />,
    Component: () => <div>Webhook</div>,
  },
  persanaAgent2: {
    title: "Persana Agent 2.0",
    Component: PersanaAgent2,
    Icon: () => <Icons.AgentIcon size={26} color="#0284fe" />,
  },
  persanaAgent3: {
    title: "Persana Agent 3",
    Component: PersanaAgent3,
    Icon: () => <Icons.AgentIcon size={26} color="#0284fe" />,
  },
  jobOpenings: {
    title: "Job Openings",
    Component: JobOpenings,
    Icon: () => <Icons.UserListIcon size={26} color="#27AE60" />,
  },
  addLeadToSmartleadsCampaign: {
    title: "Add Lead to Smartleads Campaign",
    Component: LeadToSmartLeads,
    Icon: () => <img src={Campaign} alt="Campaign" />,
  },
  useAi: {
    title: "Use AI (ChatGPT + Claude)",
    Component: UseAi,
    Icon: () => <img src={GPT} alt="GPT" />,
  },
  askAQuestion: {
    title: "Company Agent",
    Icon: () => <Icons.AgentIcon size={26} color="#ffef00" />,
    Component: AskAQuestionAiAgent,
  },
  findPeopleAtCompany: {
    title: "Find People At Company",
    Icon: () => <Icons.PeopleIcon size={26} color="#C04000" />,
    Component: FindPeopleAtCompany,
  },
  findPeopleAtCompanyProxyCurl: {
    title: "Find People At Company (Proxy Curl)",
    Icon: () => <Icons.PeopleIcon size={26} color="#C04000" />,
    Component: FindPeopleAtCompanyProxyCurl,
  },
  linkedInDetails: {
    title: "Person Profile Details",
    Icon: () => <Icons.PersonIcon size={26} color="#2F80ED" />,
    Component: LinkedInProfileDetails,
  },
  findLinkedInUrl: {
    title: "Find Linkedin Url",
    Icon: () => <Icons.LinkedInIcon size={26} color="#2F80ED" />,
    Component: FindLinkedInUrl,
  },
  companyTechnologies: {
    title: "Company Technologies",
    Icon: () => <Icons.QRCodeIcon size={26} />,
    Component: CompanyTechnologies,
  },
  companyNews: {
    title: "Company News",
    Icon: () => <img src={News} alt="News" />,
    Component: CompanyNews,
  },
  companyDetails: {
    title: "Enrich Company Details",
    Icon: () => <Icons.CompanyDetailsIcon size={26} color="#E2B93B" />,
    Component: CompanyDetails,
  },
  verifyEmail: {
    title: "Email Verification and Bounce Rates",
    Icon: () => <img src={Verify} alt="Verify" />,
    Component: VerifyEmail,
  },
  findWorkEmail: {
    title: "Find Work Email",
    Icon: () => <Icons.MailIcon size={26} color="693DC7" />,
    Component: FindWorkEmail,
  },
  findWorkEmailIcypeas: {
    title: "Find Work Email (Icypeas)",
    Icon: () => <Icons.MailIcon size={26} color="693DC7" />,
    Component: FindWorkEmail,
  },
  findWorkEmailProspeoLinkedin: {
    title: "Find Work Email with linkedin URL (Prospeo)",
    Icon: () => <Icons.MailIcon size={26} color="693DC7" />,
    Component: FindWorkEmail,
  },
  findWorkEmailFindyMail: {
    title: "Find Work Email (FindyMail)",
    Icon: () => <Icons.MailIcon size={26} color="693DC7" />,
    Component: FindWorkEmail,
  },
  findWorkEmailDatagma: {
    title: "Find Work Email (Datagma)",
    Icon: () => <Icons.MailIcon size={26} color="693DC7" />,
    Component: FindWorkEmail,
  },
  findWorkEmailHunterIo: {
    title: "Find Work Email (HunterIo)",
    Icon: () => <Icons.MailIcon size={26} color="693DC7" />,
    Component: FindWorkEmail,
  },
  findWorkEmailLeadMagic: {
    title: "Find Work Email (LeadMagic)",
    Icon: () => <Icons.MailIcon size={26} color="693DC7" />,
    Component: FindWorkEmail,
  },
  findWorkEmailWaterfall: {
    title: "Find Work Email",
    Icon: () => <Icons.MailIcon size={26} color="693DC7" />,
    Component: FindWorkEmail,
  },
  googleSearch: {
    title: "Google Search",
    Icon: () => <Icons.GoogleIcon size={26} />,
    Component: GoogleSearch,
  },
  scrapeWebsite: {
    title: "Scrape Website",
    Icon: () => <Icons.WebsiteIcon size={26} color="#27AE60" />,
    Component: ScrapeWebsite,
  },
  generateSalesEmails: {
    title: "Generate Sales Emails",
    Icon: () => <Icons.CampaignIcon size={26} color="#1972f5" />,
    Component: GenerateSalesEmails,
  },
  httpApi: {
    title: "HTTP API",
    Icon: () => <Icons.HttpIcon size={26} color="#1972f5" />,
    Component: () => <HttpApi />,
  },
  phoneWaterfall: {
    title: "find Phone Number",
    Icon: () => <Icons.CallIcon size={26} color="#1972f5" />,
    Component: () => <PhoneWaterfall />,
  },
  importData: {
    title: "Import Data",
    Icon: () => <Icons.ImportIcon size={26} color="#1972f5" />,
    Component: ImportData,
  },
  formalizer: {
    title: "Formalizer",
    Icon: () => <Icons.CleanIcon size={26} color="#1972f5" />,
    Component: Formalizer,
  },
  searchPhoneNumber: {
    title: "Find Mobile Number",
    Icon: () => <Icons.CallIcon size={26} color="#1972f5" />,
    Component: SearchPhoneNumber,
  },
  twitterDetails: {
    title: "Get Twitter Details",
    Icon: () => <Icons.TwitterIcon size={26} color="#1972f5" />,
    Component: TwitterDetails,
  },
  findMobileNumber: {
    title: "Find Mobile Number",
    Icon: () => <Icons.CallIcon size={26} color="#1972f5" />,
    Component: FindMobileNumber,
  },
  hubspotCompany: {
    title: "Create or Update Company (HubSpot)",
    Icon: () => <Icons.HubspotIcon size={26} color="#1972f5" />,
    Component: HubspotCompany,
  },
  hubspotContact: {
    title: "Create or Update Contact (HubSpot)",
    Icon: () => <Icons.HubspotIcon size={26} color="#1972f5" />,
    Component: HubspotContact,
  },
  hubspotContactUpdate: {
    title: "Update Contact (HubSpot)",
    Icon: () => <Icons.HubspotIcon size={26} color="#1972f5" />,
    Component: HubspotContact,
  },
  recentLinkedinPost: {
    title: "Find Recent LinkedIn Post",
    Icon: () => <Icons.LinkedInIcon size={26} color="#1972f5" />,
    Component: RecentLinkedInPost,
  },
  addLeadToLgm: {
    title: "La Growth Machine - Create or Update Lead",
    Icon: () => (
      <img
        src="https://persana-assets.s3.us-west-1.amazonaws.com/lgm.svg"
        alt="LGM"
        className="w-[26px]"
      />
    ),
    Component: CreateLeadLGM,
  },
  addLeadToInstantlyCampaign: {
    title: "Add Lead to Instantly Campaign",
    Component: LeadToInstantly,
    Icon: () => <Icons.LightningIcon size={22} color="#1179fc" />,
  },
  googleNews: {
    title: "Find News Results",
    Component: FindNewsResults,
    Icon: () => <Icons.NewsIcon size={22} color="#1179fc" />,
  },
  googleShopping: {
    title: "Find Shopping Results",
    Component: FindShoppingResults,
    Icon: () => <Icons.ShoppingIcon size={22} color="#1179fc" />,
  },
  zenrowsScrape: {
    title: "Run Zenrows Scrape",
    Component: Zenrows,
    Icon: () => <Icons.WebsiteIcon size={22} color="#1179fc" />,
  },
  addProspectToSendspark: {
    title: "Add Prospect to Video Campaign (Sendspark)",
    Component: SendsparkProspect,
    Icon: () => <Icons.VideoIcon size={22} color="#1179fc" />,
  },
  capterraScrape: {
    title: "Run Capterra Scrape via Zenrows",
    Component: CapterraScrape,
    Icon: () => <Icons.WebsiteIcon size={22} color="#1179fc" />,
  },
  enrichProfileByMixrank: {
    title: "Enrich Profile",
    Component: EnrichProfileByMixrank,
    Icon: () => <Icons.PersonIcon size={22} color="#1179fc" />,
  },
  addLeadToHeyReachCampaign: {
    title: "Add Lead to Campaign (HeyReach)",
    Component: LeadToHeyReach,
    Icon: () => (
      <img
        src={
          "https://persana-assets.s3.us-west-1.amazonaws.com/HeyReach-logo.png"
        }
        height={25}
        width={25}
        alt="Campaign"
      />
    ),
  },
  formula: {
    title: "Formula",
    Icon: () => <Icons.FunctionIcon size={26} color="#1972f5" />,
    Component: EditFormula,
  },
  perplexity: {
    title: "Perplexity AI",
    Icon: () => (
      <img
        src={
          "https://persana-assets.s3.us-west-1.amazonaws.com/perplexity.ai.png"
        }
        height={25}
        width={25}
        alt="Campaign"
      />
    ),
    Component: PerplexityAI,
  },
  mergeColumns: {
    title: "Merge Columns",
    Icon: () => <Icons.MergeIcon size={26} color="#1972f5" />,
    Component: MergeColumns,
  },
  addLeadToLemListCampaign: {
    title: "Add Lead to Campaign (lemlist)",
    Component: LeadToLemList,
    Icon: () => (
      <img
        src={
          "https://persana-assets.s3.us-west-1.amazonaws.com/Lemlist-logo.png"
        }
        height={25}
        width={25}
        alt="Campaign"
      />
    ),
  },
  findPeopleAtCompanyApollo: {
    title: "Find People At Company 2.0",
    Icon: () => <Icons.PeopleIcon size={26} color="#1972f5" />,
    Component: FindPeopleAtCompany2,
  },
  leadScore: {
    title: "Lead Scorer",
    Icon: () => (
      <img
        src={"https://persana-assets.s3.us-west-1.amazonaws.com/compare.png"}
        height={25}
        width={25}
        alt="Campaign"
      />
    ),
    Component: RowScorer,
    modal: {
      width: "80vw",
    },
  },
};
