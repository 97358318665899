import { Button, MenuItem, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import Swal from "sweetalert2";

import Icons from "@/components/Icons";
import { generateFilterPayload } from "@/lib/utils";
import tableService from "@/services/table.service";
import { useTableStore } from "@/stores/table.store";
import { userStore } from "@/stores/user.store";
interface Props {
  isFromMenu?: boolean;
  isAnyProcessRunning: boolean;
}

const ExportData = ({ isFromMenu = false, isAnyProcessRunning }: Props) => {
  const toast = useToast({
    position: "top-right",
  });

  const currentUser = userStore((state) => state.currentUser);
  const tableData = useTableStore((state) => state.tableData);
  const tableId = useTableStore((state) => state.tableData._id);
  const tableName = useTableStore((state) => state.tableData.name);
  const tableFilters = useTableStore((state) => state.tableFilters);
  const selectedRowsId = useTableStore((state) => state.selectedRowsId);
  const totalRows = useTableStore((state) => state.totalRows);
  const updateTableMetaDta = useTableStore((state) => state.updateTableMetaDta);
  const queryParams = useTableStore((state) => state.queryParams);

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({
      tableId,
      queryParams,
      payload,
    }: {
      tableId: string;
      queryParams: any;
      payload: any;
    }) => tableService.downloadCSV(tableId, queryParams, payload),
  });

  const handleExportData = async () => {
    if (currentUser?.planType === "Free" && tableData?.metaData?.isPremium) {
      Swal.fire({
        title: "Warning!",
        icon: "warning",
        html:
          "Please contact " +
          '<b><a href="mailto:support@persana.ai">support@persana.ai</a></b> ' +
          "to upgrade your plan",
        focusConfirm: true,
      });
      return;
    }
    if (isFromMenu) {
      toast({
        title: "Processing CSV",
        description:
          totalRows > 500
            ? "We've started processing your file..."
            : "Your CSV is being processed, Please wait...",
        status: "info",
        duration: 4000,
        isClosable: true,
      });
    }

    const { filterPayload, isAnyEmpty } = generateFilterPayload(
      tableFilters.filters || [],
    );
    await mutateAsync(
      {
        tableId,
        queryParams: {
          tableName,
          ...queryParams,
        },
        payload: {
          filterBody: !isAnyEmpty ? filterPayload : undefined,
          selectedRowsId,
          totalRows,
        },
      },
      {
        onSuccess: (responseData) => {
          if (responseData?.success) {
            toast({
              title: "Success",
              description: responseData.message,
              status: "success",
              duration: 2000,
              isClosable: true,
            });
            updateTableMetaDta({
              processingType: "CSV",
              processingMessage: responseData.message,
            });
          }
        },
      },
    );
  };

  if (isFromMenu) {
    return (
      <MenuItem
        className="flex items-center gap-4"
        onClick={handleExportData}
        isDisabled={isAnyProcessRunning}
      >
        <Icons.DownloadIcon />
        <p className="m-0">Download as CSV</p>
      </MenuItem>
    );
  }

  return (
    <Button
      className="flex !h-[35px] items-center rounded-md border !border-[#8080801c] !bg-gray !text-sm hover:!shadow-md"
      leftIcon={<Icons.ExportIcon />}
      isLoading={isPending}
      onClick={handleExportData}
      isDisabled={isAnyProcessRunning}
    >
      Export
    </Button>
  );
};

export default ExportData;
