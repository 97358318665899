/* eslint-disable @typescript-eslint/no-explicit-any */
import { AgGridReact } from "ag-grid-react";
import { Stack, Text, useConst } from "@chakra-ui/react";
import { FcDatabase } from "react-icons/fc";
import LottiePlayer from "@/components/Common/LottiePlayer";
import MainLoader from "@/assets/lottieFiles/main_loader.lottie";
import { defaultColDef } from "./columns/defaultColDef";
import { DataTableProps } from "./types";
import { useDataSourceServerSide } from "./hooks/useInfiniteQuery";
import { useTableStore } from "./hooks/useGridState.store";
import { useDataSourceClientSide } from "./hooks/useClientDataSource";
export const Table = <T,>({
  id,
  columns,
  rowModelType,
  dataSource: dataSourceFn,
}: DataTableProps<T>) => {
  const useDataSource = useConst(() =>
    rowModelType === "clientSide"
      ? useDataSourceClientSide
      : useDataSourceServerSide,
  );
  const dataSource = useDataSource<T>({ dataSource: dataSourceFn, id });
  const setGridApi = useTableStore((state) => state.setGridApi);
  const updateGridState = useTableStore((state) => state.setState);
  const updateState = useTableStore((state) => state.updateState);
  return (
    <>
      <AgGridReact<T>
        getRowId={(row) => (row.data as any)._id}
        onGridReady={(params) => {
          params.api.showLoadingOverlay();
          setGridApi(params.api);
          updateState({ id });
        }}
        onStateUpdated={(params) => {
          setGridApi(params.api);
          updateGridState(params.state);
        }}
        cacheBlockSize={30}
        onRowSelected={(params) => {
          const state = params.api.getState();
          updateGridState(state);
        }}
        {...dataSource}
        className="ag-theme-quartz"
        defaultColDef={defaultColDef}
        alwaysShowVerticalScroll
        alwaysShowHorizontalScroll
        rowHeight={64}
        columnDefs={columns}
        headerHeight={40}
        rowClass="border-bottom border-gray-200"
        rowSelection="multiple"
        paginationPageSizeSelector={false}
        rowMultiSelectWithClick
        suppressColumnMoveAnimation
        suppressRowClickSelection
        rowModelType={rowModelType ?? "infinite"}
        loadingOverlayComponent={() => (
          <LottiePlayer
            style={{ height: "120px", opacity: "0.5" }}
            src={MainLoader}
          />
        )}
        noRowsOverlayComponent={() => {
          // const isFiltered =
          //   Object.keys(params.api.getFilterModel()).length !== 0;
          return (
            <Stack className="place-content-center place-items-center" gap={4}>
              <FcDatabase className="text-8xl opacity-20" />
              <Text className="opacity-40">No Data Found</Text>
            </Stack>
          );
        }}
      />
    </>
  );
};
