import { Button, Radio, RadioGroup, Stack } from "@chakra-ui/react";

import { useEnrichStore } from "@/stores/enrich.store";
import { ProviderNameType, useProviderStore } from "@/stores/provider.store";
import { useEffect } from "react";

const KeySelect = ({
  providerTitle,
  providerName,
  disablePersanaKey = false,
  secretKeyInput = false,
}: {
  providerTitle: string;
  providerName: ProviderNameType;
  disablePersanaKey?: boolean;
  secretKeyInput?: boolean;
}) => {
  const keySelect = useEnrichStore((state) => state.keySelect);
  const updateState = useEnrichStore((state) => state.updateState);
  const updateProviderState = useProviderStore((state) => state.updateState);

  useEffect(() => {
    if (disablePersanaKey) {
      updateState({
        keySelect: "ownApiKey",
      });
    }
  }, [disablePersanaKey]);
  return (
    <>
      <hr />
      <div className="my-4">
        <div className="mb-3">
          <p className="text-base font-semibold">{`Choose ${providerTitle} API Key Account`}</p>
          {disablePersanaKey && (
            <p className="mt-[2px] text-[12px] font-bold  text-[#7E889F]">
              Note: Please use your own API key
            </p>
          )}
        </div>
        <RadioGroup
          onChange={(newValue: "persanaApiKey" | "ownApiKey") => {
            updateState({
              keySelect: newValue,
            });
          }}
          value={keySelect}
        >
          <Stack direction="row">
            {!disablePersanaKey && (
              <Radio
                size="md"
                name="persanaApiKey"
                value="persanaApiKey"
                colorScheme="primary"
                isDisabled={disablePersanaKey}
              >
                Use Persana's API Key
              </Radio>
            )}
            <Radio
              size="md"
              name="ownApiKey"
              value="ownApiKey"
              colorScheme="primary"
            >
              Use your own API key
            </Radio>
          </Stack>
        </RadioGroup>

        {keySelect === "ownApiKey" && (
          <Button
            className="ml-5 mt-3 cursor-pointer text-sm text-[#676d7e]"
            onClick={() => {
              updateProviderState({
                isProviderApiKeyModalOpen: true,
                providerApiKeyModalData: {
                  title: providerTitle,
                  providerName: providerName,
                  secretKeyInput,
                },
              });
            }}
            size="sm"
          >
            Add/Change API key
          </Button>
        )}
      </div>
      <hr className="mb-2" />
    </>
  );
};

export default KeySelect;
